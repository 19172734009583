import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { ToastContainer, toast } from 'react-toastify';
import toastNotify from '../../../../assets/images/toastNotify.svg';
import toastWarning from '../../../../assets/images/toastWarning.svg';
import toastError from '../../../../assets/images/toastError.svg';
import toastClose from '../../../../assets/images/toastClose.svg';
import './ToastMessage.scss';

const renderCloseButton = () => (
  <SVG className="toast-close" src={toastClose} />
);

const renderIcon = (type) => {
  switch (type) {
    case 'warning':
      return <SVG src={toastWarning} />;
    case 'error':
      return <SVG src={toastError} />;
    default:
      return <SVG src={toastNotify} />;
  }
};

const renderToastContent = (message, type, subtext) => (
  () => (
    <div className="toast-message">
      <div className="toast-main">
        { message }
      </div>
      { subtext
        && (
        <div className="toast-subtext">
          { subtext }
        </div>
        )
      }
    </div>
  )
);


class ToastMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  shouldComponentUpdate(nextProps) {
    const { message } = this.props;

    if (nextProps.message !== message) {
      toast(renderToastContent(nextProps.message, nextProps.type, nextProps.subtext), {
        icon: renderIcon(nextProps.type),
        className: `toast-${nextProps.type || 'notify'}`,
      });
    }

    return false;
  }

  render() {
    const { hidden } = this.props;

    if (hidden) return null;

    return (
      <ToastContainer
        position="bottom-center"
        autoClose={6000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={renderCloseButton}
      />
    );
  }
}

ToastMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  subtext: PropTypes.string,
  hidden: PropTypes.bool,
};

ToastMessage.defaultProps = {
  message: '',
  type: 'notify',
  subtext: null,
  hidden: false,
};

export default ToastMessage;
