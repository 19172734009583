import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Row, Col, FormGroup, Label, CustomInput,
} from 'reactstrap';
import { postRequest } from '../../../requestUtils';
import './MatchSourceModal.scss';

class MatchSourceModal extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedMatchSource: props.matchSource };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
  }

  handleRadioButtonChange(event) {
    const { target } = event;
    const { value } = target;
    this.setState({ selectedMatchSource: value });
  }

  handleSubmit() {
    const {
      ids, csrfToken, afterSubmit, toggleModal, context,
    } = this.props;
    const { selectedMatchSource } = this.state;
    const params = { consultants_project: { match_source: selectedMatchSource }, ids, context };
    postRequest('/consultants_projects/bulk_update', params, csrfToken)
      .then((response) => {
        afterSubmit(response.data, 'Match source', true);
        toggleModal();
      }).catch(() => {
        afterSubmit([], 'Match source', false);
        toggleModal();
      });
  }

  renderMatchSourceFormGroup(matchSource, display) {
    const { selectedMatchSource } = this.state;
    const selectedOption = selectedMatchSource === matchSource;
    const checkLabel = selectedOption ? 'source-checked' : '';
    return (
      <FormGroup check>
        <Label check>
          <CustomInput
            id={`radio-${matchSource}`}
            className="source-modal-radio"
            type="radio"
            name="match_source"
            value={matchSource}
            onChange={this.handleRadioButtonChange}
            checked={selectedMatchSource === matchSource}
          />
          <span className={`source-radio-label ${checkLabel}`}>{display}</span>
        </Label>
      </FormGroup>
    );
  }

  render() {
    const {
      showModal, matchSource, toggleModal,
    } = this.props;
    const { selectedMatchSource } = this.state;

    const disableSubmitButton = selectedMatchSource === matchSource;

    return (
      <Modal
        className="update-candidate-origin"
        isOpen={showModal}
        toggle={toggleModal}
        size="lg"
      >
        <ModalHeader toggle={toggleModal}>
          Update candidate origin
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <p className="source-modal-info">Select candidate origin</p>
              {this.renderMatchSourceFormGroup('clorify_find_similar', 'Clorify Find Similar')}
              {this.renderMatchSourceFormGroup('clorify_search', 'Clorify Search Result')}
              {this.renderMatchSourceFormGroup('linkedin', 'Linkedin')}
              {this.renderMatchSourceFormGroup('marketplace', 'Marketplace')}
              {this.renderMatchSourceFormGroup('personal_network', 'Personal Network')}
              {this.renderMatchSourceFormGroup('referral_from_marketplace', 'Referral From Marketplace')}
              {this.renderMatchSourceFormGroup('referral_from_off_platform', 'Referral From Off-Platform')}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel-button" onClick={toggleModal}>Cancel</Button>
          <Button
            className={`${disableSubmitButton ? 'submit-button-disabled' : 'submit-button'}`}
            onClick={this.handleSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

MatchSourceModal.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  matchSource: PropTypes.string,
  csrfToken: PropTypes.string.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  context: PropTypes.oneOf(['Marketplace::Project', 'Consultant']).isRequired,
};

MatchSourceModal.defaultProps = {
  matchSource: null,
};

export default MatchSourceModal;
