import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardTitle, CardBody,
} from 'reactstrap';

const CompanyResult = ({ company, marketplaceHost }) => (
  <div className="result-item mb-3">
    <Card>
      <CardBody>
        <CardTitle>
          <a
            href={`/companies/${company.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {company.name}
          </a>

          {company.qualified
            && <span className="fa fa-check-square-o" />
          }

          {company.dnu
            && <span className="fa fa-minus-circle" />
          }

          {company.clora_resource_id
            && (
              <div>
                <a
                  href={`${marketplaceHost}companies/${company.clora_resource_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Clora Profile
                </a>
              </div>
            )
          }

          {company.salesforce_id
            && (
              <div>
                <span className="fa fa-jsfiddle" />
                <a
                  href={`https://na153.lightning.force.com/lightning/r/Account/${company.clora_resource_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SFDC
                </a>
              </div>
            )
          }

          {company.linkedin_url
            && (
              <div>
                <span className="fa fa-linkedin-square" />
                <a
                  href={company.linkedin_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </div>
            )
          }

          {company.company_url
            && (
              <div>
                <span className="fa fa-globe" />
                <a
                  href={company.company_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Website
                </a>
              </div>
            )
          }

          {company.location
            && (
              <div>
                <span className="fa fa-map-marker" />
                {company.location.locality}
              </div>
            )
          }
        </CardTitle>
      </CardBody>
    </Card>
  </div>
);

CompanyResult.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number,
    clora_resource_id: PropTypes.string,
    name: PropTypes.string,
    qualified: PropTypes.bool,
    dnu: PropTypes.bool,
    salesforce_id: PropTypes.string,
    linkedin_url: PropTypes.string,
    company_url: PropTypes.string,
    location: PropTypes.shape({ locality: PropTypes.string }),
  }).isRequired,
  marketplaceHost: PropTypes.string.isRequired,
};

export default CompanyResult;
