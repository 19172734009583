import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardTitle, CardBody,
} from 'reactstrap';
import { analyticsTrack } from '../../analyticsUtils';
import './ProjectResult.scss';

class ProjectResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      descriptionExpanded: false,
      responsibilityAreasExpanded: false,
      expandAll: false,
      projectDetailsExpanded: false,
    };

    this.handleExpandAll = this.handleExpandAll.bind(this);
    this.trackResultClick = this.trackResultClick.bind(this);
  }

  handleExpandAll() {
    const { expandAll } = this.state;

    const newValue = !expandAll;

    this.setState({
      descriptionExpanded: newValue,
      responsibilityAreasExpanded: newValue,
      expandAll: newValue,
      projectDetailsExpanded: newValue,
    });
  }

  trackResultClick() {
    const { project, queryId, resultPosition } = this.props;

    analyticsTrack(
      'Search Result Clicked',
      {
        searchId: queryId,
        clickPosition: resultPosition,
        clickedObjectId: project.id,
        clickedObjectType: 'project',
      },
    );
  }

  renderExpandButton(attributeName) {
    const { expandAll } = this.state;
    const { [attributeName]: attribute } = this.state;

    if (attributeName === 'expandAll') {
      return (
        <button
          type="button"
          className="expand-all-button expand-button mt-2"
          onClick={this.handleExpandAll}
        >
          {expandAll ? '- Collapse all' : '+ Expand all'}
        </button>
      );
    }

    return (
      <button
        type="button"
        className={`${attributeName}-expand-button expand-button`}
        onClick={() => this.setState(prevState => ({ [attributeName]: !prevState[attributeName] }))}
      >
        {attribute ? 'See less' : 'See more'}
      </button>
    );
  }

  renderDescription() {
    const { project } = this.props;
    const { descriptionExpanded } = this.state;

    const showExpandButton = project.description.length > 180;
    const desc = showExpandButton && !descriptionExpanded ? `${project.description.slice(0, 180)}...` : project.description;

    return (
      <div className="col">
        <h4>Description</h4>
        {desc}
        {showExpandButton && this.renderExpandButton('descriptionExpanded')}
      </div>
    );
  }

  renderResponsibilities() {
    const { project } = this.props;
    const { responsibilityAreasExpanded } = this.state;

    const showExpandButton = project.responsibility_areas.length > 1;
    const responsibilities = showExpandButton && !responsibilityAreasExpanded
      ? <li>{`${project.responsibility_areas[0]}...`}</li> : project.responsibility_areas.map(ra => <li>{ra}</li>);

    return (
      <div className="col">
        <h4>Responsibility Areas</h4>
        <ul>
          {responsibilities}
        </ul>
        {showExpandButton && this.renderExpandButton('responsibilityAreasExpanded')}
      </div>
    );
  }

  renderProjectDetailsExpanded() {
    const { project, pageView } = this.props;
    const { projectDetailsExpanded, expandAll } = this.state;

    const showExtraTags = expandAll || pageView;

    const projectDetails = !projectDetailsExpanded ? '' : (
      <div>
        <div className="row">
          {project.description && this.renderDescription()}
        </div>
        <div className="row">
          {project.responsibility_areas && this.renderResponsibilities()}
        </div>
        <div className="row">
          {this.renderTagGroup('Areas of Expertise', 'area_of_expertise', 'area_of_expertises')}
          {this.renderTagGroup('Therapeutic Areas', 'therapeutic_area', 'therapeutic_areas')}
        </div>
        { showExtraTags && (
        <div>
          <div className="row">
            {this.renderTagGroup('Regulatory Pathways', 'regulatory_pathway', 'regulatory_pathways')}
            {this.renderTagGroup('Development Phases', 'development_phase', 'development_phases')}
          </div>
          <div className="row">
            {this.renderTagGroup('Disease Codes', 'disease_code', 'disease_codes')}
            {this.renderTagGroup('Therapies', 'therapy', 'therapies')}
          </div>
          <div className="row">
            {this.renderDegrees() }
            {this.renderLanguages() }
          </div>
          <div className="row">
            {this.renderSeniority() }
          </div>
        </div>
        )
    }
        {this.renderExpandButton('expandAll')}
      </div>
    );

    return (
      <div>
        <h4>Project Details</h4>
        {projectDetails}
        {this.renderExpandButton('projectDetailsExpanded')}
      </div>
    );
  }

  renderProjectDetails() {
    const {
      project, marketplaceHost, pageView, assignedRep,
    } = this.props;

    return (
      <div className="project-details">
        { project.resource_id && !pageView && (
        <div>
          <a
            href={`${marketplaceHost}projects/${project.resource_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Link to Marketplace Project
          </a>
        </div>
        )
        }

        { project.company && (
          <div>
            <a
              href={`${marketplaceHost}companies/${project.company.resource_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {project.company.name}
            </a>
          </div>
        )
        }
        <div>
          {`Last updated: ${project.updated_at}`}
        </div>
        { project.status && (
        <div>
          {`Status: ${project.status}`}
        </div>
        )
        }
        {
          assignedRep
          && (
          <div>
            {`Assigned Rep: ${assignedRep.name}`}
          </div>
          )
        }
      </div>
    );
  }

  renderProjectStats() {
    const { project } = this.props;

    return (
      <div className="stats">
        Project Statistics
        <ul>
          <li>{`Candidates sourced: ${project.candidates_sourced}`}</li>
          <li>{`Proposals in: ${project.proposals_in}`}</li>
          <li>{`Proposals out: ${project.proposals_out}`}</li>
        </ul>
      </div>
    );
  }

  renderTagGroup(title, attributeName, attributeNamePlural) {
    const { project } = this.props;

    let priorityTags = [];

    if (project.priority_expertise_tags) {
      priorityTags = project.priority_expertise_tags[`${attributeNamePlural}_names`];
    }

    return (
      <div className="col">
        <h4>{title}</h4>
        <ul>
          {project[attributeNamePlural].map((item) => {
            if (priorityTags.includes(item)) {
              return (<li className="priority-tag">{item}</li>);
            }
            return (<li>{item}</li>);
          }) }
        </ul>
      </div>
    );
  }

  renderDegrees() {
    const { project } = this.props;

    return (
      <div className="col">
        <h4>Degrees</h4>
        <ul>
          {project.degrees.map(el => <li>{el}</li>)}
        </ul>
      </div>
    );
  }

  renderLanguages() {
    const { project } = this.props;

    return (
      <div className="col">
        <h4>Languages</h4>
        <ul>
          {project.languages.map(l => <li>{l}</li>) }
        </ul>
      </div>
    );
  }

  renderSeniority() {
    const { project } = this.props;

    return (
      <div className="col">
        <h4>Seniority</h4>
        <ul>
          {project.levels.map(l => <li>{l.name}</li>) }
        </ul>
      </div>
    );
  }

  render() {
    const { project, pageView } = this.props;

    return (
      <div className="result-item mb-3">
        <Card>
          <CardBody>
            <CardTitle>
              <h1>
                <a
                  href={`/projects/${project.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.trackResultClick}
                >
                  {!pageView && project.name}
                </a>
              </h1>
              {this.renderProjectDetails()}
              {!pageView && this.renderProjectStats()}
              <hr />
              <div className="row">
                <div className="col">
                  <h6>Location</h6>
                  {project.work_location}
                </div>
                <div className="col">
                  <h6>Engagement Time</h6>
                  {project.duration_range}
                </div>
                <div className="col">
                  <h6>Hours per week</h6>
                  {project.avg_time_required_range}
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col">
                  <h6>Employer Hourly Rate</h6>
                  <span id="hourly_rate" className="dot">
                    { project.budget_minimum
                      ? `$${project.budget_minimum}`
                      : '??' }
                    {' '}
                    -
                    { project.budget_maximum
                      ? ` $${project.budget_maximum} `
                      : '??' }
                    / hour
                  </span>
                </div>
                <div className="col">
                  <h6>Consultant Take Home</h6>
                  <span id="consultant_hourly_rate" className="dot">
                    { project.budget_minimum
                      ? `$${Math.round(project.consultant_take_home_minimum)}`
                      : '??' }
                    {' '}
                    -
                    { project.budget_maximum
                      ? ` $${Math.round(project.consultant_take_home_maximum)} `
                      : '??' }
                    / hour
                  </span>
                </div>
              </div>
              <hr />
              {this.renderProjectDetailsExpanded()}
            </CardTitle>
          </CardBody>
        </Card>
      </div>
    );
  }
}

ProjectResult.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    office_location: PropTypes.string,
    description: PropTypes.string,
    responsibility_areas: PropTypes.arrayOf(PropTypes.string),
    resource_id: PropTypes.string,
    work_location: PropTypes.string,
    duration_range: PropTypes.string,
    avg_time_required_range: PropTypes.string,
    budget_maximum: PropTypes.number,
    budget_minimum: PropTypes.number,
    consultant_take_home_minimum: PropTypes.string,
    consultant_take_home_maximum: PropTypes.string,
    company: PropTypes.shape({
      resource_id: PropTypes.string,
      name: PropTypes.string,
    }),
    updated_at: PropTypes.string,
    status: PropTypes.string,
    candidates_sourced: PropTypes.number,
    proposals_in: PropTypes.number,
    proposals_out: PropTypes.number,
    priority_expertise_tags: PropTypes.shape({}),
    degrees: PropTypes.arrayOf(PropTypes.string),
    languages: PropTypes.arrayOf(PropTypes.string),
    levels: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  assignedRep: PropTypes.shape({
    name: PropTypes.string,
  }),
  marketplaceHost: PropTypes.string.isRequired,
  pageView: PropTypes.bool,
  resultPosition: PropTypes.number,
  queryId: PropTypes.string,
};

ProjectResult.defaultProps = {
  pageView: false,
  assignedRep: {},
  resultPosition: 0,
  queryId: null,
};

export default ProjectResult;
