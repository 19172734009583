/* global window jQuery */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import update from 'immutability-helper';
import SearchResults from '../SearchResults';
import SearchModal from '../SearchModal';
import Paginate from '../common/Paginate';
import { postRequest, deleteRequest } from '../../requestUtils';

class SearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidates: props.manualCandidates || [],
      totalCount: null,
      totalTime: null,
      currentPage: null,
      totalPages: null,
      isLoading: false,
      isError: false,
      error: '',
      advancedSearch: props.advancedSearch || false,
      matchSearch: false,
      projectConsultants: props.projectConsultants,
      currentSearchParams: {
        keywords: null,
        filters: {},
      },
      showSearchModal: true,
      query: null,
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleAddConsultant = this.handleAddConsultant.bind(this);
    this.handleRemoveConsultant = this.handleRemoveConsultant.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMatch = this.handleMatch.bind(this);
    this.toggleAdvancedSearch = this.toggleAdvancedSearch.bind(this);
    this.toggleMatchSearch = this.toggleMatchSearch.bind(this);
  }

  handleFilterChange(value, key) {
    const { selected } = this.state;
    const selectedItems = selected[key];
    let newSelected = null;

    if (Array.isArray(selectedItems)) {
      const index = selectedItems.indexOf(value);
      if (index > -1) {
        newSelected = update(selectedItems, { $splice: [[index, 1]] });
      } else {
        newSelected = update(selectedItems, { $push: [value] });
      }
    } else {
      newSelected = value;
    }

    const newState = update(selected, { [key]: { $set: newSelected } });
    this.setState({ selected: newState });
  }

  handlePageClick(page) {
    const { currentSearchParams, query } = this.state;
    const params = { ...currentSearchParams, page, query };
    this.setState({ isLoading: true, isError: false, error: '' });
    axios({
      url: '/search/find',
      params,
      /* eslint-disable-next-line no-shadow */
      paramsSerializer(params) { return jQuery.param(params); },
    })
      .then((response) => {
        this.setState({
          candidates: response.data.candidates,
          totalCount: response.data.total_count,
          currentPage: response.data.current_page,
          totalPages: response.data.total_pages,
          totalTime: response.data.total_time,
          currentSearchParams: params,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isError: true, error, isLoading: false });
      });
  }

  handleAddConsultant(consultantId) {
    const { projectId, csrfToken } = this.props;
    const { projectConsultants, query } = this.state;
    return postRequest(
      `/projects/${projectId}/add_consultant`,
      { consultant_id: consultantId, query },
      csrfToken,
    ).then((response) => {
      if (response.data.success === false) {
        /* eslint-disable-next-line no-alert */
        window.alert(response.data.error);
      }
      this.setState({ projectConsultants: [...projectConsultants, consultantId] });
    });
  }

  handleRemoveConsultant(consultantId) {
    const { projectId, csrfToken } = this.props;
    const { projectConsultants } = this.state;
    return deleteRequest(
      `/projects/${projectId}/remove_consultant`,
      { consultant_id: consultantId },
      csrfToken,
    ).then(() => {
      const copy = [...projectConsultants];
      const index = copy.indexOf(consultantId);
      copy.splice(index, 1);
      this.setState({ projectConsultants: copy });
    });
  }

  toggleAdvancedSearch() {
    const { advancedSearch } = this.state;
    this.setState({ advancedSearch: !advancedSearch });
  }

  toggleMatchSearch() {
    const { matchSearch } = this.state;
    this.setState({ matchSearch: !matchSearch });
  }

  handleSubmit(selected, keywords) {
    const { projectId } = this.props;
    const { query } = this.state;

    this.setState({ isLoading: true, isError: false, error: '' });
    const params = {
      filters: { ...selected, dnu: false },
      projectId,
      query,
      keywords,
    };

    axios({
      url: '/search/find',
      params,
      /* eslint-disable-next-line no-shadow */
      paramsSerializer(params) { return jQuery.param(params); },
    })
      .then((response) => {
        this.setState({
          candidates: response.data.candidates,
          totalCount: response.data.total_count,
          currentPage: response.data.current_page,
          totalPages: response.data.total_pages,
          totalTime: response.data.total_time,
          currentSearchParams: params,
          isLoading: false,
          showSearchModal: false,
          query: response.data.query,
        });
      })
      .catch((error) => {
        this.setState({ isError: true, error, isLoading: false });
      });
  }

  handleMatch(event) {
    event.preventDefault();
    const {
      keywords, locality, hasCloraProfile, isConsultant,
    } = this.state;

    this.setState({ isLoading: true, isError: false, error: '' });
    const params = {
      filters: {
        locality,
        has_clora_profile: hasCloraProfile,
        is_consultant: isConsultant,
        dnu: false,
      },
      keywords,
    };

    axios({
      url: '/search/match',
      params,
      /* eslint-disable-next-line no-shadow */
      paramsSerializer(params) { return jQuery.param(params); },
    })
      .then((response) => {
        this.setState({
          candidates: response.data.candidates,
          totalCount: response.data.total_count,
          totalTime: response.data.total_time,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isError: true, error, isLoading: false });
      });
  }

  renderSearchParams() {
    const { currentSearchParams } = this.state;
    const badges = [];

    if (currentSearchParams.keywords) {
      badges.push(
        <span className="badge badge-success">
          {currentSearchParams.keywords}
        </span>,
      );
    }

    Object.keys(currentSearchParams.filters).forEach((f) => {
      switch (f) {
        case 'area_of_expertises':
          currentSearchParams.filters.area_of_expertises.forEach(term => (
            badges.push(
              <span className="badge badge-primary">
                {term}
              </span>,
            )
          ));
          break;
        case 'therapeutic_areas':
          currentSearchParams.filters.therapeutic_areas.forEach(term => (
            badges.push(
              <span className="badge badge-secondary">
                {term}
              </span>,
            )
          ));
          break;
        case 'role_types':
          currentSearchParams.filters.role_types.forEach(term => (
            badges.push(
              <span className="badge badge-info">
                {term}
              </span>,
            )
          ));
          break;
        case 'locality':
          badges.push(
            <span className="badge badge-info">
              Locality:
              {currentSearchParams.filters.locality}
            </span>,
          );
          break;
        case 'has_clora_profile':
          badges.push(
            <span className="badge badge-info">
              Has Marketplace Profile
            </span>,
          );
          break;
        default:
          break;
      }
    });

    return badges;
  }

  render() {
    const {
      isLoading,
      isError,
      error,
      candidates,
      totalCount,
      totalTime,
      currentPage,
      totalPages,
      projectConsultants,
      showSearchModal,
    } = this.state;

    const {
      areaOfExpertises,
      therapeuticAreas,
      projectId,
      csrfToken,
      searchParams,
      marketplaceHost,
    } = this.props;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <SearchModal
              areaOfExpertises={areaOfExpertises}
              therapeuticAreas={therapeuticAreas}
              handleSubmit={this.handleSubmit}
              showModal={showSearchModal}
              selected={searchParams}
            />
            <div className="tags">
              {this.renderSearchParams()}
            </div>
          </div>
        </div>
        <div className="row">
          <main role="main" className="col-md-12 ml-sm-auto col-lg-12 pt-3 px-4">
            <div className="d-flex justify-content-between flex-wrap align-items-center pb-2 mb-3">
              <SearchResults
                isLoading={isLoading}
                isError={isError}
                error={error}
                candidates={candidates}
                totalCount={totalCount}
                totalTime={totalTime}
                projectConsultants={projectConsultants}
                csrfToken={csrfToken}
                projectId={projectId}
                handleAddConsultant={this.handleAddConsultant}
                handleRemoveConsultant={this.handleRemoveConsultant}
                marketplaceHost={marketplaceHost}
              />

              { currentPage && (totalPages > 0) && (
                <Paginate
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPaginationClick={this.handlePageClick}
                />
              )}
            </div>
          </main>
        </div>
      </div>
    );
  }
}

SearchContainer.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  areaOfExpertises: PropTypes.shape([]).isRequired,
  therapeuticAreas: PropTypes.shape([]).isRequired,
  projectConsultants: PropTypes.arrayOf(PropTypes.number).isRequired,
  search: PropTypes.shape({}).isRequired,
  projectId: PropTypes.number,
  manualCandidates: PropTypes.arrayOf(PropTypes.shape([])),
  searchParams: PropTypes.shape({}),
  advancedSearch: PropTypes.bool.isRequired,
  marketplaceHost: PropTypes.string.isRequired,
};

SearchContainer.defaultProps = {
  projectId: null,
  manualCandidates: [],
  searchParams: {
    area_of_expertises: [],
    therapeutic_areas: [],
    role_types: [],
  },
};

export default SearchContainer;
