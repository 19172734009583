/* global FormData */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { postRequest, deleteRequest } from '../../requestUtils';
import ModalTrigger from '../common/ModalTrigger';
import './ResumeDisplay.scss';

class ResumeDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resumes: props.resumes,
      uploading: false,
    };
    this.renderResumes = this.renderResumes.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleFileUpload(event) {
    const { consultantId, csrfToken } = this.props;
    const { resumes } = this.state;
    this.setState({ uploading: true });
    const formData = new FormData();
    const { target } = event;
    formData.append('resume', target.files[0]);
    formData.append('consultant_id', consultantId);
    postRequest(
      '/resumes',
      formData,
      csrfToken,
    ).then((response) => {
      resumes.push(response.data.resume);
      this.setState({ resumes, uploading: false });
      target.value = null;
    });
  }

  handleDelete(resumeId) {
    const { csrfToken } = this.props;
    const { resumes } = this.state;
    deleteRequest(
      `/resumes/${resumeId}`,
      {},
      csrfToken,
    ).then(() => {
      this.setState({ resumes: resumes.filter(r => r.resource_id !== resumeId) });
    });
  }

  renderResumes(form = false) {
    const { resumes } = this.state;
    if (form) {
      return (
        <div>
          {resumes.map(resume => (
            <div className="form-row" key={resume.resource_id}>
              <div className="col-md-11" key={resume.resource_id}>
                {resume.resume_file_name}
              </div>
              <div className="col-md-1">
                <button type="button" className="remove_fields" onClick={() => this.handleDelete(resume.resource_id)}>Delete</button>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return (
      <div className="form-group">
        <input type="file" />
      </div>
    );
  }

  renderForm() {
    const { uploading } = this.state;
    return (
      <div className="form-group">
        <input id="upload-file" type="file" onChange={this.handleFileUpload} />
        <span className="uploading">{ uploading ? 'Uploading...' : '' }</span>
      </div>
    );
  }

  renderResumeLinks() {
    const { expanded } = this.props;
    const { resumes } = this.state;

    if (expanded) {
      return (
        resumes.map(resume => (
          <div key={resume.resource_id}>
            <a href={`/resumes/${resume.resource_id}`} target="_blank" rel="noreferrer">{resume.resume_file_name}</a>
          </div>
        ))
      );
    }
    return (
      resumes.slice(0, 2).map(resume => (
        <div key={resume.resource_id}>
          <a href={`/resumes/${resume.resource_id}`} target="_blank" rel="noreferrer">{resume.resume_file_name}</a>
        </div>
      ))
    );
  }

  render() {
    return (
      <div>
        <h4>
          Resume(s)
          <ModalTrigger
            idKey="edit-resume"
            triggerText="Edit"
            triggerType="link"
            size="lg"
            triggerClass="inline"
            inline
          >
            <div className="form-group">
              {this.renderResumes(true)}
              {this.renderForm()}
            </div>
          </ModalTrigger>
        </h4>
        {this.renderResumeLinks()}
      </div>
    );
  }
}

ResumeDisplay.propTypes = {
  resumes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  csrfToken: PropTypes.string.isRequired,
  consultantId: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
};

ResumeDisplay.defaultProps = {
};

export default ResumeDisplay;
