import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Creatable } from 'react-select';
import { getRequest } from '../../requestUtils';

const associationTypesWithProficiency = ['AreaOfExpertisesConsultant',
  'ConsultantsHigherLevelRole'];

const proficiencyToString = (p) => {
  if (p === 'zero_to_two') {
    return ' (0-2 yrs.)';
  } if (p === 'two_to_five') {
    return ' (2-5 yrs.)';
  } if (p === 'five_to_ten') {
    return ' (5-10 yrs.)';
  } if (p === 'ten_to_fifteen') {
    return ' (10-15 yrs.)';
  } if (p === 'fifteen_to_twenty') {
    return ' (15-20 yrs.)';
  } if (p === 'twenty_plus') {
    return ' (20+ yrs.)';
  }
  return '';
};

const badgeClasses = (verified, custom, rejected) => {
  if (rejected === 'rejected_by_consultant' || rejected === 'rejected_by_rep') {
    return 'badge badge-declined';
  }

  if (verified) {
    return 'badge badge-success';
  }

  if (custom === false) {
    return 'badge badge-primary';
  }

  return 'badge badge-secondary';
};

class TagEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValues: [],
    };
    this.tagLabel = this.tagLabel.bind(this);
  }

  componentDidMount() {
    const { association, consultantId } = this.props;
    getRequest('/tags/options', { association_type: association, consultant_id: consultantId })
      .then((response) => { this.setState({ selectValues: response.data }); });
  }

  componentDidUpdate(prevProps, prevState) {
    const { newPicklist } = this.props;
    if (prevProps.newPicklist !== newPicklist) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectValues: prevState.selectValues.concat(newPicklist),
      });
    }
  }

  tagLabel(tag) {
    const { association } = this.props;

    if (associationTypesWithProficiency.includes(association)) {
      return `${tag.name}${proficiencyToString(tag.proficiency)}`;
    }

    return tag.name;
  }

  render() {
    const {
      toggleVerify,
      addTag,
      removeTag,
      addNewPicklist,
      tags,
      error,
    } = this.props;
    const { selectValues } = this.state;

    return (
      <div>
        { error && <div className="alert alert-danger">{error}</div> }
        <div className="tags">
          { tags.map((tag, index) => (tag.rejected !== 'removed' ? (
            <span
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              className={badgeClasses(tag.verified, tag.user_created, tag.rejected)}
            >
              { tag.inModel
                  && (
                    <span>
                      <i className="fa fa-bullseye" />
                    </span>
                  )
                }
              { tag.ranking
                  && (
                    <span className="mb-0 ml-0 ranked-span">
                      <i className="fa ranked" />
                    </span>
                  )
                }
              <span
                onClick={() => toggleVerify(index)}
                role="presentation"
              >
                {this.tagLabel(tag)}
              </span>
              { tag.rejected !== 'rejected_by_consultant'
                  && (
                  <span
                    className="fa fa-times remove-tag"
                    onClick={() => removeTag(index)}
                    role="presentation"
                  />
                  )
                }
            </span>
          )
            : ''
          ))}
        </div>
        <div className="mt-3">
          <Creatable
            value={[]}
            options={selectValues}
            onChange={value => addTag(value)}
            onCreateOption={value => addNewPicklist(value)}
            closeOnSelect={false}
          />
        </div>
      </div>
    );
  }
}

TagEditor.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleVerify: PropTypes.func.isRequired,
  addTag: PropTypes.func.isRequired,
  addNewPicklist: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  association: PropTypes.string.isRequired,
  error: PropTypes.string,
  consultantId: PropTypes.number.isRequired,
  newPicklist: PropTypes.shape({}).isRequired,
};

TagEditor.defaultProps = {
  error: null,
};

export default TagEditor;
