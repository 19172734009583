/* global window */
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import SVG from 'react-inlinesvg';
import { RuleOptionsContext, ruleOptions } from '../QueryBuilder/constants';
import QueryBuilder from '../QueryBuilder';
import Paginate from '../common/Paginate';
import ActionDropdown from '../common/ActionDropdown';
import SearchResults from '../SearchResults';
import SourceAlternateProjectModal from '../common/SourceAlternateProjectModal';
import consumer from '../../channels/consumer';
import { postRequest, deleteRequest } from '../../requestUtils';
import checkmark from '../../../assets/images/check.svg';
import minus from '../../../assets/images/minus.svg';
import uploadGreen from '../../../assets/images/uploadGreen.svg';
import ellipsisBlue from '../../../assets/images/ellipsisBlue.svg';
import ellipsisGray from '../../../assets/images/ellipsisGray.svg';
import plusGray from '../../../assets/images/plusGray.svg';
import cornerUpRight from '../../../assets/images/corner-up-right.svg';
import trash2 from '../../../assets/images/trash-2.svg';
import './QuerySearchContainer.scss';

const getSearchUrl = (resource) => {
  switch (resource) {
    case 'consultants':
      return '/search/find';
    case 'companies':
      return '/companies/find';
    case 'projects':
      return '/projects/find';
    default:
      return null;
  }
};

const getExportUrl = (resource) => {
  switch (resource) {
    case 'consultants':
      return '/search/bulk_export';
    case 'companies':
      return '/companies/bulk_export';
    case 'projects':
      return '/projects/bulk_export';
    default:
      return null;
  }
};

const resultsTime = (lowerConsultantBound, upperConsultantBound, totalCount, totalTime) => {
  if (totalCount != null && totalTime != null) {
    return (
      <span className="count-display">
        Viewing
        <span className="count-display-page-details">
          {` ${lowerConsultantBound}-${upperConsultantBound} `}
        </span>
        of
        <span className="count-display-page-details">
          {` ${totalCount} `}
        </span>
        candidates found
        <span className="count-display-time">
          {` (${totalTime} seconds)`}
        </span>
      </span>
    );
  }
  return (
    <span className="count-display">No results found.</span>
  );
};

class QuerySearchContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectId: props.projectId,
      candidates: [],
      consultantIds: [],
      totalCount: null,
      totalTime: null,
      currentPage: null,
      totalPages: null,
      resultsPerPage: null,
      isLoading: false,
      isError: false,
      error: '',
      bulkEmailActionMessage: '',
      projectConsultants: props.projectConsultants,
      selectedProjectConsultants: [],
      currentQuery: null,
      exportedSelected: false,
      ruleOptionsState: ruleOptions[props.resource],
      showAssociatedConsultants: true,
      bulkEmailResponseCount: 0,
      bulkEmailInProgress: false,
      foundCount: 0,
    };

    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleBulkExportSelected = this.handleBulkExportSelected.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleAddConsultants = this.handleAddConsultants.bind(this);
    this.handleBulkRemoveConsultants = this.handleBulkRemoveConsultants.bind(this);
    this.handleRemoveConsultant = this.handleRemoveConsultant.bind(this);
    this.handleSelectConsultant = this.handleSelectConsultant.bind(this);
    this.handleDeselectConsultant = this.handleDeselectConsultant.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleDeselectAll = this.handleDeselectAll.bind(this);
    this.handleBulkFindEmail = this.handleBulkFindEmail.bind(this);
    this.handleBulkEmailsResponse = this.handleBulkEmailsResponse.bind(this);
    this.handleAssociatedConsultants = this.handleAssociatedConsultants.bind(this);
    this.toggleSourceAlternateModal = this.toggleSourceAlternateModal.bind(this);
    this.getCurrentPageCount = this.getCurrentPageCount.bind(this);
    this.getDisabledPages = this.getDisabledPages.bind(this);
  }

  getFilteredCount() {
    const {
      totalCount, projectConsultants, consultantIds, showAssociatedConsultants,
    } = this.state;

    if (showAssociatedConsultants) {
      return totalCount;
    } if (totalCount > 1000) {
      // to avoid timeouts, the backend will not send more than 1k consultant ids
      // so we make a guess
      return totalCount - projectConsultants.length;
    } // otherwise, get the exact number of filtered consultants
    return consultantIds.filter(id => !projectConsultants.includes(id)).length;
  }

  getCurrentPageCount() {
    const {
      showAssociatedConsultants, projectConsultants,
      currentPage, totalPages, totalCount, resultsPerPage,
    } = this.state;
    const intervalBounds = [];
    const minStandardIndex = currentPage === 1 ? 1 : (currentPage - 1) * resultsPerPage + 1;
    const maxStandardIndex = currentPage === totalPages ? totalCount : currentPage * resultsPerPage;
    if (!showAssociatedConsultants) {
      const lowerBound = currentPage === 1 ? 1 : minStandardIndex - projectConsultants.filter(
        consultantIndex => consultantIndex < minStandardIndex,
      ).length;
      const adjustment = minStandardIndex - lowerBound;
      let upperBound = maxStandardIndex - projectConsultants.filter(
        consultantIndex => consultantIndex >= minStandardIndex
            && consultantIndex <= maxStandardIndex,
      ).length - adjustment;

      const filteredTotalCount = this.getFilteredCount();
      if (filteredTotalCount < upperBound) {
        upperBound = filteredTotalCount;
      }
      intervalBounds.push(lowerBound);
      intervalBounds.push(upperBound);
      return intervalBounds;
    }
    return [minStandardIndex, maxStandardIndex];
  }

  getDisabledPages(getCall) {
    const {
      projectConsultants, totalPages, consultantIds,
      totalCount, showAssociatedConsultants, resultsPerPage,
    } = this.state;

    if (showAssociatedConsultants && !getCall) {
      return [];
    }

    const remainingConsultants = consultantIds.filter(id => !projectConsultants.includes(id));
    const disabledPages = [];
    for (let i = 1; i <= totalPages; i += 1) {
      const lowerBound = (i - 1) * resultsPerPage;
      const upperBound = i === totalPages ? totalCount : i * resultsPerPage;
      const consultantsOnPage = consultantIds.slice(lowerBound, upperBound);
      const isDisabledPage = !consultantsOnPage.some(id => remainingConsultants.includes(id));
      if (isDisabledPage) {
        disabledPages.push(i);
      }
    }
    return disabledPages;
  }

  subscribeToBulkEmailChannel() {
    const { userId } = this.props;

    consumer.subscriptions.create({
      channel: 'BulkEmailChannel',
      user_id: userId,
    }, {
      received: this.handleBulkEmailsResponse,
    });
  }

  handleBulkEmailsResponse(response) {
    const {
      candidates, selectedProjectConsultants, bulkEmailResponseCount, foundCount,
    } = this.state;

    const emails = response.body;
    const emailCount = emails.reduce((total, status) => total + (status.success ? 1 : 0),
      0) + foundCount;
    const newCandidates = candidates.map((candidate) => {
      const foundResponse = emails.find(r => r.id === candidate.id);
      if (foundResponse && foundResponse.success) {
        const newCandidate = { ...candidate };
        newCandidate.email = foundResponse.email;
        return newCandidate;
      }
      return candidate;
    });

    let message = `Found ${emailCount} out of ${selectedProjectConsultants.length} emails.`;
    if (response.batches_left) {
      message = message.concat(` Waiting on ${response.batches_left} more responses...`);
    }

    this.setState({
      bulkEmailInProgress: response.batches_left > 0,
      bulkEmailResponseCount: bulkEmailResponseCount + 1,
      bulkEmailActionMessage: message,
      candidates: newCandidates,
      foundCount: emailCount,
    });

    if (response.batches_left === 0) {
      this.handleBulkExportSelected();
    }
  }

  handleSearchSubmit(query) {
    const { resource } = this.props;
    const { projectId } = this.state;
    const params = {
      query,
      project_id: projectId,
    };
    this.setState({
      bulkEmailActionMessage: null,
      isLoading: true,
      isError: false,
      error: '',
    });

    axios({
      url: getSearchUrl(resource),
      params,
    }).then((response) => {
      this.setState({
        selectedProjectConsultants: [],
        candidates: response.data.candidates,
        consultantIds: response.data.consultant_ids,
        totalCount: response.data.total_count,
        currentPage: response.data.current_page,
        totalPages: response.data.total_pages,
        totalTime: response.data.search_time,
        currentQuery: response.data.query,
        sourcingProjects: response.data.sourcing_projects,
        isLoading: false,
        exportedSelected: false,
        /* eslint-disable-next-line react/destructuring-assignment */
        projectId: this.props.projectId,
        resultsPerPage: response.data.results_per_page,
      });
    }).catch((error) => {
      this.setState({ isError: true, error, isLoading: false });
    });
  }

  handleBulkExport() {
    const { resource } = this.props;
    const { currentQuery, selectedProjectConsultants } = this.state;

    const selected = selectedProjectConsultants.length > 500
      ? selectedProjectConsultants.slice(0, 500)
      : selectedProjectConsultants;

    const params = {
      query_id: currentQuery.id,
      query: currentQuery.params,
      selected_ids: selected,
      export_all: false,
    };
    axios({
      url: getExportUrl(resource),
      params,
    }).then(() => {
      this.setState({
        exportedSelected: false,
        bulkEmailActionMessage: 'Export Complete',
      });
    }).catch(() => {
      this.setState({ bulkEmailActionMessage: 'Bulk Export Error!' });
    });
  }

  handleBulkExportSelected() {
    this.setState({
      bulkEmailActionMessage: 'Export in Progress',
    });
    this.handleBulkExport();
  }

  handleBulkFindEmail() {
    const { userId } = this.props;
    const { selectedProjectConsultants } = this.state;
    const params = {
      selected_ids: selectedProjectConsultants.slice(0, 500),
      user_id: userId,
    };

    if (selectedProjectConsultants.length > 500) {
      if (!window.confirm('Only fetching emails for/exporting first 500 consultants. Try narrowing your search.')) return;
    }

    this.setState({
      foundCount: 0,
      bulkEmailInProgress: true,
      exportedSelected: true,
      bulkEmailActionMessage: `Searching for ${selectedProjectConsultants.length} emails...`,
    });
    postRequest('/rocketreach/bulk_find_email', params).then(() => {
      this.subscribeToBulkEmailChannel();
    }).catch(() => {
      this.setState({
        bulkEmailInProgress: false,
        bulkEmailActionMessage: 'There was an error retrieving emails, wait 5 minutes and try again.',
      });
      this.handleBulkExportSelected();
    });
  }

  handlePageClick(page) {
    const { resource } = this.props;
    const { currentQuery } = this.state;
    const params = { query: currentQuery.params, query_id: currentQuery.id, page };
    this.setState({ isLoading: true, isError: false, error: '' });

    axios({
      url: getSearchUrl(resource),
      params,
    })
      .then((response) => {
        this.setState({
          candidates: response.data.candidates,
          totalCount: response.data.total_count,
          currentPage: response.data.current_page,
          totalPages: response.data.total_pages,
          totalTime: response.data.total_time,
          resultsPerPage: response.data.results_per_page,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isError: true, error, isLoading: false });
      });
  }

  handleRemoveConsultant(consultantId) {
    const { csrfToken } = this.props;
    const { projectConsultants, projectId } = this.state;

    if (projectId === null) return null;

    return deleteRequest(
      `/projects/${projectId}/remove_consultant`,
      { consultant_id: consultantId },
      csrfToken,
    ).then(() => {
      const copy = [...projectConsultants];
      const index = copy.indexOf(consultantId);
      copy.splice(index, 1);
      this.setState({ projectConsultants: copy });
    });
  }

  handleBulkRemoveConsultants(consultantIds, projectId) {
    const { csrfToken } = this.props;
    const { projectConsultants } = this.state;

    if (projectId === null) return null;

    return deleteRequest(
      `/projects/${projectId}/bulk_remove_consultants`,
      { consultant_ids: consultantIds },
      csrfToken,
    ).then(() => {
      const removed = projectConsultants.filter(id => !projectConsultants.includes(id));
      this.setState({ projectConsultants: removed });
    });
  }

  handleAddConsultants(consultantIds, projectId) {
    const { csrfToken } = this.props;
    const { projectConsultants, currentQuery } = this.state;

    if (projectId === null || consultantIds.length === 0) return null;

    if (consultantIds.length > 200) {
      if (!window.confirm('Only sourcing first 200 consultants. Try narrowing your search.')) return null;
    }
    return postRequest(
      `/projects/${projectId}/add_consultants`,
      { consultant_ids: consultantIds.slice(0, 200), query_id: currentQuery.id },
      csrfToken,
    ).then((response) => {
      if (response.data.success === false) {
        /* eslint-disable-next-line no-alert */
        window.alert(response.data.error);
      }
      this.setState({
        projectConsultants: projectConsultants.concat(consultantIds),
      });
    });
  }

  handleDeselectConsultant(consultantId) {
    const { selectedProjectConsultants } = this.state;

    const copy = [...selectedProjectConsultants];
    const index = copy.indexOf(consultantId);
    copy.splice(index, 1);
    this.setState({ selectedProjectConsultants: copy });
  }

  handleSelectConsultant(consultantId) {
    const { selectedProjectConsultants } = this.state;

    this.setState({ selectedProjectConsultants: [...selectedProjectConsultants, consultantId] });
  }

  handleSelectAll() {
    const { consultantIds, showAssociatedConsultants, projectConsultants } = this.state;
    const selectConsultants = showAssociatedConsultants
      ? consultantIds : consultantIds.filter(id => !projectConsultants.includes(id));
    this.setState({ selectedProjectConsultants: selectConsultants });
  }

  handleDeselectAll() {
    this.setState({ selectedProjectConsultants: [] });
  }

  handleAssociatedConsultants() {
    const { currentPage, totalPages } = this.state;
    let requestedPage = currentPage;
    while (requestedPage < totalPages && this.getDisabledPages(true).includes(requestedPage)) {
      requestedPage += 1;
    }
    this.setState(prevState => ({
      showAssociatedConsultants:
      !prevState.showAssociatedConsultants,
    }), () => this.handlePageClick(requestedPage));
  }

  toggleSourceAlternateModal() {
    const { showSourceAlternateModal } = this.state;

    this.setState({ showSourceAlternateModal: !showSourceAlternateModal });
  }

  renderSelectionCheckbox() {
    const {
      selectedProjectConsultants,
      candidates,
      totalCount,
      resultsPerPage,
    } = this.state;
    const candidateIds = candidates.map(candidate => candidate.id);
    const numSelectedCandidates = candidateIds.filter(
      id => selectedProjectConsultants.includes(id),
    ).length;
    let selectText;
    let selectClass;
    if (selectedProjectConsultants.length === totalCount) {
      selectText = 'All selected';
      selectClass = 'selected-text';
    } else if (selectedProjectConsultants.length > 0) {
      selectText = `${selectedProjectConsultants.length} selected`;
      selectClass = 'selected-text';
    } else {
      selectText = 'Select All';
      selectClass = 'unselected-text';
    }

    let selectMethod;
    let selectMark;
    let checkboxSelect;
    if (numSelectedCandidates > 0) {
      selectMethod = this.handleDeselectAll;
      checkboxSelect = 'selected-page';

      selectMark = (numSelectedCandidates === resultsPerPage)
        ? <SVG className="select-check" src={checkmark} />
        : <SVG className="select-minus" src={minus} />;
    } else {
      selectMethod = this.handleSelectAll;
      selectMark = null;
      checkboxSelect = 'unselected-page';
    }

    return (
      <label htmlFor="select-checkbox" className="select-label">
        {selectMark || ''}
        <input id="select-checkbox" type="button" onClick={selectMethod} className={`btn ${checkboxSelect}`} />
        <span id="select-checkbox-text" className={selectClass}>{selectText}</span>
      </label>
    );
  }

  renderAssociatedConsultantsButton() {
    const { showAssociatedConsultants } = this.state;
    const showSelect = showAssociatedConsultants ? <SVG className="select-check" src={checkmark} />
      : <SVG className="select-minus" src={minus} />;
    const showTextClass = showAssociatedConsultants ? 'selected-text' : 'unselected-text';
    const showButtonClass = showAssociatedConsultants ? 'selected-page' : 'unselected-page';
    return (
      <label htmlFor="source-checkbox" className="select-label ml-3 include-sourced">
        {showSelect || ''}
        <input id="source-checkbox" type="button" onClick={this.handleAssociatedConsultants} className={`btn ${showButtonClass}`} />
        <span id="source-checkbox-text" className={showTextClass}>Include pipeline candidates</span>
      </label>

    );
  }

  renderExportSelectedButton() {
    const { bulkEmailInProgress, exportedSelected, selectedProjectConsultants } = this.state;
    const inProgress = bulkEmailInProgress || exportedSelected;

    return (
      <button
        className="btn btn-secondary export-button ml-2"
        type="button"
        onClick={this.handleBulkFindEmail}
        disabled={inProgress || selectedProjectConsultants.length === 0}
      >
        <SVG className="export-icon" src={uploadGreen} />
        <span className="btn-text">Export for Outreach</span>
      </button>
    );
  }

  renderActionsDropdown() {
    const { projectId } = this.props;
    const { selectedProjectConsultants } = this.state;
    const disabled = selectedProjectConsultants.length === 0;

    return (
      <ActionDropdown
        id="consultant-bulk-actions"
        key={`consultant-bulk-actions-for-${selectedProjectConsultants.length}-consultants`}
        defaultLabel={(
          <SVG
            title="Bulk Actions"
            src={disabled ? ellipsisGray : ellipsisBlue}
            className="bulk-consultant-actions candidate-action-icon"
          />
        )}
        links={[
          ['function', 'Add to another project', this.toggleSourceAlternateModal, cornerUpRight],
          ['function', 'Add to current project', () => (this.handleAddConsultants(selectedProjectConsultants, projectId)),
            plusGray, `${projectId ? '' : 'disabled'} gray-plus`],
          ['function', 'Remove from current project',
            () => (this.handleBulkRemoveConsultants(selectedProjectConsultants, projectId)),
            trash2, projectId ? '' : 'disabled',
          ],
        ]}
        plainTrigger
        isDisabled={disabled}
        title="Bulk Actions"
      />
    );
  }

  render() {
    const {
      isLoading,
      isError,
      error,
      candidates,
      totalTime,
      currentPage,
      totalPages,
      projectConsultants,
      selectedProjectConsultants,
      currentQuery,
      ruleOptionsState,
      projectId,
      bulkEmailActionMessage,
      showAssociatedConsultants,
      bulkEmailResponseCount,
      sourcingProjects,
      showSourceAlternateModal,
      resultsPerPage,
    } = this.state;

    const {
      initialQuery,
      resetQuery,
      csrfToken,
      resource,
      marketplaceHost,
      preferenceOptions,
      enrichmentStatusOptions,
    } = this.props;

    const queryResult = currentPage && (totalPages > 0);
    const filteredCandidates = showAssociatedConsultants
      ? candidates : candidates.filter(c => !projectConsultants.includes(c.id));
    const consultantResource = resource === 'consultants';
    const headerClass = consultantResource ? 'header-sticky' : 'header-sticky-project';
    const intervalBoundsPage = this.getCurrentPageCount();
    return (
      <div className="container-fluid">
        <Row>
          <Col sm={queryResult ? '6' : '9'} xl={queryResult ? '6' : '8'}>
            <Row>
              <div className="query-container mx-2 p-4">
                <div>
                  Current Query Id:
                  { currentQuery && `${currentQuery.id}`}
                </div>
                <RuleOptionsContext.Provider value={ruleOptionsState}>
                  <QueryBuilder
                    initialQuery={initialQuery === null ? undefined : initialQuery}
                    resetQuery={resetQuery}
                    handleSearchSubmit={this.handleSearchSubmit}
                    resource={resource}
                    preferenceOptions={preferenceOptions}
                  />
                </RuleOptionsContext.Provider>
              </div>
              <div className="d-flex">
                <div className="vr mx-2 vertical-divider" />
              </div>
            </Row>
          </Col>
          <Col sm={queryResult ? '6' : '3'} xl={queryResult ? '6' : '4'} className="pr-5">
            <Row className={headerClass}>
              { queryResult && (
              <div>
                <div>
                  { resultsTime(intervalBoundsPage[0],
                    intervalBoundsPage[1],
                    this.getFilteredCount(),
                    totalTime) }
                  { projectId && this.renderAssociatedConsultantsButton() }
                </div>
                { consultantResource
                  && (
                  <div>
                    { this.renderSelectionCheckbox() }
                    { this.renderExportSelectedButton() }
                    { this.renderActionsDropdown() }
                    <SourceAlternateProjectModal
                      selectedConsultantName={`${selectedProjectConsultants.length} consultants`}
                      consultantIds={selectedProjectConsultants}
                      toggleModal={this.toggleSourceAlternateModal}
                      csrfToken={csrfToken}
                      showModal={showSourceAlternateModal}
                      sourcingProjects={sourcingProjects}
                      handleSetBulkActionMessage={this.handleSetBulkActionMessage}
                    />
                  </div>
                  )
                }
              </div>
              )}

              <div className="flex-col bulk-action-message">
                { bulkEmailActionMessage
                  && (
                    <div className={`alert ${error ? 'alert-danger' : ''}`}>
                      {bulkEmailActionMessage}
                    </div>
                  )
                }
              </div>
              <hr />
            </Row>
            <Row
              className={`scrollable-body ${queryResult ? 'results-present' : ''}`}
              style={{ maxHeight: consultantResource ? '70vh' : '79vh' }}
            >
              <SearchResults
                key={`${currentQuery ? currentQuery.id : 0}-${this.getFilteredCount()}-${bulkEmailResponseCount}`}
                isLoading={isLoading}
                isError={isError}
                wasSubmitted={currentQuery != null}
                error={error}
                candidates={filteredCandidates}
                projectConsultants={projectConsultants}
                selectedProjectConsultants={selectedProjectConsultants}
                csrfToken={csrfToken}
                projectId={projectId}
                addConsultant={this.handleAddConsultants}
                removeConsultant={this.handleRemoveConsultant}
                selectConsultant={this.handleSelectConsultant}
                deselectConsultant={this.handleDeselectConsultant}
                resource={resource}
                marketplaceHost={marketplaceHost}
                enrichmentStatusOptions={enrichmentStatusOptions}
                sourcingProjects={sourcingProjects}
                sourcingAvailable
                queryId={currentQuery && currentQuery.id}
                currentPage={currentPage}
                resultsPerPage={resultsPerPage}
              />
            </Row>
            <div className="d-flex align-items-center justify-content-center footer-sticky">
              { queryResult && (
                <div className="outer-sticky">
                  <div className="inner-sticky-bottom">
                    <Paginate
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPaginationClick={this.handlePageClick}
                      disabledPages={this.getDisabledPages(false)}
                    />
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

QuerySearchContainer.propTypes = {
  projectId: PropTypes.number,
  initialQuery: PropTypes.shape({}),
  resetQuery: PropTypes.shape({}),
  projectConsultants: PropTypes.arrayOf(PropTypes.number).isRequired,
  csrfToken: PropTypes.string.isRequired,
  resource: PropTypes.string,
  marketplaceHost: PropTypes.string.isRequired,
  preferenceOptions: PropTypes.shape({}).isRequired,
  enrichmentStatusOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userId: PropTypes.string,
};

QuerySearchContainer.defaultProps = {
  userId: null,
  initialQuery: null,
  resetQuery: null,
  projectId: null,
  resource: 'consultants',
};

export default QuerySearchContainer;
