import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import FilterList from '../FilterList';
import './Filter.scss';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: props.height,
    };

    this.toggleFilters = this.toggleFilters.bind(this);
  }

  toggleFilters() {
    const { height } = this.state;

    this.setState({
      height: height === 0 ? 'auto' : 0,
    });
  }

  renderFilters(iterable) {
    const { onItemClick } = this.props;

    return iterable.map((iter) => {
      if (iter.type === 'text') return null;

      return (
        <div className="col" key={`${iter.key}_${iter.name}`}>
          <h6 className="text-uppercase font-weight-bold">{iter.name}</h6>
          <hr />
          {this.renderFilterList(
            iter.options,
            val => onItemClick(val, iter.key),
            iter.key,
          )}
        </div>
      );
    });
  }

  renderFilterList(items, clickHandler, stateName) {
    const { selected } = this.props;

    return (
      <FilterList
        items={items}
        onClick={clickHandler}
        selectedList={selected[stateName]}
      />
    );
  }

  render() {
    const { height } = this.state;
    const {
      filters,
      buttonText,
      children,
      clearFilters,
    } = this.props;
    return (
      <React.Fragment>
        <span className="flex-grid">
          <div className="col">
            <button type="button" className="btn filter-modal-trigger" onClick={this.toggleFilters}>
              <i className="fa fa-filter mr-2" />
              {buttonText}
            </button>
            {height !== 0
              && (
              <button type="button" className="btn ml-2 filter-modal-trigger" onClick={clearFilters}>
                <i className="fa fa-ban mr-2" />
                Clear Filters
              </button>
              )
            }
          </div>
        </span>

        <AnimateHeight
          duration={500}
          height={height}
        >
          <div className="flex-grid mt-3">
            {this.renderFilters(filters)}
            { children }
          </div>
        </AnimateHeight>
      </React.Fragment>
    );
  }
}

Filter.propTypes = {
  buttonText: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onItemClick: PropTypes.func.isRequired,
  selected: PropTypes.shape({}),
  children: PropTypes.node,
  clearFilters: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  buttonText: 'Filter',
  height: 0,
  selected: {},
  children: null,
};

export default Filter;
