// PLEASE NOTE:
// Passing a UTC datetime into this component will not show the UTC date
// Javascript date objects correspond to the user's timezone
// So a date of '2019-07-01T00:00:00Z' will display as '2019-06-30' in EST
// One way around this is to pass in the date as end-of-day

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.scss';

class DateInput extends Component {
  constructor(props) {
    super(props);
    this.state = { startDate: props.startDate ? new Date(props.startDate) : null };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(newDate) {
    this.setState({ startDate: newDate });
  }

  render() {
    const { startDate } = this.state;
    const { dateFormat, name } = this.props;

    return (
      <DatePicker
        selected={startDate}
        onChange={this.handleChange}
        allowSameDay
        className="form-control"
        name={name}
        value={startDate}
        dateFormat={dateFormat || 'yyyy-MM-dd'}
        highlightDates={[new Date()]}
      />
    );
  }
}

DateInput.propTypes = {
  startDate: PropTypes.string,
  name: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
};

DateInput.defaultProps = {
  startDate: null,
  dateFormat: null,
};

export default DateInput;
