/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import WebpackerReact from 'webpacker-react';
import Sidebar from '../components/Sidebar';
import SearchContainer from '../components/SearchContainer';
import NotesSearchContainer from '../components/NotesSearchContainer';
import ResumesContainer from '../components/ResumesContainer';
import SearchResults from '../components/SearchResults';
import ConsultantResult from '../components/ConsultantResult';
import SkillsContainer from '../components/SkillsContainer';
import ConsultantProjectContainer from '../components/ConsultantProjectContainer';
import RatingsContainer from '../components/RatingsContainer';
import ProjectTagsContainer from '../components/ProjectTagsContainer';
import NotesContainer from '../components/NotesContainer';
import SimilarConsultantModal from '../components/SimilarConsultantModal';
import DeleteConsultant from '../components/DeleteConsultant';
import Recommendation from '../components/Recommendation';
import DateInput from '../components/DateInput';
import QuerySearchContainer from '../components/QuerySearchContainer';
import ProjectStatusChanges from '../components/ProjectStatusChanges';
import ProjectResult from '../components/ProjectResult';
import HeaderDropdown from '../components/HeaderDropdown';
import 'cocoon-js-vanilla';
import 'select2';
import '../../assets/javascripts/consultants';

WebpackerReact.setup({
  Sidebar,
  SearchContainer,
  NotesSearchContainer,
  ResumesContainer,
  SearchResults,
  ConsultantResult,
  SkillsContainer,
  ConsultantProjectContainer,
  RatingsContainer,
  ProjectTagsContainer,
  NotesContainer,
  SimilarConsultantModal,
  DeleteConsultant,
  Recommendation,
  DateInput,
  QuerySearchContainer,
  ProjectStatusChanges,
  ProjectResult,
  HeaderDropdown,
});
