import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { deleteRequest } from '../../requestUtils';

class SkillsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skills: props.skills,
    };

    this.deleteSkill = this.deleteSkill.bind(this);
  }

  deleteSkill(event) {
    const { skills } = this.state;
    const { formUrl, csrfToken } = this.props;
    const skillsCopy = skills.slice();
    const skillIndex = parseInt(event.target.value, 10);
    const skillId = event.target.id;

    return deleteRequest(
      `${formUrl}/${skillId}`,
      {},
      csrfToken,
    ).then(() => {
      skillsCopy.splice(skillIndex, 1);
      this.setState({ skills: skillsCopy });
    });
  }

  renderResourceLink(name, id) {
    const { resourceLink } = this.props;
    if (resourceLink) {
      return <a href={`${resourceLink}/${id}`}>{name}</a>;
    }

    return name;
  }

  render() {
    const { skills } = this.state;

    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {skills.map((skill, index) => (
            <tr key={skill.id}>
              <td>
                <h6>
                  {this.renderResourceLink(skill.name, skill.id)}
                  {' '}
                  <span className="badge badge-secondary">{skill.consultants_count}</span>
                </h6>
              </td>
              <td><button type="button" onClick={this.deleteSkill} className="btn btn-danger" value={index} id={skill.id}>Delete</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

SkillsContainer.propTypes = {
  skills: PropTypes.shape([]).isRequired,
  formUrl: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  resourceLink: PropTypes.string,
};

SkillsContainer.defaultProps = {
  resourceLink: null,
};

export default SkillsContainer;
