import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Sidebar from '../../Sidebar';
import { getRequest } from '../../../requestUtils';
import './GenericSearchContainer.scss';

const renderTextInput = (key, answer, handleChange) => (
  <div className="form-group">
    <h6 className="sidebar-heading d-flex mt-3 mb-1 text-muted">
      <span>{key}</span>
    </h6>
    <input
      type="text"
      id={`${key}Input`}
      className="form-control"
      value={answer}
      name={key}
      onChange={e => handleChange(e.target.value, key)}
    />
  </div>
);

const renderAssociationInput = (key, answer, associations, handleChange) => (
  <div className="form-group">
    <h6 className="sidebar-heading d-flex justify-content-between align-items-center mt-4 mb-1 text-muted">
      <span>{key}</span>
    </h6>
    <Select
      inputId={key}
      name={key}
      isMulti
      value={answer}
      options={associations}
      removeSelected={false}
      onChange={v => handleChange(v, key)}
      handleSelectChange={v => handleChange(v, key)}
      autosize
      joinValues
    />
  </div>
);

class GenericSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      filters: props.filters,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(value, key) {
    const { filters } = this.state;
    const filter = filters.find(f => f.key === key);
    filter.answer = value;

    this.setState({ filters });
  }

  handleSubmit() {
    const { filters } = this.state;
    const { searchLink } = this.props;

    const newFilters = filters.map((f) => {
      if (f.type === 'text') return f;
      const newF = Object.assign({}, f, { answer: f.answer.map(a => a.value) });
      return newF;
    });

    return getRequest(
      searchLink,
      { filters: newFilters },
    ).then((response) => {
      this.setState({ results: response.data });
    });
  }

  renderFilter(filter) {
    switch (filter.type) {
      case 'text':
        return renderTextInput(
          filter.key,
          filter.answer,
          this.handleInputChange,
        );
      case 'integer':
        return renderAssociationInput(
          filter.key,
          filter.answer,
          filter.associations,
          this.handleInputChange,
        );
      default:
        return null;
    }
  }

  render() {
    const { filters, results } = this.state;
    const { header, renderResults } = this.props;
    return (
      <div className="container-fluid">
        <div className="search-container row">
          <Sidebar>
            <h2 className="mt-5">Search</h2>
            {filters.map(filter => (this.renderFilter(filter)))}
            <div className="form-group">
              <input
                type="submit"
                value="Submit"
                className="btn btn-primary"
                onClick={this.handleSubmit}
              />
            </div>
          </Sidebar>
          <div id="results" className="col-md-9 col-lg-10">
            <h1 className="title">{header}</h1>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
              {renderResults(results)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GenericSearchContainer.propTypes = {
  header: PropTypes.string,
  searchLink: PropTypes.string.isRequired,
  filters: PropTypes.shape([]).isRequired,
  renderResults: PropTypes.func.isRequired,
};

GenericSearchContainer.defaultProps = {
  header: 'Results',
};

export default GenericSearchContainer;
