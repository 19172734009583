import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import starGreen from '../../../../assets/images/starGreen.svg';
import starGreenOutline from '../../../../assets/images/starGreenOutline.svg';
import starYellow from '../../../../assets/images/starYellow.svg';
import starYellowOutline from '../../../../assets/images/starYellowOutline.svg';
import chevronDown from '../../../../assets/images/chevronDown.svg';

import './StarRating.scss';

class StarRating extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderStars() {
    const { rating } = this.props;
    const stars = [];

    for (let i = 1; i <= 5; i += 1) {
      stars.push(this.renderStar(i <= Math.floor(rating)));
    }
    return stars;
  }

  renderStar(filled) {
    const { color } = this.props;
    let starType;
    if (color === 'green') {
      starType = filled ? starGreen : starGreenOutline;
    } else {
      starType = filled ? starYellow : starYellowOutline;
    }

    return (
      <SVG src={starType} className="rating-star" />
    );
  }

  render() {
    const { beforeText, rating } = this.props;

    return (
      <div className="star-rating">
        <div className="before-text">{beforeText}</div>
        {rating
          ? (
            <React.Fragment>
              {this.renderStars()}
              <div className="rating">{Math.round(rating * 10) / 10}</div>
            </React.Fragment>
          )
          : <div className="rating-content null-rating">No rating available</div>
        }
        <SVG src={chevronDown} className="rating chevron-down" />
      </div>
    );
  }
}

StarRating.propTypes = {
  beforeText: PropTypes.string,
  color: PropTypes.oneOf(['yellow', 'green']).isRequired,
  rating: PropTypes.number,
};

StarRating.defaultProps = {
  rating: 0.0,
  beforeText: '',
};

export default StarRating;
