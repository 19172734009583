import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const Rating = ({
  score, dimension, handleInputChange, index,
}) => (
  <div>
    <h4>{dimension}</h4>
    <Select
      name={dimension}
      onChange={e => handleInputChange(e, dimension, index)}
      value={score}
      clearable={false}
      options={[
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
      ]}
    />
  </div>
);

Rating.propTypes = {
  dimension: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  score: PropTypes.shape({}),
  index: PropTypes.number.isRequired,
};

Rating.defaultProps = {
  score: null,
};

export default Rating;
