import React from 'react';

export const ruleOptions = {
  consultants: {
    label: [
      {
        name: 'Functional Area',
        value: 'area_of_expertises',
      },
      {
        name: 'Role',
        value: 'higher_level_roles',
      },
      {
        name: 'Development Phase',
        value: 'development_phases',
      },
      {
        name: 'Diseases',
        value: 'disease_codes',
      },
      {
        name: 'Industry',
        value: 'industries',
      },
      {
        name: 'Seniority',
        value: 'levels',
      },
      {
        name: 'Therapy',
        value: 'therapies',
      },
      {
        name: 'Therapeutic Area',
        value: 'therapeutic_areas',
      },
      {
        name: 'Formulations',
        value: 'formulations',
      },
      {
        name: 'Tools',
        value: 'tools',
      },
      {
        name: 'Languages',
        value: 'languages',
      },
      {
        name: 'Credentials',
        value: 'accreditations',
      },
    ],
    range: [
      {
        name: 'Available Start Date',
        value: 'availability',
      },
      {
        name: 'Availability Confirmed At',
        value: 'availability_confirmed_at',
      },
      {
        name: 'Minimum Hourly Rate',
        value: 'minimum_hourly_rate',
      },
      {
        name: 'Maximum Hourly Rate',
        value: 'maximum_hourly_rate',
      },
      {
        name: 'Minimum Hours/Week',
        value: 'minimum_hours_per_week',
      },
      {
        name: 'Maximum Hours/Week',
        value: 'maximum_hours_per_week',
      },
      {
        name: 'Rating',
        value: 'overall_rating',
      },
      {
        name: 'Years of Experience',
        value: 'total_years_of_experience',
      },
      {
        name: 'Travel Days',
        value: 'travel_days',
      },
      {
        name: 'In-office Days',
        value: 'in_office_days',
      },
    ],
    bool: [
      {
        name: 'On Marketplace',
        value: 'has_clora_profile',
      },
      {
        name: 'Consultant',
        value: 'is_consultant',
      },
      {
        name: 'Contractor',
        value: 'is_contractor',
      },
      {
        name: 'FTE',
        value: 'is_fte',
      },
      {
        name: 'Moonlighter',
        value: 'is_moonlighter',
      },
      {
        name: 'Vetted',
        value: 'is_vetted',
      },
    ],
    preference: [
      {
        name: 'Company Fit',
        value: 'company_fit',
      },
      {
        name: 'Email Preferences',
        value: 'email_preferences',
      },
    ],
    ready_to_consult: [
      {
        name: 'Ready to Consult',
        value: 'ready_to_consult',
      },
    ],
  },
  companies: {
    label: [
      {
        name: 'Functional Area',
        value: 'area_of_expertises',
      },
      {
        name: 'Development Phase',
        value: 'development_phases',
      },
      {
        name: 'Diseases',
        value: 'disease_codes',
      },
      {
        name: 'Industry',
        value: 'industries',
      },
      {
        name: 'Therapy',
        value: 'therapies',
      },
      {
        name: 'Therapeutic Area',
        value: 'therapeutic_areas',
      },
    ],
    range: [],
    bool: [
      {
        name: 'On Marketplace',
        value: 'has_clora_profile',
      },
      {
        name: 'Qualified',
        value: 'qualified',
      },
    ],
  },
  projects: {
    label: [
      {
        name: 'Functional Area',
        value: 'area_of_expertises',
      },
      {
        name: 'Development Phase',
        value: 'development_phases',
      },
      {
        name: 'Diseases',
        value: 'disease_codes',
      },
      {
        name: 'Degrees',
        value: 'degrees',
      },
      {
        name: 'Seniority',
        value: 'levels',
      },
      {
        name: 'Therapies',
        value: 'therapies',
      },
      {
        name: 'Therapeutic Area',
        value: 'therapeutic_areas',
      },
      {
        name: 'Regulatory Pathways',
        value: 'regulatory_pathways',
      },
      {
        name: 'Languages',
        value: 'languages',
      },
    ],
    range: [
      {
        name: 'Budget Minimum',
        value: 'budget_minimum',
      },
      {
        name: 'Budget Maximum',
        value: 'budget_maximum',
      },
      {
        name: 'Days In Office',
        value: 'days_in_office',
      },
      {
        name: 'Days Of Travel',
        value: 'days_of_travel',
      },
      {
        name: 'Clora Take Rate',
        value: 'clora_take_rate',
      },
      {
        name: 'Published At',
        value: 'published_at',
      },
      {
        name: 'First Proposal Submitted At',
        value: 'first_proposal_submitted_at',
      },
    ],
    bool: [
      {
        name: 'Offering consultant benefits',
        value: 'offering_consultant_benefits',
      },
      {
        name: 'Consultant needs to sign agreement',
        value: 'consultant_needs_sign_agreement',
      },
      {
        name: 'Has internal budget approved',
        value: 'have_internal_budget_approval',
      },
    ],
  },
};

export const RuleOptionsContext = React.createContext(ruleOptions.consultants);
