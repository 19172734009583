import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { Row, Col } from 'reactstrap';
import localDateString from '../../utils/dateFormatter';
import * as starYellowIcon from '../../../assets/images/star-yellow.svg';
import * as chevronDown from '../../../assets/images/chevronDown.svg';
import * as chevronUp from '../../../assets/images/chevronUp.svg';
import './NoteDisplay.scss';

const renderStars = (n) => {
  const stars = [];
  for (let i = 0; i < n; i += 1) {
    stars.push(starYellowIcon);
  }

  return <span className="stars">{stars.map(i => <SVG src={i} />)}</span>;
};

class NoteDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noteTypes: props.noteTypes,
      showAllDataButton: false,
    };
    this.renderNoteData = this.renderNoteData.bind(this);
    this.toggleShowAllButton = this.toggleShowAllButton.bind(this);
    this.renderShowAllButton = this.renderShowAllButton.bind(this);
  }

  componentDidMount() {
    const noteTextContainerHeight = this.noteTextContainer.clientHeight;
    this.setState({ noteTextContainerHeight });
  }

  toggleShowAllButton() {
    const { showAllDataButton } = this.state;
    this.setState({ showAllDataButton: !showAllDataButton });
  }

  renderShowAllButton() {
    const { showAllDataButton } = this.state;
    return (
      <button
        type="button"
        className="toggle-more-button"
        onClick={this.toggleShowAllButton}
      >
        {showAllDataButton ? 'See less' : 'See more' }
        {' '}
        <SVG src={showAllDataButton ? chevronUp : chevronDown} />
      </button>
    );
  }

  renderNoteData() {
    const { note } = this.props;
    const { noteTypes, noteTextContainerHeight, showAllDataButton } = this.state;
    let lineClamp = null;

    const showAllButton = noteTextContainerHeight > 80;
    if (showAllButton && !showAllDataButton) {
      lineClamp = 3;
    }

    return (
      <div className="note-item">
        <div className="header-item">
          <div className="icon-date-item">
            <SVG src={noteTypes[note.type]} className="svg-icon" />
            <span>{localDateString(note.created)}</span>
          </div>
          <span className="author-name">
            {note.author_name}
          </span>
        </div>
        <div className="average-rating">
          {note.average_rating
              && (
                <div>
                  Clora
                  {renderStars(note.average_rating)}
                  {note.average_rating}
                </div>
              )
            }
        </div>
        {note.type === 'availability'
          && (
          <div className="availability-info">
            { note.has_availability
              ? (
                <span className="available">
                  <strong>Available</strong>
                  {' '}
                  {note.start_date && localDateString(note.start_date)}
                  {' - '}
                  {note.end_date && localDateString(note.end_date)}
                </span>
              )
              : <span>No (Unavailable)</span>}
            <span>
              {note.minimum_hours_per_week && note.minimum_hours_per_week}
              {' - '}
              {note.maximum_hours_per_week && note.maximum_hours_per_week}
              {' '}
              hrs/wk
            </span>
          </div>
          )
        }
        <div className="project-info">
          {note.new_status && (
          <div>
            New status
            {' '}
            <span className={`badge badge-${note.new_status}`}>
              {note.new_status.split('_').join(' ')}
            </span>
          </div>
          )}
          {note.project_company && (
            <div className="project-company">
              {note.project_company}
            </div>
          )}
          {note.project_name
              && (
              <a
                href={note.project_link}
                rel="noopener noreferrer"
                target="_blank"
                className="project-name"
              >
                {note.project_name}
              </a>
              )
            }
        </div>
        <div ref={(el) => { this.noteTextContainer = el; }}>
          <div className="note-text" style={{ WebkitLineClamp: lineClamp }}>
            {note.data}
          </div>
          {showAllButton && this.renderShowAllButton() }
        </div>
        <div className="horizontal-divider" />
      </div>
    );
  }

  render() {
    return (
      <Row className="note-div">
        <Col>
          {this.renderNoteData()}
        </Col>
      </Row>
    );
  }
}

NoteDisplay.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.number,
    project_id: PropTypes.number,
    data: PropTypes.string,
    average_rating: PropTypes.number,
    editable: PropTypes.bool,
    created: PropTypes.string,
    project_link: PropTypes.string,
    has_availability: PropTypes.bool,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    minimum_hours_per_week: PropTypes.string,
    maximum_hours_per_week: PropTypes.string,
    new_status: PropTypes.string,
    project_name: PropTypes.string,
    project_company: PropTypes.string,
    type: PropTypes.string,
    author_name: PropTypes.string,
  }).isRequired,
  noteTypes: PropTypes.shape({
    note: PropTypes.oneOf(['img', 'svg']),
    ratings: PropTypes.oneOf(['img', 'svg']),
    'status-update': PropTypes.oneOf(['img', 'svg']),
    availability: PropTypes.oneOf(['img', 'svg']),
  }).isRequired,
};

export default NoteDisplay;
