import React, { Component } from 'react';
import PropTypes from 'prop-types';
import nanoid from 'nanoid';
import SVG from 'react-inlinesvg';
import { Button } from 'reactstrap';
import RuleGroup from './RuleGroup';
import { findRule } from './utils/queryBuilderUtils';
import search from '../../../assets/images/search.svg';
import reset from '../../../assets/images/reset.svg';
import './QueryBuilder.scss';

const createRule = optional => (
  {
    id: `r-${nanoid()}`,
    type: 'label',
    field: 'area_of_expertises',
    name: '',
    verified: '',
    optional,
  }
);

const createStatusRule = optional => (
  {
    id: `r-${nanoid()}`,
    type: 'status',
    field: 'status',
    value: 'clora_matching',
    optional,
  }
);

const createExperienceRule = optional => (
  {
    id: `r-${nanoid()}`,
    type: 'experience',
    field: 'experiences',
    title: '',
    organization: '',
    current: '',
    description: '',
    optional,
  }
);

const createTextRule = optional => (
  {
    id: `r-${nanoid()}`,
    type: 'text',
    field: '',
    query: '',
    optional,
  }
);

const createKeywordRule = () => (
  {
    id: `r-${nanoid()}`,
    type: 'text',
    field: '',
    query: '',
    optional: false,
    keyword: true,
  }
);

const createRangeRule = optional => (
  {
    id: `r-${nanoid()}`,
    type: 'range',
    field: 'overall_rating',
    operator: 'gte',
    min: '',
    max: '',
    value: '',
    optional,
  }
);

const createBoolRule = optional => (
  {
    id: `r-${nanoid()}`,
    type: 'bool',
    field: 'has_clora_profile',
    value: 'true',
    optional,
  }
);

const createGeoRule = optional => (
  {
    id: `r-${nanoid()}`,
    type: 'geo',
    field: 'locality',
    value: '',
    distance: '50mi',
    optional,
  }
);

const createPreferenceRule = optional => (
  {
    id: `r-${nanoid()}`,
    type: 'preference',
    field: 'company_fit',
    value: '',
    optional,
  }
);

const createReadyToConsultRule = optional => (
  {
    id: `r-${nanoid()}`,
    type: 'ready_to_consult',
    field: 'ready_to_consult',
    value: 'true',
    start_date_min: '',
    start_date_max: '',
    confirmed_at_min: '',
    confirmed_at_max: '',
    optional,
  }
);

const createRuleGroup = (withinOptionalGroup, keywordGroup) => (
  {
    id: `g-${nanoid()}`,
    rules: [],
    combinator: 'and',
    optional_group: false,
    keyword_group: keywordGroup,
    removable: true,
    withinOptionalGroup,
  }
);

class QueryBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: props.initialQuery,
      optionalEnabled: true,
      startQuery: JSON.parse(JSON.stringify(props.resetQuery)),
    };
    this.handleOptionalToggle = this.handleOptionalToggle.bind(this);
    this.onRuleAdd = this.onRuleAdd.bind(this);
    this.onRuleRemove = this.onRuleRemove.bind(this);
    this.onRuleGroupAdd = this.onRuleGroupAdd.bind(this);
    this.onRuleGroupRemove = this.onRuleGroupRemove.bind(this);
    this.onPropChange = this.onPropChange.bind(this);
    this.resetInitialQuery = this.resetInitialQuery.bind(this);
  }

  onRuleAdd(rule, parentId, resource, group) {
    const { query } = this.state;
    const rootCopy = { ...query };
    const { notifyChange } = this.props;
    const parent = findRule(parentId, rootCopy);
    if (resource === 'consultants' && group) {
      parent.rules.splice(-1, 0, rule);
    } else {
      parent.rules.push(rule);
    }

    this.setState({ query: rootCopy });
    notifyChange();
  }

  onRuleRemove(ruleId, parentId) {
    const { query } = this.state;
    const rootCopy = { ...query };
    const { notifyChange } = this.props;
    const parent = findRule(parentId, rootCopy);
    const index = parent.rules.findIndex(x => x.id === ruleId);

    parent.rules.splice(index, 1);

    this.setState({ query: rootCopy });
    notifyChange();
  }

  onRuleGroupAdd(group, parentId, resource, parentGroup) {
    const { query } = this.state;
    const rootCopy = { ...query };
    const { notifyChange } = this.props;
    const parent = findRule(parentId, rootCopy);
    if (resource === 'consultants' && parentGroup) {
      parent.rules.splice(-1, 0, group);
    } else {
      parent.rules.push(group);
    }

    this.setState({ query: rootCopy });
    notifyChange();
  }

  onRuleGroupRemove(groupId, parentId) {
    const { query } = this.state;
    const rootCopy = { ...query };
    const { notifyChange } = this.props;
    const parent = findRule(parentId, rootCopy);
    const index = parent.rules.findIndex(x => x.id === groupId);

    parent.rules.splice(index, 1);

    this.setState({ query: rootCopy });
    notifyChange();
  }

  onPropChange(prop, value, id) {
    const { query } = this.state;
    const rootCopy = { ...query };
    const { notifyChange } = this.props;
    const rule = findRule(id, rootCopy);
    Object.assign(rule, { [prop]: value });

    this.setState({ query: rootCopy });
    notifyChange();
  }

  resetInitialQuery() {
    const { startQuery } = this.state;

    this.setState({ query: JSON.parse(JSON.stringify(startQuery)) });
  }

  handleOptionalToggle() {
    const { optionalEnabled } = this.state;

    this.setState({ optionalEnabled: !optionalEnabled });
  }

  render() {
    const { query, optionalEnabled } = this.state;
    const schema = {
      onRuleAdd: this.onRuleAdd,
      onRuleRemove: this.onRuleRemove,
      onRuleGroupAdd: this.onRuleGroupAdd,
      onRuleGroupRemove: this.onRuleGroupRemove,
      onPropChange: this.onPropChange,
      createRule,
      createStatusRule,
      createExperienceRule,
      createTextRule,
      createKeywordRule,
      createRangeRule,
      createBoolRule,
      createGeoRule,
      createPreferenceRule,
      createReadyToConsultRule,
      createRuleGroup,
    };
    const { handleSearchSubmit, resource, preferenceOptions } = this.props;
    const submitQuery = { ...query };
    submitQuery.rules = optionalEnabled
      ? query.rules
      : query.rules.filter(rule => !rule.optional_group);

    return (
      <span className="query-builder">
        <h5>Query Builder</h5>
        <div className="scrollable-container">
          <div className="scrollable-body builder">
            <RuleGroup
              rules={query.rules}
              combinator={query.combinator}
              id={query.id}
              parentId={null}
              resource={resource}
              schema={schema}
              backgroundStyling="main-group"
              preferenceOptions={preferenceOptions}
              removable={false}
              optionalGroup={false}
              keywordGroup={false}
              optionalEnabled={optionalEnabled}
              optionalToggle={this.handleOptionalToggle}
            />
          </div>
          <div className="mb-2 mt-2 query-builder-btns">
            <Button className="reset-btn" onClick={() => this.resetInitialQuery()}>
              <SVG src={reset} className="search-icon" />
              Reset
            </Button>
            <Button className="search-btn" onClick={() => handleSearchSubmit(submitQuery)}>
              <SVG src={search} className="search-icon" />
              Search
            </Button>
          </div>
        </div>
      </span>
    );
  }
}

QueryBuilder.propTypes = {
  initialQuery: PropTypes.shape({}),
  resetQuery: PropTypes.shape({}),
  handleSearchSubmit: PropTypes.func.isRequired,
  notifyChange: PropTypes.func,
  resource: PropTypes.string.isRequired,
  preferenceOptions: PropTypes.shape({}).isRequired,
};

QueryBuilder.defaultProps = {
  initialQuery: createRuleGroup(),
  resetQuery: createRuleGroup(),
  notifyChange: () => null,
};

export default QueryBuilder;
