import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConsultantResult from '../ConsultantResult';
import { putRequest } from '../../requestUtils';

class Recommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMatch: props.isMatch,
    };

    this.getBgColor = this.getBgColor.bind(this);
    this.handleMatchStatus = this.handleMatchStatus.bind(this);
  }

  getBgColor() {
    const { isMatch } = this.state;
    switch (isMatch) {
      case true:
        return 'bg-success';
      case false:
        return 'bg-danger';
      default:
        return '';
    }
  }

  sendMatch(matchState) {
    const { id, csrfToken } = this.props;
    const params = { is_match: matchState };
    return putRequest(
      `/project_recommendations/${id}/set_match`,
      params,
      csrfToken,
    );
  }

  handleMatchStatus(status) {
    this.sendMatch(status).then((response) => {
      this.setState({ isMatch: response.data.match_status });
    });
  }

  render() {
    const {
      item, csrfToken, score, marketplaceHost,
    } = this.props;
    return (
      <div className={`card p-4 mt-4 ${this.getBgColor()}`}>
        <h6>
          Score:
          {score}
        </h6>
        <div className="btn-btn-group mb-2" role="group">
          <button
            type="button"
            onClick={() => this.handleMatchStatus(true)}
            className="btn btn-outline-success mr-2"
          >
            Match
          </button>
          <button
            type="button"
            onClick={() => this.handleMatchStatus(false)}
            className="btn btn-outline-danger mr-2"
          >
            Reject
          </button>
          <button
            type="button"
            onClick={() => this.handleMatchStatus(null)}
            className="btn btn-outline-secondary mr-2"
          >
            Reset
          </button>
        </div>
        <ConsultantResult
          item={item}
          csrfToken={csrfToken}
          displayAddRemove={false}
          marketplaceHost={marketplaceHost}
        />
      </div>
    );
  }
}

Recommendation.propTypes = {
  id: PropTypes.number.isRequired,
  item: PropTypes.shape({}).isRequired,
  csrfToken: PropTypes.string.isRequired,
  isMatch: PropTypes.bool,
  score: PropTypes.number.isRequired,
  marketplaceHost: PropTypes.string.isRequired,
};

Recommendation.defaultProps = {
  isMatch: null,
};

export default Recommendation;
