import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import Select from 'react-select';
import SVG from 'react-inlinesvg';
import { putRequest } from '../../requestUtils';
import checkGreen from '../../../assets/images/checkGreen.svg';
import notAvailable from '../../../assets/images/notAvailable.svg';
import clock from '../../../assets/images/clock.svg';
import clockPerson from '../../../assets/images/clockPerson.svg';
import suitcaseGray from '../../../assets/images/suitcaseGray.svg';
import airplaneGray from '../../../assets/images/airplaneGray.svg';
import dollarGray from '../../../assets/images/dollarGray.svg';
import mapPin from '../../../assets/images/map-pin.png';
import info from '../../../assets/images/info.png';
import toastError from '../../../assets/images/toastError.svg';
import './ConsultantProjectApplication.scss';

class ConsultantProjectApplication extends Component {
  constructor(props) {
    super(props);

    this.tagTypes = {
      area_of_expertises: 'Functional areas',
      therapeutic_areas: 'Therapeutic areas',
      therapies: 'Product Technologies',
      development_phases: 'Development phases',
      disease_codes: 'Disease codes',
      regulatory_pathways: 'Regulatory Pathways',
      degrees: 'Education',
    };

    this.state = {
      action: 'view',
      internalFeedbacks: [],
      feedbackText: '',
      sendEmail: true,
      message: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setAction(value) {
    return () => { this.setState({ action: value, message: this.templateMessage(value) }); };
  }

  requiredExpertisePresent() {
    const { marketplaceDetails } = this.props;

    return Object.keys(this.tagTypes).reduce((agg, type) => (
      agg.concat(marketplaceDetails.missing_priority_tags[type] || []).concat(
        marketplaceDetails.matching_priority_tags[type] || [],
      )), []).length;
  }

  preferredExpertisePresent() {
    const { marketplaceDetails } = this.props;

    return Object.keys(this.tagTypes).reduce((agg, type) => (
      agg.concat(marketplaceDetails.missing_preferred_tags[type] || []).concat(
        marketplaceDetails.matching_preferred_tags[type] || [],
      )), []).length;
  }

  actionVerb() {
    const { action } = this.state;
    switch (action) {
      case 'decline':
        return 'declining';
      case 'waitlist':
        return 'waitlisting';
      case 'accept':
        return 'accepting';
      default:
        return null;
    }
  }

  internalFeedbackOptions() {
    const { marketplaceDetails } = this.props;

    return (
      marketplaceDetails.internal_feedback_reasons.map(opt => ({
        value: opt.id,
        label: opt.name,
      }))
    );
  }

  templateMessage(action) {
    const { marketplaceDetails, projectName } = this.props;

    switch (action) {
      case 'decline':
        return `Dear ${marketplaceDetails.consultant_name},\n
Thank you for indicating your interest in <a href="${marketplaceDetails.project_url}">${projectName}</a>. Though your skills \
are valuable to the Clora network, we will be proceeding with a different candidate for this particular project. \n
You can strengthen your profile by adding past accomplishments and making sure your areas of expertise, \
product technologies, and therapeutic areas are up-to-date. An accurate and comprehensive profile will \
ensure that you are targeted with opportunities that best fit your expertise. \
We encourage you to continue browsing through our <a href="${marketplaceDetails.opportunities_url}">open opportunities</a> \
page for projects that match your skills and interests.\n
I'd like to thank you for your time, interest, and effort.\n`;
      case 'waitlist':
        return `Dear ${marketplaceDetails.consultant_name},\n
Thank you for submitting your resume for <a href="${marketplaceDetails.project_url}">${projectName}</a>. We are still actively reviewing your interest in this project and will be in touch with an update over the next few days. \n
If you have any specific questions in the meantime, please do not hesitate to reach out at support@clora.com. \n`;
      case 'accept':
        return `Dear ${marketplaceDetails.consultant_name},\n
Thank you for submitting your interest for <a href="${marketplaceDetails.project_url}">${projectName}</a>. We would like to schedule a 20-minute call with you so that I can share a few more details about the project and brief you on the next steps. Please feel free to select the best time directly in my calendar here - [clora rep to insert their meeting booking link]. \n
If you have any specific questions, please do not hesitate to reach out at support@clora.com. \n`;
      default:
        return null;
    }
  }

  handleInputChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  handleSubmit() {
    const {
      marketplaceDetails, projectId, userEmail, csrfToken, handleStateChange,
    } = this.props;
    const {
      action, internalFeedbacks, sendEmail, feedbackText, message,
    } = this.state;

    const params = {
      route: 'api/v1/consultant_projects/',
      args: {
        consultant_resource_id: marketplaceDetails.consultant_resource_id,
        project_id: projectId,
        user_email: userEmail,
        rep_action: action,
        internal_feedback_ids: internalFeedbacks.map(reason => reason.value),
        internal_feedback_text: feedbackText,
        send_email: sendEmail,
        message,
      },
    };
    putRequest('/marketplace_api_proxy', params, csrfToken).then(() => {
      this.setState({ action: 'view', flashHeader: `Application ${action} successful.` });
      handleStateChange(action);
    }).catch(() => {
      this.setState({ action: 'view', flashHeader: `Application ${action} not successful.` });
    });
  }

  renderMissingTagWarning() {
    const { marketplaceDetails } = this.props;

    if (marketplaceDetails.missing_priority_tags) {
      return (
        <div className="missing-expertise-warning">
          <SVG className="stop-icon" src={toastError} alt="Missing required expertise" />
          <span className="missing-expertise-text">
            Consultant is missing this required expertise listed in their profile.
          </span>
        </div>
      );
    }
    return null;
  }

  renderProjectTags(priority, tagType, label) {
    const { marketplaceDetails } = this.props;
    const matchingTags = priority ? marketplaceDetails.matching_priority_tags[tagType]
      : marketplaceDetails.matching_preferred_tags[tagType];
    const missingTags = priority ? marketplaceDetails.missing_priority_tags[tagType]
      : marketplaceDetails.missing_preferred_tags[tagType];

    if (!(matchingTags + missingTags).length) return null;

    return (
      <div className="tag-listing">
        <h6 className="expertise-tag-header">{label}</h6>
        {matchingTags.length
          ? (
            <React.Fragment>
              <SVG className="green-check" src={checkGreen} alt="Matched expertise" />
              {matchingTags.map(tag => <span className="badge badge-primary small matching-tag">{tag.name}</span>)}
            </React.Fragment>
          )
          : ''
        }
        {missingTags.length
          ? (
            <React.Fragment>
              {
              priority
                ? <SVG className="not-available" src={notAvailable} alt="Missing expertise" />
                : ''
            }
              {missingTags.map(tag => <span className={`badge badge-secondary small ${priority ? 'missing-tag' : 'normal-tag'}`}>{tag.name}</span>)}
            </React.Fragment>
          )
          : ''
        }
      </div>
    );
  }

  renderInterestDetails() {
    const { marketplaceDetails, toggleFn } = this.props;
    const { flashHeader } = this.state;

    const submittedDate = marketplaceDetails
      && new Date(marketplaceDetails.interest_submitted_on).toString();

    return (
      <React.Fragment>
        <ModalHeader toggle={toggleFn}>
          {marketplaceDetails.name
            ? (
              <React.Fragment>
                {`Applicant: ${marketplaceDetails.consultant_name}`}
                <div className="flash-header">
                  {flashHeader}
                </div>
                <h6>
                  {marketplaceDetails.interest_submitted_on
                    ? `Submitted: ${submittedDate.slice(4, 10)}, ${submittedDate.slice(11, 16)}`
                    : 'Interest has not been submitted'
                }
                </h6>
              </React.Fragment>
            )
            : 'Loading...'
          }
        </ModalHeader>

        { marketplaceDetails.name
          && (
          <ModalBody>
            <div className="style-guide start-application">
              <h5 className="uppercase-demibold mt-15 mb-10">Resume</h5>

              <div className="modal-section">
                { marketplaceDetails.resume_url
                  ? (
                    <a href={marketplaceDetails.resume_url} className="ml-2" target="_blank" rel="noreferrer">
                      {marketplaceDetails.resume_name}
                    </a>
                  )
                  : 'No resume has been submitted'
                }
              </div>

              { this.requiredExpertisePresent()
                ? (
                  <div className="tag-listing modal-section">
                    <h5 className="uppercase-demibold mt-2">Required expertise</h5>
                    <div className="modal-section">
                      { Object.keys(this.tagTypes).map(type => (
                        this.renderProjectTags(true, type, this.tagTypes[type])
                      )) }
                    </div>
                  </div>
                )
                : ''
              }
              { this.preferredExpertisePresent()
                ? (
                  <div className="tag-listing modal-section">
                    <h5 className="uppercase-demibold mt-2">Preferred expertise</h5>
                    <div className="modal-section">
                      { Object.keys(this.tagTypes).map(type => (
                        this.renderProjectTags(false, type, this.tagTypes[type])
                      )) }
                    </div>
                  </div>
                )
                : ''
              }
              <h5 className="uppercase-demibold mt-30">Availability</h5>
              <div className="modal-section">
                This project requires consultants with the following availability
              </div>
              <div className="body-text-2 mt-1 mb-2">
                { marketplaceDetails.approximate_start_date_local
                  ? `Available starting ${marketplaceDetails.approximate_start_date_local}` : ''
                }
              </div>
              { marketplaceDetails.avg_time_required_range
                ? (
                  <div className="availability-text">
                    <SVG src={clock} className="clock" alt="Hours required per week" />
                    {`${marketplaceDetails.avg_time_required_range} / wk`}
                  </div>
                ) : ''
              }
              { marketplaceDetails.duration_range
                ? (
                  <div className="availability-text duration">
                    <SVG src={clockPerson} className="clock-person" alt="Duration of project" />
                    { marketplaceDetails.duration_range }
                  </div>
                ) : ''
              }
              { marketplaceDetails.work_location
                ? (
                  <div className="availability-text">
                    <SVG src={suitcaseGray} className="suitcase-gray" alt="Work Location" />
                    { marketplaceDetails.work_location }
                  </div>
                ) : ''
              }
              { marketplaceDetails.office_location
                ? (
                  <div className="availability-text office">
                    <img src={mapPin} className="map-pin" alt="Office Location" />
                    { marketplaceDetails.office_location }
                  </div>
                ) : ''
              }
              { marketplaceDetails.days_of_travel
                ? (
                  <div className="availability-text">
                    <SVG src={airplaneGray} className="airplane-gray" alt="Travel" />
                    {`Travel up to ${marketplaceDetails.days_of_travel} days/mo${marketplaceDetails.travel_locations && ` to ${marketplaceDetails.travel_locations}`}`}
                  </div>
                ) : ''
              }
              { marketplaceDetails.resume_url ? '✅ Consultant Accepted' : '✖ No resume submitted' }
              <h5 className="uppercase-demibold mt-3">Preferred Hourly Rate (in USD)</h5>
              <div className="modal-section">
                Considering the scope of the project, seniority and responsibilities below,
                what hourly rate range would you consider for this project?
              </div>
              {this.renderProjectTags(false, 'levels', 'Seniority')}
              <h6>Role Responsibilities</h6>
              <ul>{ marketplaceDetails.responsibilities.map(resp => <li>{resp}</li>) }</ul>
              <h6>Preferred hourly rate</h6>
              <div className="hourly-rates-container">
                <div className="requested-hourly-rate mr-10">
                  <SVG src={dollarGray} className="dollar-sign" alt="Hourly rate in dollars" />
                  <input
                    id="requested-hourly-rate"
                    type="text"
                    value={marketplaceDetails.requested_hourly_rate}
                    readOnly
                  />
                </div>
              </div>
              { marketplaceDetails.consultant_take_home_minimum
                && marketplaceDetails.consultant_take_home_maximum
                ? (
                  <div className="hourly-rate-info">
                    <img className="info" src={info} alt="Info" />
                    A typical range for similar roles is
                    <span className="demibold ml-1">
                      $
                      {marketplaceDetails.consultant_take_home_minimum}
                      {' '}
                      - $
                      {marketplaceDetails.consultant_take_home_maximum}
                    </span>
                    , depending on experience
                  </div>
                )
                : ''
              }
              <h5 className="uppercase-demibold mt-2">Tax reporting</h5>
              <div className="modal-section">
                Consultants for this role are responsible for their own federal payroll taxes,
                also known as self-employment tax. Clora will provide you with a
                &nbsp;
                <span className="demibold">1099</span>
                .
              </div>
              { marketplaceDetails.resume_url ? '✅ Consultant Accepted' : '✖ No resume submitted' }
              <br />

              <div className="interest-action-buttons">
                { marketplaceDetails.status
                  ? (
                    <React.Fragment>
                      { marketplaceDetails.status !== 'interest_waitlisted'
                      && <Button className="btn-outline-secondary waitlist-button" onClick={this.setAction('waitlist')}>Waitlist</Button> }
                      { marketplaceDetails.status !== 'interest_approved'
                      && <Button className="btn-primary accept-button" onClick={this.setAction('accept')}>Accept</Button> }
                      { marketplaceDetails.status !== 'interest_rejected'
                      && <Button className="btn-outline-secondary decline-button" onClick={this.setAction('decline')}>Decline</Button> }
                    </React.Fragment>
                  )
                  : ''
                }
              </div>
            </div>
          </ModalBody>
          )}
      </React.Fragment>
    );
  }

  renderActionView() {
    const { projectName, marketplaceDetails } = this.props;
    const {
      action, internalFeedbacks, sendEmail, feedbackText, message,
    } = this.state;

    const placeholder = internalFeedbacks && internalFeedbacks.map(r => r.label).includes('Other') ? 'Explanation (required)' : 'Explanation';


    return (
      <React.Fragment>
        <ModalHeader>
          {
            `You're about to ${action.toLowerCase()}
            ${marketplaceDetails.consultant_name} for ${projectName}!`
          }
        </ModalHeader>
        <ModalBody>
          <div className="modal-body">
            Indicate primary reason(s) you are
            {' '}
            {this.actionVerb()}
            {' '}
            this consultant&apos;s interest:
            <div className="mb-3">
              <Select
                className="rejection-dropdown"
                // styles={customStyles}
                classNamePrefix="clora-multiselect"
                options={this.internalFeedbackOptions()}
                value={internalFeedbacks}
                maxMenuHeight={500}
                onChange={val => this.handleInputChange('internalFeedbacks', val)}
                closeOnSelect={false}
                closeMenuOnSelect={false}
                isMulti
                cacheOptions
              />
            </div>
            Internal notes about
            {' '}
            {action === 'Accept' ? 'accepted interest' : action.toLowerCase()}
            :
            <div className="action-explanation mb-2">
              <textarea
                placeholder={placeholder}
                value={feedbackText}
                onChange={e => this.handleInputChange('feedbackText', e.target.value)}
              />
            </div>
            <div className="option consultant-email-option-container mb-16">
              <span className="consultant-email-option-heading">Do you want to send an email?</span>
              <label className="custom-radio-container mr-15" htmlFor="email">
                <input
                  type="radio"
                  id="email"
                  name="email"
                  value
                  checked={sendEmail}
                  onChange={() => this.setState({ sendEmail: true })}
                />
                <span className="radio-btn radio-btn-red" />
                <span className="radio-text">Yes</span>
              </label>
              <label className="custom-radio-container" htmlFor="no-email">
                <input
                  type="radio"
                  id="no-email"
                  name="no-email"
                  value={false}
                  checked={!sendEmail}
                  onChange={() => this.setState({ sendEmail: false })}
                />
                <span className="radio-btn radio-btn-red" />
                <span className="radio-text">No</span>
              </label>
            </div>
            { sendEmail
              && (
              <div className="rejection-email-text">
                <textarea
                  onChange={e => this.handleInputChange('message', e.target.value)}
                  value={message}
                />
              </div>
              )
            }
            <div className="interest-action-buttons">
              <Button className="btn-outline-secondary back-button" onClick={this.setAction('view')}>Back</Button>
              <Button className="submit-button" onClick={this.handleSubmit}>Submit</Button>
            </div>
          </div>
        </ModalBody>
      </React.Fragment>
    );
  }

  render() {
    const { id, isOpen, toggleFn } = this.props;
    const { action } = this.state;

    return (
      <Modal
        id={`consultant-interest-${id}`}
        isOpen={isOpen}
        toggle={toggleFn}
      >
        { action === 'view' ? this.renderInterestDetails() : this.renderActionView() }
      </Modal>
    );
  }
}

ConsultantProjectApplication.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  projectName: PropTypes.string.isRequired,
  marketplaceDetails: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    avg_time_required_range: PropTypes.string,
    days_of_travel: PropTypes.string,
    approximate_start_date_local: PropTypes.string,
    duration_range: PropTypes.string,
    consultant_take_home_minimum: PropTypes.string,
    consultant_take_home_maximum: PropTypes.string,
    responsibilities: PropTypes.arrayOf(PropTypes.shape({})),
    work_location: PropTypes.string,
    office_location: PropTypes.string,
    matching_priority_tags: PropTypes.arrayOf(PropTypes.shape({})),
    matching_preferred_tags: PropTypes.arrayOf(PropTypes.shape({})),
    missing_priority_tags: PropTypes.arrayOf(PropTypes.shape({})),
    missing_preferred_tags: PropTypes.arrayOf(PropTypes.shape({})),
    consultant_name: PropTypes.string,
    resume_url: PropTypes.string,
    resume_name: PropTypes.string,
    requested_hourly_rate: PropTypes.string,
    internal_feedback_reasons: PropTypes.arrayOf(PropTypes.shape({})),
    project_url: PropTypes.string,
    opportunities_url: PropTypes.string,
    consultant_resource_id: PropTypes.string,
    status: PropTypes.string,
    interest_submitted_on: PropTypes.string,
    travel_locations: PropTypes.string,
  }),
  toggleFn: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  handleStateChange: PropTypes.func.isRequired,
};

ConsultantProjectApplication.defaultProps = {
  marketplaceDetails: {},
};
export default ConsultantProjectApplication;
