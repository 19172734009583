import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import ConsultantResult from '../ConsultantResult';
import CompanyResult from '../CompanyResult';
import ProjectResult from '../ProjectResult';
import ToastMessage from '../common/ToastMessage';
import cloraLoading from '../../../assets/images/DNA-2s-200px.svg';

class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      bulkActionMessage: null,
      bulkMessageType: null,
    };

    this.handleSetBulkActionMessage = this.handleSetBulkActionMessage.bind(this);
  }

  handleSetBulkActionMessage(bulkActionMessage, type) {
    this.setState({ bulkActionMessage, bulkMessageType: type });
  }

  render() {
    const {
      projectConsultants,
      selectedProjectConsultants,
      resource,
      wasSubmitted,
      candidates,
      isError,
      isLoading,
      csrfToken,
      addConsultant,
      removeConsultant,
      selectConsultant,
      deselectConsultant,
      marketplaceHost,
      sourcingAvailable,
      projectId,
      enrichmentStatusOptions,
      sourcingProjects,
      currentPage,
      queryId,
      resultsPerPage,
    } = this.props;
    const { bulkActionMessage, bulkMessageType } = this.state;

    if (isLoading === true) {
      return (
        <div className="flex-grid center-horizontal">
          <SVG src={cloraLoading} />
        </div>
      );
    }

    if (isError === true) {
      return <div><h1>Sorry, something went wrong...</h1></div>;
    }

    return (
      <div className="consultant-results-container">
        <ToastMessage message={bulkActionMessage} type={bulkMessageType} />
        {(wasSubmitted && candidates.length === 0)
          && <span>0 results found</span>
        }
        {candidates.map(
          (candidate, idx) => {
            switch (resource) {
              case 'consultants':
                return (
                  <ConsultantResult
                    key={candidate.id}
                    item={candidate}
                    profileRatings={candidate.profile_ratings}
                    projectRatings={candidate.formatted_reviews}
                    isConsultant={candidate.is_consultant}
                    isContractor={candidate.is_contractor}
                    isFte={candidate.is_fte}
                    isMoonlighter={candidate.is_moonlighter}
                    dnu={candidate.dnu}
                    isAdded={projectConsultants.includes(candidate.id)}
                    isSelected={selectedProjectConsultants.includes(candidate.id)}
                    csrfToken={csrfToken}
                    addConsultant={addConsultant}
                    removeConsultant={removeConsultant}
                    selectConsultant={selectConsultant}
                    deselectConsultant={deselectConsultant}
                    marketplaceHost={marketplaceHost}
                    sourcingAvailable={sourcingAvailable}
                    projectId={projectId}
                    enrichmentStatusOptions={enrichmentStatusOptions}
                    sourcingProjects={sourcingProjects}
                    targetBlank
                    searchResultPage
                    setBulkActionMessage={this.handleSetBulkActionMessage}
                    queryId={queryId}
                    resultPosition={(currentPage - 1) * resultsPerPage + idx + 1}
                  />
                );
              case 'companies':
                return (
                  <CompanyResult
                    key={candidate.id}
                    company={candidate}
                    marketplaceHost={marketplaceHost}
                  />
                );
              case 'projects':
                return (
                  <ProjectResult
                    key={candidate.id}
                    project={candidate}
                    marketplaceHost={marketplaceHost}
                    resultPosition={(currentPage - 1) * resultsPerPage + idx + 1}
                    queryId={queryId}
                  />
                );
              default:
                return null;
            }
          },
        )}
      </div>
    );
  }
}

SearchResults.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  wasSubmitted: PropTypes.bool,
  candidates: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  projectConsultants: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedProjectConsultants: PropTypes.arrayOf(PropTypes.number).isRequired,
  csrfToken: PropTypes.string.isRequired,
  addConsultant: PropTypes.func.isRequired,
  removeConsultant: PropTypes.func.isRequired,
  selectConsultant: PropTypes.func.isRequired,
  deselectConsultant: PropTypes.func.isRequired,
  resource: PropTypes.string,
  marketplaceHost: PropTypes.string.isRequired,
  sourcingAvailable: PropTypes.bool,
  projectId: PropTypes.string,
  sourcingProjects: PropTypes.arrayOf(PropTypes.shape({})),
  enrichmentStatusOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentPage: PropTypes.number,
  queryId: PropTypes.string,
  resultsPerPage: PropTypes.number,
};

SearchResults.defaultProps = {
  resource: 'consultants',
  sourcingAvailable: false,
  projectId: null,
  wasSubmitted: false,
  sourcingProjects: [],
  queryId: null,
  currentPage: 0,
  resultsPerPage: 20,
};

export default SearchResults;
