import React from 'react';
import PropTypes from 'prop-types';
import FilterListItem from '../FilterListItem';
import './FilterList.scss';

const selected = (a, b) => {
  if (Array.isArray(b)) {
    return b.indexOf(a) > -1;
  }

  return a === b;
};

const FilterList = ({ items, onClick, selectedList }) => (
  <ul className="list-nostyle filter-list">
    { items.map(item => (
      <li className="mb-2" key={item.value}>
        <FilterListItem
          label={item.label}
          value={item.value}
          selected={selected(item.value, selectedList)}
          onClick={onClick}
          type={item.type}
        />
      </li>
    ))}
  </ul>
);

FilterList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    ),
    PropTypes.string,
  ]),
};

FilterList.defaultProps = {
  selectedList: [],
};

export default FilterList;
