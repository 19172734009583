import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash.groupby';
import SVG from 'react-inlinesvg';
import { Button } from 'reactstrap';
import StarRating from '../../common/StarRating';
import ModalTrigger from '../../common/ModalTrigger';
import ExpandButton from '../../common/ExpandButton';
import AvailabilityModal from '../../common/AvailabilityModal';
import linkedin from '../../../../assets/images/linkedin.svg';
import phone from '../../../../assets/images/phone.svg';
import emailNavyBlue from '../../../../assets/images/email-navy-blue.svg';
import greyDollar from '../../../../assets/images/greyDollar.svg';
import greyLocation from '../../../../assets/images/greyLocation.svg';
import greyPlane from '../../../../assets/images/greyPlane.svg';
import greyWorkPref from '../../../../assets/images/greyWorkPref.svg';
import '../ConsultantResult.scss';
import './GeneralConsultantInfoDisplay.scss';

const sortDates = (a, b) => {
  const dateA = new Date(a.date);
  const dateB = new Date(b.date);
  return dateA - dateB;
};

const getLatestDate = ratings => (
  new Date(ratings.sort(sortDates).slice(-1)[0].date).toLocaleDateString(
    'en-us',
    { year: '2-digit', month: '2-digit', day: '2-digit' },
  )
);

class GeneralConsultantInfoDisplay extends Component {
  static sortRatingsByDimension(a, b) {
    let comparison = 0;
    if (b.dimension_name > a.dimension_name) {
      comparison = -1;
    } else if (b.dimension_name < a.dimension_name) {
      comparison = 1;
    }
    return comparison;
  }

  constructor(props) {
    super(props);
    this.state = {
      givenName: props.givenName,
      familyName: props.familyName,
      email: props.email,
      phoneNumber: props.phoneNumber,
      linkedinUrl: props.linkedinUrl,
      locality: props.locality,
      editGivenName: props.givenName,
      editFamilyName: props.familyName,
      editEmail: props.email,
      editPhoneNumber: props.phoneNumber,
      editCloraResourceId: props.cloraResourceId,
      editLinkedinUrl: props.linkedinUrl,
      editLocality: props.locality,
      // eslint-disable-next-line react/prop-types
      editEnrichmentStatus: props.enrichmentStatus.value,
      updated: false,
      searchedEmail: false,
      showAvailabilityModal: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderContactInformation = this.renderContactInformation.bind(this);
    this.renderConsultantEditModal = this.renderConsultantEditModal.bind(this);
    this.handleFindEmail = this.handleFindEmail.bind(this);
    this.renderConsultantEmail = this.renderConsultantEmail.bind(this);
    this.renderWorkPreferences = this.renderWorkPreferences.bind(this);
    this.toggleAvailabilityModal = this.toggleAvailabilityModal.bind(this);
  }

  getRatingsTableHeaders() {
    const { ratingsDimensions } = this.props;
    return ['Project Ratings', ...ratingsDimensions, 'Last Update'];
  }

  handleInputChange(v, k) {
    this.setState({ [k]: v.target.value });
  }

  handleSubmit() {
    const { handleEditModalUpdate } = this.props;
    const {
      editGivenName,
      editFamilyName,
      editEmail,
      editPhoneNumber,
      editCloraResourceId,
      editLinkedinUrl,
      editLocality,
      editEnrichmentStatus,
    } = this.state;
    return handleEditModalUpdate({
      editGivenName,
      editFamilyName,
      editEmail,
      editPhoneNumber,
      editCloraResourceId,
      editLinkedinUrl,
      editLocality,
      editEnrichmentStatus,
    })
      .then((resp) => {
        const { data } = resp;
        this.setState({
          givenName: editGivenName,
          familyName: editFamilyName,
          email: editEmail,
          phoneNumber: editPhoneNumber,
          editCloraResourceId,
          linkedinUrl: editLinkedinUrl,
          locality: editLocality,
          editEnrichmentStatus: data.enrichment_status,
        });
      });
  }

  handleFindEmail() {
    const { handleFindEmail } = this.props;
    handleFindEmail().then(() => {
      this.setState({ searchedEmail: true });
    });
  }

  toggleAvailabilityModal() {
    const { showAvailabilityModal } = this.state;
    this.setState({ showAvailabilityModal: !showAvailabilityModal });
  }

  renderProfileEnrichmentStatusBadge() {
    const { enrichmentStatus } = this.props;

    if (!enrichmentStatus.name) {
      return null;
    }

    return (
      <div className="profile-enrichment-status-container">
        Enrichment status:
        <span className={`badge profile-enrichment-status ${enrichmentStatus.value}`}>
          {enrichmentStatus.name}
        </span>
      </div>
    );
  }

  renderMarketplaceStatusBadge() {
    const { cloraResourceId } = this.props;

    const badgeClass = cloraResourceId ? 'consultant-badge' : 'not-consultant-badge';
    return (
      <div className="profile-enrichment-status-container">
        Marketplace status:
        <span className={`badge profile-enrichment-status ${badgeClass}`}>
          {cloraResourceId ? ' Registered' : ' Unregistered'}
        </span>
      </div>
    );
  }

  renderConsultantBadge() {
    const { isConsultant } = this.props;

    if (isConsultant === true) {
      return (
        <div className="consultant-badge">
          <i className="fa fa-user" />
          Is a Consultant
        </div>
      );
    }
    return (
      <div className="not-consultant-badge">
        <i className="fa fa-ban user-not-consultant" />
        Not a consultant
      </div>
    );
  }

  renderProfileReviewStatus(boolField, dateField, copy, className) {
    const { [boolField]: status, [dateField]: changedAt } = this.props;

    if (!status) {
      return null;
    }

    const date = changedAt ? new Date(changedAt) : new Date();
    return (
      <div className={className}>
        <i className="fa fa-check-square" />
        {copy}
        {' '}
        {date.toLocaleDateString('en-US')}
      </div>
    );
  }

  renderLinkedInLink() {
    const { linkedinUrl } = this.state;
    if (!linkedinUrl) {
      return null;
    }

    return (
      <div>
        <a href={linkedinUrl} target="_blank" rel="noopener noreferrer" className="linked-in-link">Linkedin Profile</a>
      </div>
    );
  }

  renderField(label, id, stateName, value, type = 'text') {
    return (
      <div className="form-group">
        {label}
        <input
          id={id}
          className="form-control"
          value={value}
          type={type}
          onChange={val => this.handleInputChange(val, stateName)}
        />
      </div>
    );
  }

  renderTextareaField(label, id, stateName, value) {
    return (
      <div className="form-group">
        {label}
        <textarea
          id={id}
          className="form-control"
          value={value}
          onChange={val => this.handleInputChange(val, stateName)}
        />
      </div>
    );
  }

  renderSelectField(label, id, stateName, value, options) {
    return (
      <div className="form-group">
        {label}
        <select
          id={id}
          className="form-control"
          value={value}
          onChange={val => this.handleInputChange(val, stateName)}
        >
          {
            options.map(option => (
              <option key={option.value} value={option.value}>{option.name}</option>
            ))
          }
        </select>
      </div>
    );
  }

  renderRating(singleProjectRatings) {
    const { ratingsDimensions } = this.props;

    const project = singleProjectRatings[0].project_name;
    return (
      <tr key={project || 'no-project'} className="spaced-project">
        <td key="project-name" className="spaced-cell field-name">
          {project || '-'}
        </td>
        {ratingsDimensions.map((category) => {
          const categoryRatings = singleProjectRatings.filter(r => r.dimension_name === category);
          return (
            <td key={category} className="spaced-cell">
              {categoryRatings.length > 0 ? categoryRatings[0].score : '-'}
            </td>
          );
        })}
        <td key="rating-date" className="spaced-cell">
          {getLatestDate(singleProjectRatings)}
        </td>
      </tr>
    );
  }

  renderProjectRatings(ratings) {
    return (
      <table className="consultant-project-ratings">
        <thead>
          <tr>
            {this.getRatingsTableHeaders().map(
              header => <th key={header} className="spaced-cell">{header}</th>,
            )}
          </tr>
        </thead>
        <tbody>
          {ratings.map(projectRatings => this.renderRating(projectRatings))}
        </tbody>
      </table>
    );
  }

  renderProfileRatings(ratings) {
    const { ratingsDimensions } = this.props;
    return (
      <table className="consultant-project-ratings">
        <thead>
          <tr>
            <th key="consultant-profile-rating" className="spaced-cell">
              Profile Ratings
            </th>
            {ratingsDimensions.map(
              header => <th key={header} className="spaced-cell">{header}</th>,
            )}
            <th key="consultant-profile-rating-date" className="spaced-cell">
              Last Update
            </th>
          </tr>
        </thead>
        <tbody>
          <tr key="profile-rating" className="spaced-project">
            <td key="score-denote" className="spaced-cell field-name">
              Scores:
            </td>
            {ratingsDimensions.map((dimension) => {
              const categoryRatings = ratings.filter(r => r.dimension_name === dimension);
              return (categoryRatings.length > 0
                ? (
                  <td key={dimension} className="spaced-cell">
                    {categoryRatings[0].score}
                  </td>
                )
                : (
                  <td key={dimension} className="spaced-cell">
                    -
                  </td>
                )
              );
            })}
            <td key="last-updated" className="spaced-cell">
              {getLatestDate(ratings)}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderAvailability() {
    const {
      maximumHoursPerWeek,
      availabilityType,
      availabilityConfirmedAt,
    } = this.props;

    let availabilityStatus;
    switch (availabilityType) {
      case 'available':
        availabilityStatus = 'Yes (Available)';
        break;
      case 'not_available':
        availabilityStatus = 'No (Unavailable)';
        break;
      default:
        availabilityStatus = 'Availability Unknown';
    }
    return (
      <React.Fragment>
        <div>{`Availability: ${availabilityStatus}`}</div>
        <div>
          {maximumHoursPerWeek ? `Preferred hours per week: ${maximumHoursPerWeek}` : ''}
        </div>
        <div>
          {availabilityConfirmedAt
          && `Availability confirmed: ${new Date(availabilityConfirmedAt).toLocaleDateString('en-US')}`}
        </div>
      </React.Fragment>
    );
  }

  renderStarRatings() {
    const { profileRatings, projectRatings } = this.props;
    const ratings = profileRatings.concat(projectRatings);

    const internalScores = ratings.filter(rating => (rating.reviewer_type === 'internal' && typeof (rating.score) === 'number')).map(rating => rating.score);
    const clientScores = ratings.filter(rating => (rating.reviewer_type === 'client' && typeof (rating.score) === 'number')).map(rating => rating.score);

    const internalAverage = internalScores.length
      && internalScores.reduce((sc, agg) => (sc + agg), 0) / internalScores.length;
    const clientAverage = clientScores.length
      && clientScores.reduce((sc, agg) => (sc + agg), 0) / clientScores.length;

    return (
      <div className="star-ratings-container">
        <StarRating beforeText="Clora" color="green" rating={internalAverage} />
        <br />
        <StarRating beforeText="Client" color="yellow" rating={clientAverage} />
      </div>
    );
  }

  renderConsultantEditModal(triggerText) {
    const {
      updated,
      editGivenName,
      editFamilyName,
      editEmail,
      editPhoneNumber,
      editCloraResourceId,
      editLinkedinUrl,
      editLocality,
      editEnrichmentStatus,
    } = this.state;
    const {
      enrichmentStatusOptions,
    } = this.props;

    return (
      <ModalTrigger
        idKey="edit-consultant"
        triggerText={triggerText}
        triggerType="link"
        submitButtonText="Submit"
        handleSubmit={this.handleSubmit}
        size="lg"
        triggerClass="inline"
        inline
      >
        <div className="user-form">
          {updated && 'Updated!'}
          <div className="row">
            <div className="col">
              {this.renderField('Given Name', 'given_name', 'editGivenName', editGivenName)}
            </div>
            <div className="col">
              {this.renderField('Family Name', 'family_name', 'editFamilyName', editFamilyName)}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.renderField('Email', 'email', 'editEmail', editEmail)}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.renderField('Phone Number', 'phone_number', 'editPhoneNumber', editPhoneNumber)}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.renderField('Clora Resource Id', 'clora_resource_id', 'editCloraResourceId', editCloraResourceId)}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.renderField('Linkedin Url', 'linkedin_url', 'editLinkedinUrl', editLinkedinUrl)}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.renderField('Locality', 'locality', 'editLocality', editLocality)}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.renderSelectField('Profile enrichment status', 'enrichment_status', 'editEnrichmentStatus', editEnrichmentStatus, enrichmentStatusOptions, 'select')}
            </div>
          </div>
        </div>
      </ModalTrigger>
    );
  }

  renderSearchResultDetails() {
    return (
      <Fragment>
        {this.renderContactInformation()}
        {this.renderWorkPreferences()}
      </Fragment>
    );
  }

  renderContactInformation() {
    const { searchResultPage } = this.props;
    const { phoneNumber, linkedinUrl } = this.state;

    return (
      <div className="consultant-info-container preferences-info-spacing">
        <div className="heading">
          Contact information
          {!searchResultPage && this.renderConsultantEditModal('Edit')}
        </div>
        <div className="consultant-info">
          <span className="info-icons">
            <SVG className="contact-info-image" src={linkedin} title="linkedin" />
          </span>
          {linkedinUrl
            ? <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">LinkedIn profile</a>
            : (this.renderConsultantEditModal('Add Linkedin'))
          }
        </div>
        <div className="consultant-info">
          <span className="info-icons">
            <SVG className="contact-info-image" src={emailNavyBlue} title="email" />
          </span>
          {this.renderConsultantEmail()}
        </div>
        <div className="consultant-info pt-0">
          <span className="info-icons">
            <SVG className="contact-info-image phone-image" src={phone} title="phone number" />
          </span>
          {phoneNumber
            ? <span>{phoneNumber}</span>
            : (this.renderConsultantEditModal('Add phone'))
          }
        </div>
      </div>
    );
  }

  renderConsultantEmail() {
    const { searchedEmail } = this.state;
    const { email } = this.state;

    if (!email && !searchedEmail) {
      return (
        <button className="get-email" type="button" onClick={this.handleFindEmail}>
          Get Email
        </button>
      );
    }
    if (email) {
      return (
        <a className="consultant-email" href={`mailto:${email}`}>{email}</a>
      );
    }
    return this.renderConsultantEditModal('Add Email');
  }

  renderHourlyRate() {
    const {
      minimumHourlyRate, maximumHourlyRate,
    } = this.props;

    let hourlyRate = null;

    if (minimumHourlyRate && maximumHourlyRate) {
      hourlyRate = `$${minimumHourlyRate} - $${maximumHourlyRate}`;
    } else if (minimumHourlyRate) {
      hourlyRate = `$${minimumHourlyRate}`;
    } else if (maximumHourlyRate) {
      hourlyRate = `$${maximumHourlyRate}`;
    }

    if (hourlyRate) {
      return (
        <div>
          <SVG src={greyDollar} className="mr-2 work-pref-image" />
          <div className="work-pref-info">
            <div className="work-pref-info work-pref-bold">
              {hourlyRate}
            </div>
            {' '}
            / hr
          </div>
        </div>
      );
    }

    return (
      ''
    );
  }

  renderWorkPreferences() {
    const {
      travelDays, roleTypes,
    } = this.props;
    const { locality } = this.state;

    const roleNames = roleTypes.map(element => element.name);

    return (
      <div className="consultant-info-container preferences-info-spacing">
        <div className="heading">
          Work preferences
        </div>
        {this.renderHourlyRate()}
        {locality && (
        <div>
          <SVG src={greyLocation} className="mr-2 work-pref-image" />
          <div className="work-pref-info">
            {locality}
          </div>
        </div>
        )}
        {travelDays && (
        <div>
          <SVG src={greyPlane} className="mr-2 work-pref-image" />
          <div className="work-pref-info">
            Travel up to
            {' '}
            {travelDays}
            {' '}
            days/mo
          </div>
        </div>
        )}
        {roleNames.length > 0 && (
        <div>
          <SVG src={greyWorkPref} className="mr-2 work-pref-image" />
          <div className="work-pref-info">
            {roleNames.join(' and ')}
          </div>
        </div>
        )}

      </div>
    );
  }

  renderFullViewDetails() {
    const {
      source, searchResultPage, consultingRestartDate, readyToConsultConfirmedAt,
      emailPreferences, emailPreferencesConfirmedAt, companyFit, readyToConsult,
      companyFitConfirmedAt, travelDays, travelDaysConfirmedAt, inOfficeDays,
      inOfficeDaysConfirmedAt, toggleExpand, expanded, proposalStats, handleAvailabilityModalUpdate,
      availability, availabilityEndDate, availabilityType, availabilityConfirmedAt,
      minimumHoursPerWeek, maximumHoursPerWeek, availabilityNote, dnu,
    } = this.props;
    const { email, phoneNumber, showAvailabilityModal } = this.state;

    return (
      <Fragment>
        <span className="text-muted">
          { source && `Source: ${source}` }
        </span>
        <h5 className="text-muted consultant-email">
          <a href={`mailto:${email}`}>{email}</a>
        </h5>
        <h5 className="text-muted consultant-email">{phoneNumber}</h5>
        <div className="consultant-statuses">
          {this.renderConsultantBadge()}
          {this.renderProfileEnrichmentStatusBadge()}
          {this.renderMarketplaceStatusBadge()}
          {this.renderProfileReviewStatus('isReviewed', 'profileReviewedAt', 'Profile Reviewed:', 'consultant-reviewed')}
          {this.renderProfileReviewStatus('isVetted', 'vettedAt', 'Vetted:', 'consultant-vetted')}
        </div>
        <div className="consultant-links">
          {!searchResultPage && this.renderLinkedInLink()}
        </div>
        <div className="availability">
          {expanded
            && (
            <div>
              {readyToConsult
                && (
                <div>
                  { consultingRestartDate
                    ? `Ready to Consult until ${new Date(consultingRestartDate).toLocaleDateString()}`
                    : 'Ready to Consult'
                  }
                  <span>
                    -
                    Updated on
                    {' '}
                    {new Date(readyToConsultConfirmedAt).toLocaleDateString()}
                  </span>
                </div>
                )
              }
              {!!emailPreferences.length
                && (
                <div>
                  Email Preferences:
                  {' '}
                  {emailPreferences.map(label => label.replace(/_/, ' ')).join(' • ')}
                  <span>
                    -
                    Updated on
                    {' '}
                    {new Date(emailPreferencesConfirmedAt).toLocaleDateString()}
                  </span>
                </div>
                )
              }
              {!!companyFit.length
                && (
                <div>
                  Company Fit:
                  {' '}
                  {companyFit.map(label => label.replace(/_/, ' ')).join(' • ')}
                  <span>
                    -
                    Updated on
                    {' '}
                    {new Date(companyFitConfirmedAt).toLocaleDateString()}
                  </span>
                </div>
                )
              }
              {travelDays
                && (
                <div>
                  Travel Days:
                  {' '}
                  {travelDays}
                  <span>
                    -
                    Updated on
                    {' '}
                    {new Date(travelDaysConfirmedAt).toLocaleDateString()}
                  </span>
                </div>
                )
              }
              {inOfficeDays
                && (
                <div>
                  In-office Days:
                  {' '}
                  {inOfficeDays}
                  <span>
                    -
                    Updated on
                    {' '}
                    {new Date(inOfficeDaysConfirmedAt).toLocaleDateString()}
                  </span>
                </div>
                )
              }
            </div>
            )
          }

          <div>
            {`Proposals IN: ${proposalStats.submitted_to_clora}`}
          </div>

          <div>
            {`Proposals OUT: ${proposalStats.submitted_to_employer}`}
          </div>

          <div>
            {`Matched: ${proposalStats.accepted}`}
          </div>

          <div>
            {`Work Started: ${proposalStats.work_started}`}
          </div>

          {this.renderAvailability()}
          <Button
            outline
            color="secondary"
            size="sm"
            onClick={this.toggleAvailabilityModal}
            className="availability-modal"
          >
            Edit Availability
          </Button>
          <AvailabilityModal
            handleUpdate={handleAvailabilityModalUpdate}
            availability={availability}
            availabilityType={availabilityType}
            availabilityConfirmedAt={availabilityConfirmedAt}
            availabilityEndDate={availabilityEndDate}
            minimumHoursPerWeek={minimumHoursPerWeek}
            maximumHoursPerWeek={maximumHoursPerWeek}
            availabilityNote={availabilityNote}
            showModal={showAvailabilityModal}
            toggleModal={this.toggleAvailabilityModal}
            dnu={dnu}
          />

          <ExpandButton handleToggle={toggleExpand} expanded={expanded} />
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      id,
      target,
      profileRatings,
      projectRatings,
      ratingsDimensions,
      searchResultPage,
      trackResultClick,
    } = this.props;

    const {
      givenName,
      familyName,
    } = this.state;

    const ratingGroupRule = item => (
      item.rater_email
    );

    const projectGroupRule = item => (
      item.project_id
    );

    const sortedProfileRatings = profileRatings.sort(this.constructor.sortRatingsByDimension);
    const groupedProfileRatings = groupBy(sortedProfileRatings, ratingGroupRule);

    const recentProfileRatings = {};
    Object.keys(groupedProfileRatings).map((reviewer) => {
      let latestRatings = [];
      ratingsDimensions.forEach((dimension) => {
        const categoryRatings = groupedProfileRatings[reviewer].filter(
          r => r.dimension_name === dimension,
        );
        if (categoryRatings.length > 0) {
          latestRatings = [...latestRatings, categoryRatings.sort(sortDates).slice(-1)[0]];
        }
      });
      recentProfileRatings[reviewer] = latestRatings;
      return null;
    });

    const sortedProjectRatings = projectRatings.sort(this.constructor.sortRatingsByDimension);
    const groupedProjectRatings = Object.values(groupBy(sortedProjectRatings, ratingGroupRule));
    const multipleProjectRatings = groupedProjectRatings.map(
      reviewerRatings => groupBy(reviewerRatings, projectGroupRule),
    );

    const recentRatings = {};
    multipleProjectRatings.map((reviewer) => {
      const rater = Object.values(reviewer)[0][0].rater_email;
      const projectRecentRatings = Object.values(reviewer).map((project) => {
        let latestRatings = [];
        ratingsDimensions.forEach((dimension) => {
          const categoryRatings = project.filter(
            r => (r.score != null && dimension === r.dimension_name),
          );
          if (categoryRatings.length > 0) {
            latestRatings = [...latestRatings, categoryRatings.sort(sortDates).slice(-1)[0]];
          }
        });
        return latestRatings;
      });
      recentRatings[rater] = projectRecentRatings;
      return null;
    });

    const allReviewers = [...new Set(
      [...Object.keys(groupedProfileRatings), ...Object.keys(recentRatings)],
    )];

    return (
      <div>
        {!searchResultPage
          && (
          <h4>
            General Info
            {this.renderConsultantEditModal('Edit')}
          </h4>
          )
        }
        <div className="consultant-name">
          <a href={`/consultants/${id}`} target={target} onClick={trackResultClick}>
            {`${givenName} ${familyName}`}
          </a>
        </div>
        { (projectRatings || profileRatings)
          && (
            <ModalTrigger
              idKey="consultant-ratings-table"
              triggerText={this.renderStarRatings()}
              triggerType="link"
              size="lg"
            >
              <div className="consultant-ratings-table">
                <h3>
                  {`Most Recent Ratings for ${givenName} ${familyName}`}
                </h3>
                {projectRatings && allReviewers.map(reviewer => (
                  <div>
                    <hr className="reviewer-separate" />
                    <strong>Rated by: </strong>
                    {reviewer}
                    {recentProfileRatings[reviewer]
                      && this.renderProfileRatings(recentProfileRatings[reviewer])
                    }
                    {recentRatings[reviewer]
                      && this.renderProjectRatings(recentRatings[reviewer])}
                  </div>
                ))}
              </div>
            </ModalTrigger>
          )
        }
        {searchResultPage ? this.renderSearchResultDetails() : this.renderFullViewDetails()}
      </div>
    );
  }
}

GeneralConsultantInfoDisplay.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  target: PropTypes.string.isRequired,
  givenName: PropTypes.string.isRequired,
  familyName: PropTypes.string.isRequired,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  linkedinUrl: PropTypes.string,
  cloraResourceId: PropTypes.string,
  locality: PropTypes.string,
  isConsultant: PropTypes.bool,
  isVetted: PropTypes.bool,
  vettedAt: PropTypes.string,
  source: PropTypes.string,
  minimumHourlyRate: PropTypes.number,
  maximumHourlyRate: PropTypes.number,
  minimumHoursPerWeek: PropTypes.number,
  maximumHoursPerWeek: PropTypes.number,
  availability: PropTypes.string,
  availabilityType: PropTypes.string,
  availabilityConfirmedAt: PropTypes.string,
  profileRatings: PropTypes.arrayOf(PropTypes.shape({})),
  projectRatings: PropTypes.arrayOf(PropTypes.shape({})),
  ratingsDimensions: PropTypes.arrayOf(PropTypes.string),
  marketplaceHost: PropTypes.string.isRequired,
  readyToConsult: PropTypes.bool,
  readyToConsultConfirmedAt: PropTypes.string,
  consultingRestartDate: PropTypes.string,
  emailPreferences: PropTypes.shape([]),
  emailPreferencesConfirmedAt: PropTypes.string,
  companyFit: PropTypes.shape([]),
  companyFitConfirmedAt: PropTypes.string,
  travelDays: PropTypes.number,
  travelDaysConfirmedAt: PropTypes.string,
  inOfficeDays: PropTypes.number,
  inOfficeDaysConfirmedAt: PropTypes.string,
  toggleExpand: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  isReviewed: PropTypes.bool.isRequired,
  profileReviewedAt: PropTypes.string,
  roleTypes: PropTypes.arrayOf(PropTypes.shape({})),
  enrichmentStatus: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  enrichmentStatusOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  proposalStats: PropTypes.shape({
    submitted_to_clora: PropTypes.number,
    submitted_to_employer: PropTypes.number,
    accepted: PropTypes.number,
    work_started: PropTypes.number,
  }).isRequired,
  searchResultPage: PropTypes.bool,
  handleEditModalUpdate: PropTypes.func,
  handleFindEmail: PropTypes.func,
  handleAvailabilityModalUpdate: PropTypes.func,
  availabilityEndDate: PropTypes.string,
  availabilityNote: PropTypes.string,
  trackResultClick: PropTypes.func,
  dnu: PropTypes.bool.isRequired,
};

GeneralConsultantInfoDisplay.defaultProps = {
  linkedinUrl: null,
  cloraResourceId: null,
  locality: null,
  isConsultant: null,
  isVetted: null,
  vettedAt: null,
  source: null,
  minimumHourlyRate: null,
  maximumHourlyRate: null,
  minimumHoursPerWeek: null,
  maximumHoursPerWeek: null,
  availability: null,
  availabilityType: null,
  availabilityConfirmedAt: null,
  profileRatings: null,
  projectRatings: null,
  ratingsDimensions: null,
  email: '',
  phoneNumber: '',
  readyToConsult: false,
  readyToConsultConfirmedAt: null,
  consultingRestartDate: null,
  emailPreferences: [],
  emailPreferencesConfirmedAt: null,
  companyFit: [],
  companyFitConfirmedAt: null,
  travelDays: null,
  travelDaysConfirmedAt: null,
  inOfficeDays: null,
  inOfficeDaysConfirmedAt: null,
  profileReviewedAt: null,
  searchResultPage: false,
  roleTypes: [],
  handleEditModalUpdate: () => null,
  handleFindEmail: () => null,
  handleAvailabilityModalUpdate: () => null,
  availabilityEndDate: '',
  availabilityNote: '',
  trackResultClick: () => {},
};

export default GeneralConsultantInfoDisplay;
