import React from 'react';
import PropTypes from 'prop-types';
import Geosuggest from 'react-geosuggest';
import SVG from 'react-inlinesvg';
import './FilterListItem.scss';
import box from '../../../../assets/images/box.svg';
import selectedbox from '../../../../assets/images/selected.svg';

const renderGeosuggest = onClick => (
  <Geosuggest
    types={['(cities)']}
    onChange={v => onClick(v)}
    onSuggestSelect={(obj) => {
      if (obj !== undefined) {
        onClick(obj.label);
      } else {
        onClick('');
      }
    }}
  />
);

const renderTextInput = (label, onClick) => (
  <input
    type="text"
    className="form-control"
    name={label}
    onChange={event => onClick(event.target.value)}
  />
);

const renderSpan = (label, value, selected, onClick) => (
  <div
    className="filter-list-item"
    onClick={() => onClick(value)}
    onKeyUp={() => onClick(value)}
    role="button"
    tabIndex={0}
  >
    { selected ? <SVG src={selectedbox} />
      : <SVG src={box} /> }
    <p className="filter-list-item-label">{label}</p>
  </div>
);

const FilterListItem = ({
  label,
  value,
  selected,
  onClick,
  type,
}) => {
  if (type === 'geosuggest') {
    return renderGeosuggest(onClick);
  }

  if (type === 'text_input') {
    return renderTextInput(label, onClick);
  }

  return renderSpan(label, value, selected, onClick);
};

FilterListItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

FilterListItem.defaultProps = {
  selected: false,
};

export default FilterListItem;
