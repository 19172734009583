import React from 'react';
import PropTypes from 'prop-types';
import { AsyncCreatable } from 'react-select';
import axios from 'axios';

const AsyncCreatableSelect = ({
  values,
  handleSelectChange,
  handleCreateOption,
  autosize,
  field,
}) => {
  const getOptions = input => axios.get(`/autocomplete?field=${field}&query=${input || '*'}`)
    .then(response => (response.data));

  return (
    <AsyncCreatable
      name="skillsForm"
      className="select-design"
      classNamePrefix="overlay-dropdown"
      value={values}
      removeSelected={false}
      loadOptions={getOptions}
      defaultOptions
      onChange={handleSelectChange}
      onCreateOption={handleCreateOption}
      formatCreateLabel={inputValue => `Search '${inputValue}'`}
      closeOnSelect={false}
      onSelectResetsInput={false}
      autosize={autosize}
    />
  );
};

AsyncCreatableSelect.propTypes = {
  values: PropTypes.shape({}),
  handleSelectChange: PropTypes.func.isRequired,
  handleCreateOption: PropTypes.func,
  autosize: PropTypes.bool,
  field: PropTypes.string.isRequired,
};

AsyncCreatableSelect.defaultProps = {
  values: [],
  autosize: true,
  handleCreateOption: () => {},
};

export default AsyncCreatableSelect;
