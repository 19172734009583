import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormText,
} from 'reactstrap';
import '../ModalTrigger/ModalTrigger.scss';
import './AvailabilityModal.scss';
import '../../ConsultantResult/ConsultantResult.scss';
import '../../ConsultantResult/GeneralConsultantInfoDisplay/GeneralConsultantInfoDisplay.scss';

const localDateString = (date, format = 'en-US') => (
  (date ? new Date(date).toLocaleDateString(format, { timeZone: 'UTC' }) : '')
);

const formatLatestAvailability = (availabilityType, startDate, endDate, confirmedAt) => {
  if (availabilityType === 'unknown') {
    return 'Unknown';
  } if (startDate || endDate) {
    return (
      <span>
        {localDateString(startDate, 'en-US')}
        {' - '}
        {localDateString(endDate, 'en-US')}
      </span>
    );
  }
  return (localDateString(confirmedAt));
};

class AvailabilityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availabilityType: props.availabilityType,
      availability: props.availability,
      availabilityConfirmedAt: props.availabilityConfirmedAt,
      availabilityEndDate: props.availabilityEndDate,
      // eslint-disable-next-line react/no-unused-state
      availabilityNote: props.availabilityNote,
      editAvailability: props.availability,
      editAvailabilityEndDate: props.availabilityEndDate,
      editMinimumHoursPerWeek: props.minimumHoursPerWeek,
      editMaximumHoursPerWeek: props.maximumHoursPerWeek,
      editAvailabilityNote: props.availabilityNote,
      editAvailabilityType: props.dnu ? 'dnu' : props.availabilityType,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClearEditAvailabilityInput = this.handleClearEditAvailabilityInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(v, k) {
    this.setState({ [k]: v.target.value });
  }

  handleClearEditAvailabilityInput() {
    this.setState({
      editAvailability: '',
      editAvailabilityEndDate: '',
      editMinimumHoursPerWeek: '',
      editMaximumHoursPerWeek: '',
      editAvailabilityNote: '',
      editAvailabilityType: '',
    });
  }

  handleSubmit() {
    const { handleUpdate, toggleModal } = this.props;
    const {
      editAvailability,
      editAvailabilityEndDate,
      editMinimumHoursPerWeek,
      editMaximumHoursPerWeek,
      editAvailabilityNote,
      editAvailabilityType,
    } = this.state;

    return handleUpdate({
      editAvailability,
      editAvailabilityEndDate,
      editMinimumHoursPerWeek,
      editMaximumHoursPerWeek,
      editAvailabilityNote,
      editAvailabilityType,
      dnu: editAvailabilityType === 'dnu',
    }).then(() => {
      toggleModal();
      this.setState({
        availabilityType: editAvailabilityType,
        availability: editAvailability,
        availabilityEndDate: editAvailabilityEndDate,
        // eslint-disable-next-line react/no-unused-state
        availabilityNote: editAvailabilityNote,
      });
    });
  }

  renderField(label, id, stateName, value, type = 'text') {
    return (
      <div className="form-group">
        {label}
        <input
          id={id}
          className="form-control"
          value={value}
          type={type}
          onChange={val => this.handleInputChange(val, stateName)}
        />
      </div>
    );
  }

  renderTextareaField(label, id, stateName, value) {
    return (
      <div className="form-group">
        {label}
        <textarea
          id={id}
          className="form-control"
          value={value}
          onChange={val => this.handleInputChange(val, stateName)}
        />
      </div>
    );
  }

  render() {
    const {
      editAvailability,
      editAvailabilityEndDate,
      editMinimumHoursPerWeek,
      editMaximumHoursPerWeek,
      editAvailabilityNote,
      editAvailabilityType,
      availabilityEndDate,
      availability,
      availabilityType,
      availabilityConfirmedAt,
    } = this.state;

    const { showModal, toggleModal } = this.props;

    const disableSubmitButton = editAvailabilityType === 'available' && !editAvailability;
    const dateValidator = editAvailability > editAvailabilityEndDate;

    return (
      <Modal isOpen={showModal} toggle={toggleModal} idKey="edit-availability-modal" className="modal-trigger">
        <ModalHeader toggle={toggleModal}>
          Update availability
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormText className="mb-2">
              Latest availability:
              {' '}
              {formatLatestAvailability(
                availabilityType, availability, availabilityEndDate, availabilityConfirmedAt,
              )}
            </FormText>
            <div className={`form-check mb-2 ${editAvailabilityType === 'available' ? 'checked-input' : ''}`}>
              <input
                id="availability"
                type="radio"
                name="availability"
                value="available"
                className="form-check-input"
                checked={editAvailabilityType === 'available'}
                onChange={val => this.handleInputChange(val, 'editAvailabilityType')}
              />
              {' '}
              Availability
            </div>
            <div className={`row mb-2 date-container ${editAvailabilityType !== 'available' ? 'disable-date-container' : ''}`}>
              <div className="col">
                {this.renderField('Starting', 'start_date', 'editAvailability', localDateString(editAvailability, 'en-CA'), 'date')}
              </div>
              <div className="col">
                {this.renderField('Ending', 'end_date', 'editAvailabilityEndDate', localDateString(editAvailabilityEndDate, 'en-CA'), 'date')}
              </div>
              {disableSubmitButton && <span className="text-danger">Starting date is required</span>}
              {dateValidator && <span className="text-danger">Ending date should be after starting date</span>}
            </div>
            <div className={`form-check mb-2 ${editAvailabilityType === 'not_available' ? 'checked-input' : ''}`}>
              <input
                id="not_availability"
                type="radio"
                name="availability"
                value="not_available"
                className="form-check-input"
                checked={editAvailabilityType === 'not_available'}
                onChange={val => this.handleInputChange(val, 'editAvailabilityType')}
              />
              {' '}
              Not Available
            </div>
            <div className="form-check mb-3 text-danger">
              <input
                id="dnu"
                type="radio"
                name="availability"
                value="dnu"
                className="form-check-input"
                checked={editAvailabilityType === 'dnu'}
                onChange={val => this.handleInputChange(val, 'editAvailabilityType')}
              />
              {' '}
              DO NOT USE
            </div>
            <h6 className="mt-2 mb-2">Preferred hours per week</h6>
            <div className="row">
              <div className="col-md-6">
                {this.renderField('Minimum', 'minimum_hours_per_week', 'editMinimumHoursPerWeek', editMinimumHoursPerWeek)}
              </div>
              <div className="col-md-6">
                {this.renderField('Maximum', 'maximum_hours_per_week', 'editMaximumHoursPerWeek', editMaximumHoursPerWeek)}
              </div>
            </div>
            {this.renderTextareaField('Note', 'note', 'editAvailabilityNote', editAvailabilityNote)}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="clear-button" onClick={this.handleClearEditAvailabilityInput}>Clear all</Button>
          <Button className="cancel-button" onClick={toggleModal}>Cancel</Button>
          <Button
            className={`${disableSubmitButton || dateValidator ? 'submit-button-disabled' : 'submit-button'}`}
            onClick={this.handleSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

AvailabilityModal.propTypes = {
  availability: PropTypes.string,
  availabilityType: PropTypes.string,
  availabilityConfirmedAt: PropTypes.string,
  availabilityEndDate: PropTypes.string,
  minimumHoursPerWeek: PropTypes.number,
  maximumHoursPerWeek: PropTypes.number,
  availabilityNote: PropTypes.string,
  handleUpdate: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  dnu: PropTypes.bool.isRequired,
};

AvailabilityModal.defaultProps = {
  availability: null,
  availabilityType: null,
  availabilityConfirmedAt: null,
  availabilityEndDate: null,
  minimumHoursPerWeek: null,
  maximumHoursPerWeek: null,
  availabilityNote: null,
};

export default AvailabilityModal;
