import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Row, Col, Button,
} from 'reactstrap';
import SVG from 'react-inlinesvg';
import capitalize from 'lodash.capitalize';
import { getRequest } from '../../requestUtils';
import * as proposalIcon from '../../../assets/images/proposal.svg';
import * as notesIcon from '../../../assets/images/notes.svg';
import * as emailIcon from '../../../assets/images/email.svg';
import cornerUpRight from '../../../assets/images/corner-up-right.svg';
import trash2 from '../../../assets/images/trash-2.svg';
import sourceOrigin from '../../../assets/images/sourceOrigin.svg';
import source from '../../../assets/images/Source.svg';
import ActionDropdown from '../common/ActionDropdown';
import NotesContainer from '../NotesContainer';
import ConsultantProjectStateModal from '../common/ConsultantProjectStateModal';
import ConsultantProjectApplication from '../ConsultantProjectApplication';
import MatchSourceModal from '../common/MatchSourceModal';
import arrowUpRight from '../../../assets/images/arrow-up-right.svg';
import './ConsultantProjectStatus.scss';

const getRatingContent = (rating, category) => {
  let content;
  if (category === 'Date') {
    content = rating.created_at;
  } else if (category === 'Reviewer') {
    content = rating.author;
  } else {
    content = rating.scores[category];
  }

  return content || '-';
};

class ConsultantProjectStatus extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      currentState: props.currentState,
      statusChanges: props.statusChanges,
      matchSource: props.matchSource,
      latestStatusChangeAt: props.latestStatusChangeAt,
      showNotesModal: false,
      notes: props.notes,
      showHistoryModal: false,
      showRatingsModal: false,
      showStateModal: false,
      showMatchSourceModal: false,
      marketplaceDetails: {
        id: null,
        name: null,
        avg_time_required_range: null,
        days_of_travel: null,
        approximate_start_date_local: null,
        duration_range: null,
        consultant_take_home_minimum: null,
        consultant_take_home_maximum: null,
        responsibilities: [],
        work_location: null,
        office_location: null,
        matching_priority_tags: [],
        matching_preferred_tags: [],
        missing_priority_tags: [],
        missing_preferred_tags: [],
        consultant_name: null,
        resume_url: null,
      },
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleHighlight = this.handleHighlight.bind(this);
    this.handleUnhighlight = this.handleUnhighlight.bind(this);
    this.handleUpdateConsultantState = this.handleUpdateConsultantState.bind(this);
    this.handleConsultantStateChange = this.handleConsultantStateChange.bind(this);
    this.toggleHistoryModal = this.toggleHistoryModal.bind(this);
    this.toggleMatchSourceModal = this.toggleMatchSourceModal.bind(this);
    this.toggleNotesModal = this.toggleNotesModal.bind(this);
    this.toggleRatingsModal = this.toggleRatingsModal.bind(this);
    this.toggleStateModal = this.toggleStateModal.bind(this);
    this.toggleInterestModal = this.toggleInterestModal.bind(this);
    this.onCopy = this.onCopy.bind(this);
  }

  componentDidMount() {
    const { projectId, updated } = this.props;
    if (this.ref && window.location.href.includes(`#${projectId}`)) {
      this.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
    if (updated) this.handleHighlight();
  }


  onCopy(val) {
    this.setState({ [val]: true });

    setTimeout(() => {
      this.setState({ [val]: false });
    }, 1000);
  }

  getRatingsTableHeaders() {
    const { ratingCategories } = this.props;
    return [...ratingCategories, 'Date', 'Reviewer'];
  }

  fetchConsultantInterestDetails() {
    const { consultantCloraResourceId, projectId, userEmail } = this.props;

    const params = {
      route: 'api/v1/consultant_projects/',
      args: {
        consultant_resource_id: consultantCloraResourceId,
        project_id: projectId,
        user_email: userEmail,
      },
    };
    return getRequest('/marketplace_api_proxy', params);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value, name } = target;
    this.setState({
      [name]: value,
    });
  }

  handleHighlight() {
    this.setState({ highlighted: true });
    setTimeout(this.handleUnhighlight, 3000);
  }

  handleUnhighlight() {
    this.setState({ highlighted: false });
  }

  toggleHistoryModal() {
    const { showHistoryModal } = this.state;
    this.setState({ showHistoryModal: !showHistoryModal });
  }

  toggleMatchSourceModal() {
    const { showMatchSourceModal } = this.state;
    this.setState({ showMatchSourceModal: !showMatchSourceModal });
  }

  toggleStateModal() {
    const { showStateModal } = this.state;
    this.setState({ showStateModal: !showStateModal });
  }

  toggleInterestModal() {
    const { showInterestModal } = this.state;
    if (!showInterestModal) {
      this.fetchConsultantInterestDetails().then((response) => {
        this.setState({ marketplaceDetails: response.data });
      });
    }
    this.setState({ showInterestModal: !showInterestModal });
  }

  toggleNotesModal() {
    const { showNotesModal } = this.state;
    this.setState({ showNotesModal: !showNotesModal });
  }

  toggleRatingsModal() {
    const { showRatingsModal } = this.state;
    this.setState({ showRatingsModal: !showRatingsModal });
  }

  handleSelectConsultant(id) {
    const { selectConsultant } = this.props;
    return () => selectConsultant(id);
  }

  handleDeselectConsultant(id) {
    const { deselectConsultant } = this.props;
    return () => deselectConsultant(id);
  }

  handleUpdateConsultantState() {
    const { handleUpdateConsultantStates } = this.props;

    return (updatedConsultantsProjects, updateField, success) => {
      handleUpdateConsultantStates(updatedConsultantsProjects, updateField, success);
      this.handleHighlight();
    };
  }

  handleConsultantStateChange(action) {
    const stateMap = {
      accept: 'application_accepted',
      waitlist: 'waitlisted',
      decline: 'application_rejected',
    };

    this.setState({ currentState: stateMap[action] });
    this.handleHighlight();
  }

  renderInterestModal() {
    const {
      id, resourceName, projectId, projectName, csrfToken, userEmail,
    } = this.props;
    const { marketplaceDetails, showInterestModal } = this.state;

    return (
      <ConsultantProjectApplication
        id={id}
        marketplaceDetails={marketplaceDetails}
        projectName={projectName}
        isOpen={showInterestModal}
        resourceName={resourceName}
        toggleFn={this.toggleInterestModal}
        csrfToken={csrfToken}
        userEmail={userEmail}
        projectId={projectId}
        handleStateChange={this.handleConsultantStateChange}
      />
    );
  }

  renderCheckbox() {
    const { id, isSelected } = this.props;

    return (
      <button
        type="button"
        id={id}
        className="btn btn-checkbox-thin"
        onClick={isSelected ? this.handleDeselectConsultant(id) : this.handleSelectConsultant(id)}
        title="Select for bulk action"
      >
        { isSelected
          && <i className="fa fa-check" />
        }
      </button>
    );
  }


  renderCompany() {
    const { company, marketplaceHost } = this.props;

    if (!company || !company.resource_id) {
      return null;
    }

    return (
      <div className="project-company">
        <a
          href={`${marketplaceHost}companies/${company.resource_id}`}
          className="project-company-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {company.name || 'Link'}
        </a>
      </div>
    );
  }

  renderLatestStatusChange() {
    const { latestStatusChangeAt } = this.state;

    if (!latestStatusChangeAt) {
      return null;
    }

    const dateStr = new Date(latestStatusChangeAt).toLocaleDateString(
      'en-us',
      {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    );

    return (
      <div className="latest-status-change">
        {dateStr}
      </div>
    );
  }

  renderRatingsModalButton() {
    const { ratings } = this.props;

    if (!ratings || ratings.length === 0) {
      return null;
    }

    return (
      <a
        role="button"
        tabIndex={0}
        onClick={this.toggleRatingsModal}
        href="#"
        className="show-ratings-btn"
      >
        See Ratings
      </a>
    );
  }

  renderStateModal() {
    const {
      id, csrfToken, funnelStates, context,
    } = this.props;
    const { showStateModal } = this.state;

    return (
      <ConsultantProjectStateModal
        ids={[id]}
        toggleModal={this.toggleStateModal}
        csrfToken={csrfToken}
        funnelStates={funnelStates}
        showModal={showStateModal}
        handleUpdateConsultantStates={this.handleUpdateConsultantState()}
        context={context}
      />
    );
  }

  renderMatchSourceModal() {
    const {
      id, matchSource, csrfToken, context,
    } = this.props;
    const { showMatchSourceModal } = this.state;

    return (
      <MatchSourceModal
        ids={[id]}
        matchSource={matchSource}
        showModal={showMatchSourceModal}
        csrfToken={csrfToken}
        toggleModal={this.toggleMatchSourceModal}
        afterSubmit={this.handleUpdateConsultantState()}
        context={context}
      />
    );
  }

  renderStatusChangeModal() {
    const { showHistoryModal, statusChanges } = this.state;

    return (
      <Modal className="status-changes" isOpen={showHistoryModal} toggle={this.toggleHistoryModal} size="xl">
        <ModalHeader toggle={this.toggleHistoryModal}>Status Changes</ModalHeader>
        <ModalBody>
          <table className="status-changes-table">
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Old Status</th>
                <th>New Status</th>
                <th>Change Reason</th>
              </tr>
            </thead>
            <tbody>
              {statusChanges.map(change => (
                <tr key={change.id}>
                  <td key="timestamp">{new Date(change.created_at).toLocaleDateString()}</td>
                  <td key="old_status">{change.old_status}</td>
                  <td key="new_status">{change.new_status}</td>
                  <td key="change_reason">{change.change_reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
    );
  }

  renderNotesModal() {
    const {
      addNotePath, csrfToken, ratings, baseLink, context, id,
    } = this.props;
    const { notes, showNotesModal } = this.state;

    return (
      <Modal isOpen={showNotesModal} toggle={this.toggleNotesModal} size="lg" key={`notes-modal-${id}`}>
        <ModalHeader toggle={this.toggleNotesModal} key={`notes-modal-header-${id}`} />
        <ModalBody>
          <NotesContainer
            key={`notes-modal-${id}`}
            header="Consultant/Project Notes"
            notes={notes}
            submitPath={addNotePath}
            csrfToken={csrfToken}
            ratings={ratings}
            afterChange={newNotes => this.setState({ notes: newNotes })}
            baseLink={baseLink}
            context={context}
          />
        </ModalBody>
      </Modal>
    );
  }

  renderRating(rating) {
    return (
      <tr key={rating.id}>
        {this.getRatingsTableHeaders().map(category => (
          <td key={category}>
            {getRatingContent(rating, category)}
          </td>
        ))}
      </tr>
    );
  }

  renderRatingsModal() {
    const { ratings } = this.props;
    const { showRatingsModal } = this.state;

    return (
      <Modal isOpen={showRatingsModal} toggle={this.toggleRatingsModal} modalClassName="marketplace-ratings-modal">
        <ModalHeader toggle={this.toggleRatingsModal}>Ratings from Marketplace Reviews</ModalHeader>
        <ModalBody>
          <table id="marketplace-ratings-table">
            <thead>
              <tr>
                {this.getRatingsTableHeaders().map(header => <th key={header}>{header}</th>)}
              </tr>
            </thead>
            <tbody>
              {ratings.map(rating => this.renderRating(rating))}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
    );
  }

  render() {
    const {
      id,
      resourceName,
      resourceLink,
      email,
      proposalRate,
      marketplaceHost,
      proposalResourceId,
      unsourceConsultant,
      toggleSourceAlternateModal,
      isSelected,
      context,
    } = this.props;

    const {
      currentState,
      matchSource,
      notes,
      highlighted,
    } = this.state;

    const selectedClass = isSelected ? 'selected' : '';
    const applicationStates = [
      'prescreen', 'application_submitted', 'application_accepted', 'waitlisted', 'application_rejected',
    ];

    const propAction = proposalResourceId
      ? ['link', 'View Proposal', `${marketplaceHost}proposals/${proposalResourceId}`, proposalIcon]
      : ['function', 'View Application', this.toggleInterestModal, proposalIcon,
        applicationStates.find(st => st === currentState) ? '' : 'disabled'];

    const statusLinks = [
      propAction,
      ['function', `Open notes (${notes.length})`, this.toggleNotesModal, notesIcon, ''],
      ['link', 'Email this candidate', `mailto:${email}`, emailIcon, email ? '' : 'disabled'],
      ['function', 'Add to another project', toggleSourceAlternateModal, cornerUpRight, ''],
      ['function', 'Remove from current project', unsourceConsultant, trash2, 'unsource-button'],
      ['separator', '----', () => {}, null, 'separator'],
      ['function', 'Update candidate status', this.toggleStateModal, sourceOrigin, 'update-button'],
      ['function', 'Update candidate origin', this.toggleMatchSourceModal, source, ''],
    ];

    return (
      <Row className={`consultant-project ${selectedClass} ${highlighted ? 'highlighted' : ''}`}>
        <Col xs={1} className="checkbox-column">
          {this.renderCheckbox()}
        </Col>

        <Col xs={1} className="main status-column">
          <Button
            className={`badge badge-${currentState} final-state`}
            onClick={this.toggleStateModal}
          >
            {currentState && capitalize(currentState.split('_').join(' '))}
          </Button>
        </Col>

        <Col xs={3} className="resource-display">
          <a className="ml-1" target="_blank" href={resourceLink} rel="noreferrer">
            {resourceName}
            <SVG src={arrowUpRight} className="arrow-up-right-icon" />
          </a>
        </Col>

        <Col xs={1} className="hourly-rate">
          { proposalRate ? `$${proposalRate}` : ''}
        </Col>

        <Col xs={2} className="main">
          {matchSource
            && (
              <a
                className="no-dash"
                role="button"
                onClick={this.toggleMatchSourceModal}
                href="#"
              >
                {matchSource.replace(/_/g, ' ')}
              </a>
            )
          }
        </Col>

        <Col xs={2} className="history">
          {this.renderLatestStatusChange()}
          <div className="show-status-changes-wrapper">
            <a
              className="no-dash match_source"
              role="button"
              tabIndex={0}
              href="#"
              onClick={this.toggleHistoryModal}
            >
              History
            </a>
          </div>
        </Col>

        {
          context === 'Consultant'
          && (
          <Col xs={1}>
            {this.renderCompany()}
          </Col>
          )
        }

        <Col xs={1}>
          <ActionDropdown
            defaultLabel="Actions"
            links={statusLinks}
            id={id}
          />
        </Col>

        {this.renderRatingsModalButton()}
        {this.renderStateModal()}
        {this.renderMatchSourceModal()}
        {this.renderStatusChangeModal()}
        {this.renderNotesModal()}
        {this.renderRatingsModal()}
        {this.renderInterestModal()}
      </Row>
    );
  }
}

ConsultantProjectStatus.propTypes = {
  id: PropTypes.number.isRequired,
  email: PropTypes.string,
  csrfToken: PropTypes.string.isRequired,
  currentState: PropTypes.string.isRequired,
  matchSource: PropTypes.string,
  resourceLink: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  statusChanges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  proposalResourceId: PropTypes.string,
  proposalRate: PropTypes.number,
  marketplaceHost: PropTypes.string.isRequired,
  selectConsultant: PropTypes.func.isRequired,
  deselectConsultant: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    resource_id: PropTypes.string.isRequired,
  }),
  latestStatusChangeAt: PropTypes.string,
  notes: PropTypes.arrayOf(PropTypes.shape({})),
  addNotePath: PropTypes.string,
  ratings: PropTypes.arrayOf(PropTypes.shape({})),
  ratingCategories: PropTypes.arrayOf(PropTypes.string),
  baseLink: PropTypes.string,
  projectId: PropTypes.number,
  projectName: PropTypes.string.isRequired,
  context: PropTypes.oneOf(['Marketplace::Project', 'Consultant']).isRequired,
  unsourceConsultant: PropTypes.func.isRequired,
  updated: PropTypes.bool,
  funnelStates: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  handleUpdateConsultantStates: PropTypes.func.isRequired,
  toggleSourceAlternateModal: PropTypes.func.isRequired,
  consultantCloraResourceId: PropTypes.string,
  userEmail: PropTypes.string.isRequired,
};

ConsultantProjectStatus.defaultProps = {
  email: null,
  matchSource: null,
  proposalRate: null,
  proposalResourceId: null,
  company: null,
  latestStatusChangeAt: null,
  notes: [],
  addNotePath: null,
  ratings: [],
  ratingCategories: [],
  baseLink: null,
  projectId: null,
  updated: false,
  consultantCloraResourceId: null,
};

export default ConsultantProjectStatus;
