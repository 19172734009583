import axios from 'axios';

const getRequestHeaders = csrfToken => ({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': csrfToken,
  },
});

const errorHandler = (err) => {
  if (err && err.response && err.response.data) {
    throw err.response.data;
  } else {
    throw err.message;
  }
};

export const getRequest = (url, params) => (
  axios.get(url,
    {
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      params,
    }).catch(errorHandler)
);

export const postRequest = (url, data, csrfToken) => (
  axios.post(url, data, getRequestHeaders(csrfToken)).catch(errorHandler)
);

export const putRequest = (url, data, csrfToken) => (
  axios.put(url, data, getRequestHeaders(csrfToken)).catch(errorHandler)
);

export const deleteRequest = (url, data, csrfToken) => (
  axios({
    method: 'delete',
    url,
    data,
    headers: { 'X-Requested-With': 'XMLHttpRequest', 'X-CSRF-Token': csrfToken },
  })
);
