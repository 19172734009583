import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import SVG from 'react-inlinesvg';
import { putRequest, postRequest, deleteRequest } from '../../requestUtils';
import ModalTrigger from '../common/ModalTrigger';
import TagEditor from '../TagEditor';
import StringTagEditor from '../StringTagEditor';
import './TagDisplay.scss';
import checkmark from '../../../assets/images/check.svg';
import checkmarkDark from '../../../assets/images/check-dark.svg';

const associationTypesWithProficiency = [
  'AreaOfExpertisesConsultant',
  'ConsultantsHigherLevelRole',
  'ConsultantsTherapeuticArea',
  'ConsultantsDiseaseCode',
  'ConsultantsTherapy',
];

const proficiencyToString = (p) => {
  if (p === 'zero_to_two') {
    return ' (0-2 yrs.)';
  } if (p === 'two_to_five') {
    return ' (2-5 yrs.)';
  } if (p === 'five_to_ten') {
    return ' (5-10 yrs.)';
  } if (p === 'ten_to_fifteen') {
    return ' (10-15 yrs.)';
  } if (p === 'fifteen_to_twenty') {
    return ' (15-20 yrs.)';
  } if (p === 'twenty_plus') {
    return ' (20+ yrs.)';
  }
  return '';
};

const badgeClassesFull = (verified, custom, rejected, sources) => {
  if (rejected === 'rejected_by_consultant' || rejected === 'rejected_by_rep') {
    return 'badge badge-declined';
  } if (sources && sources.includes('marketplace_accepted')) {
    return 'badge badge-accepted';
  } if (verified) {
    return 'badge badge-success';
  } if (custom === false) {
    return 'badge badge-primary';
  }
  return 'badge badge-secondary';
};

const badgeClasses = (verified, rejected, mustHaveMatch, optionalMatch) => {
  const classes = ['badge'];

  if (rejected === 'rejected_by_consultant' || rejected === 'rejected_by_rep') {
    classes.push('badge-declined');
  } else if (verified) {
    classes.push('badge-verified');
  }

  if (mustHaveMatch) {
    classes.push('badge-primary');
  } else if (optionalMatch) {
    classes.push('badge-success');
  } else {
    classes.push('badge-secondary');
  }
  return classes.join(' ');
};

const tooltip = (verified, custom, rejected, sources) => {
  let labels = [];
  if (verified) { labels = labels.concat('Verified'); }
  if (custom) { labels = labels.concat('User-defined labels'); }
  if (sources && rejected === 'not_rejected' && sources.includes('marketplace_accepted')) {
    labels = labels.concat('Consultant accepted');
  }
  if (sources) { labels = labels.concat(sources.filter(s => s !== 'marketplace_accepted')); }
  if (rejected === 'rejected_by_consultant') { labels = labels.concat('Consultant rejected'); }
  if (rejected === 'rejected_by_rep') { labels = labels.concat('Clora rep rejected'); }
  return labels.join(', ');
};

class TagDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      newPicklist: {},
    };

    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.addNewPicklist = this.addNewPicklist.bind(this);
    this.toggleVerify = this.toggleVerify.bind(this);
    this.tagLabel = this.tagLabel.bind(this);
  }

  componentDidMount() {
    this.setState({ tags: this.updateTagModelStatus() });
  }

  updateTagModelStatus() {
    const { tags } = this.props;
    const nonmodelSources = ['marketplace', 'marketplace_accepted', 'rep', 'linkedin', 'supply_acquisition'];
    return tags.filter(t => t.rejected !== 'removed').map((t) => {
      if (t.sources) {
        const models = t.sources.filter(s => !nonmodelSources.includes(s));
        return { ...t, inModel: models.length > 0 };
      }

      return t;
    });
  }

  toggleVerify(index) {
    const { freeForm, csrfToken, associationType } = this.props;
    if (freeForm) return;
    const { tags } = this.state;
    const tag = tags[index];
    if (tag.rejected === 'rejected_by_consultant' || tag.rejected === 'rejected_by_rep') return;
    const params = { association_type: associationType };
    putRequest(`/tags/${tag.id}`, params, csrfToken)
      .then((response) => {
        const newTags = update(tags, { [index]: { $set: response.data } });
        this.setState({ tags: newTags, newPicklist: {} });
      });
  }

  // value: { value: "label" }
  addNewPicklist(value) {
    const { csrfToken, associationType } = this.props;

    postRequest(
      '/tags/add_new_picklist',
      {
        association_type: associationType,
        name: value,
      },
      csrfToken,
    ).then((response) => {
      this.setState({ newPicklist: response.data, error: null });
    });
  }

  // value: { value: 1, label: "label" }
  addTag(value) {
    const { csrfToken, consultantId, associationType } = this.props;
    const { tags } = this.state;

    if (tags.some(t => t.model_id === value.value)) {
      this.setState({ error: 'This tag is already applied.' });
    } else {
      postRequest(
        `/consultants/${consultantId}/tags`,
        {
          association_type: associationType,
          association_id: value.value,
        },
        csrfToken,
      ).then((response) => {
        const newTags = update(tags, { $push: [response.data] });
        this.setState({ tags: newTags, error: null, newPicklist: {} });
      });
    }
  }

  removeTag(index) {
    const { csrfToken, consultantId, associationType } = this.props;
    const { tags } = this.state;
    const tag = tags[index];
    const tagId = (associationType === 'Accomplishment' ? tag.name : tag.id);
    if (tag.inModel === true) {
      this.setState({ error: 'This tag is in a model and cannot be removed.' });
      return;
    }
    if (tag.rejected === 'rejected_by_consultant') {
      this.setState({ error: 'This tag was declined by the consultant and cannot be added or removed.' });
      return;
    }

    const params = { association_type: associationType };
    deleteRequest(`/consultants/${consultantId}/tags/${tagId}`, params, csrfToken)
      .then((response) => {
        const newTags = update(tags, { [index]: { $set: response.data } });
        this.setState({ tags: newTags, newPicklist: {} });
      });
  }

  tagLabel(tag) {
    const { associationType } = this.props;

    if (associationTypesWithProficiency.includes(associationType)) {
      return `${tag.name}${proficiencyToString(tag.proficiency)}`;
    }

    return tag.name;
  }

  renderTags(tags, modalTrigger) {
    const {
      title,
      allowEdit,
      mustHaveMatches,
      optionalMatches,
      isSearchResultPage,
    } = this.props;

    return (
      <div className="result-field tags">
        <div className="tag-header text-capitalize">
          { title }
          { allowEdit && modalTrigger }
        </div>
        { tags.filter(t => t.rejected !== 'removed').map((tag, index) => {
          const mustHaveMatched = (mustHaveMatches || []).includes(tag.name);
          const optionalMatched = (optionalMatches || []).includes(tag.name);
          const badgeClass = isSearchResultPage
            ? badgeClasses(tag.verified, tag.rejected, mustHaveMatched, optionalMatched)
            : badgeClassesFull(tag.verified, tag.user_created, tag.rejected, tag.sources);
          return (
            <span
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              className={badgeClass}
              title={tooltip(
                tag.verified,
                tag.user_created,
                tag.rejected,
                tag.sources,
              )}
            >
              { isSearchResultPage
                ? (tag.verified
                  && (
                  <SVG src={(mustHaveMatched || optionalMatched) ? checkmark : checkmarkDark}>
                    {' '}
                  </SVG>
                  )
                )
                : (
                  <React.Fragment>
                    { tag.inModel
                    && (
                      <span>
                        <i className="fa fa-bullseye" />
                      </span>
                    )
                  }
                    { tag.ranking
                    && (
                      <span className="mb-0 ml-0 ranked-span">
                        <i className="fa ranked" />
                      </span>
                    )
                  }
                  </React.Fragment>
                )
              }
              <span>
                {this.tagLabel(tag)}
              </span>
            </span>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      title,
      associationType,
      consultantId,
      freeForm,
      expanded,
    } = this.props;
    const { tags, error, newPicklist } = this.state;

    if (tags === undefined) {
      return null;
    }

    const tagEditor = (freeForm
      ? (
        <StringTagEditor
          tags={tags}
          toggleVerify={this.toggleVerify}
          addTag={this.addTag}
          removeTag={this.removeTag}
          error={error}
          consultantId={consultantId}
        />
      )
      : (
        <TagEditor
          tags={tags}
          toggleVerify={this.toggleVerify}
          addTag={this.addTag}
          removeTag={this.removeTag}
          association={associationType}
          addNewPicklist={this.addNewPicklist}
          error={error}
          consultantId={consultantId}
          newPicklist={newPicklist}
        />
      )
    );
    const modalTrigger = (
      <ModalTrigger
        headerTitle={'Edit '.concat(title)}
        triggerText="Edit"
        idKey={associationType}
        triggerType="link"
        size="lg"
        triggerClass="inline"
        inline
      >
        { tagEditor }
      </ModalTrigger>
    );

    if (expanded) {
      return (
        <div className="tags-container">
          {this.renderTags(tags, modalTrigger)}
        </div>
      );
    }

    return (
      <div className="tags-container">
        {this.renderTags(tags.slice(0, 8), modalTrigger)}
      </div>
    );
  }
}

TagDisplay.propTypes = {
  title: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  consultantId: PropTypes.number,
  associationType: PropTypes.string,
  csrfToken: PropTypes.string,
  allowEdit: PropTypes.bool,
  freeForm: PropTypes.bool,
  expanded: PropTypes.bool,
  mustHaveMatches: PropTypes.arrayOf(PropTypes.string),
  optionalMatches: PropTypes.arrayOf(PropTypes.string),
  isSearchResultPage: PropTypes.bool,
};

TagDisplay.defaultProps = {
  title: '',
  allowEdit: false,
  associationType: null,
  csrfToken: null,
  freeForm: false,
  mustHaveMatches: [],
  optionalMatches: [],
  isSearchResultPage: false,
  expanded: false,
  consultantId: null,
};

export default TagDisplay;
