import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import { postRequest } from '../../../requestUtils';
import './ConsultantProjectStateModal.scss';

// each sub-array is a column of choices
const rejectionReasons = [
  ['Not Interested', 'Not Available', 'Not A Fit'],
  ['Chose Another', 'Filled Elsewhere', 'Filled Internally'],
];

class ConsultantProjectStateModal extends Component {
  constructor(props) {
    super(props);
    const defaultOpt = props.getNextState();
    this.state = {
      funnelState: (defaultOpt && defaultOpt.value) || 'sourced',
      rejectChecks: [],
      isSaveDisabled: true,
    };

    this.cleanFields = this.cleanFields.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleFunnelStateChange = this.handleFunnelStateChange.bind(this);
    this.handleRejectReasonsChange = this.handleRejectReasonsChange.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  getChangeReasonParam() {
    const { changeReason, funnelState, rejectChecks } = this.state;

    if (funnelState === 'application_rejected') {
      const reason = `${rejectChecks.join('; ')}: ${changeReason}`;
      return reason;
    }

    return changeReason;
  }

  cleanFields() {
    this.setState({
      funnelState: 'sourced',
      rejectChecks: [],
      changeReason: '',
      isSaveDisabled: true,
    });
  }

  handleSubmit() {
    const {
      ids, csrfToken, toggleModal, handleUpdateConsultantStates, context,
    } = this.props;
    const { funnelState } = this.state;
    const params = {
      change_reason: this.getChangeReasonParam(),
      funnel_state: funnelState,
      context,
      ids,
    };

    postRequest('/consultants_projects/update_state/', params, csrfToken)
      .then((response) => {
        this.cleanFields();
        handleUpdateConsultantStates(response.data, 'Status', true);
        toggleModal();
      }).catch(() => {
        handleUpdateConsultantStates([], 'Status', false);
        toggleModal();
      });
  }

  handleInputChange(event) {
    const { target } = event;
    const { value, name } = target;
    this.setState({
      [name]: value,
      isSaveDisabled: !(value.length > 0),
    });
  }

  handleCheckboxChange(event) {
    const { rejectChecks } = this.state;
    const { target } = event;
    const { value } = target;
    if (target.checked) {
      this.setState({ rejectChecks: [...rejectChecks, value] });
    } else {
      const index = rejectChecks.indexOf(value);
      if (index !== -1) {
        const newChecks = rejectChecks.slice();
        newChecks.splice(index, 1);
        this.setState({ rejectChecks: newChecks });
      }
    }
  }

  handleFunnelStateChange(opt) {
    if (opt === 'application_rejected') {
      this.setState({ rejectChecks: ['Not Interested'] });
    } else if (opt === 'sourced' || opt === 'outreach') {
      this.setState({ rejectChecks: [] });
    }

    this.setState({ funnelState: opt });
  }

  handleRejectReasonsChange(opt) {
    this.setState({ rejectChecks: [opt] });
  }

  hideModal() {
    const { toggleModal } = this.props;
    this.cleanFields();
    toggleModal();
  }

  renderRejectReasons() {
    const { rejectChecks } = this.state;

    return (
      <div className="reject-seasons-list">
        {rejectionReasons.flat().map(item => (
          <label htmlFor={item.replace(/\s+/g, '-').toLowerCase()}>
            <input type="radio" name="reject-seasons" value="item" id={item} checked={rejectChecks[0] === item} onClick={() => this.handleRejectReasonsChange(item)} />
            <span>{ item }</span>
          </label>
        ))}
      </div>
    );
  }

  render() {
    const {
      showModal,
      toggleModal,
      headerText,
    } = this.props;
    const { funnelState, isSaveDisabled } = this.state;
    return (
      <Modal isOpen={showModal} toggle={toggleModal} onClosed={() => this.cleanFields()} size="lg" className="consultant-project-state-modal">
        <ModalHeader toggle={toggleModal} />
        <ModalBody>
          <h1>
            { headerText }
          </h1>
          <div className="status-selection">
            <div className="label">Select status</div>
            <div className="choices">
              <div className="radio">
                <label className="sourced" htmlFor="sourced">
                  <input type="radio" name="select-funnel-state" value="sourced" id="sourced" checked={funnelState === 'sourced'} onClick={() => this.handleFunnelStateChange('sourced')} />
                  <span>Sourced</span>
                </label>
              </div>
              <div className="radio">
                <label className="outreach" htmlFor="outreach">
                  <input type="radio" name="select-funnel-state" value="outreach" id="outreach" checked={funnelState === 'outreach'} onClick={() => this.handleFunnelStateChange('outreach')} />
                  <span>Outreach</span>
                </label>
              </div>
              <div className="radio">
                <label className="application_rejected" htmlFor="application_rejected">
                  <input type="radio" name="select-funnel-state" value="application_rejected" id="application_rejected" checked={funnelState === 'application_rejected'} onClick={() => this.handleFunnelStateChange('application_rejected')} />
                  <span>Application Rejected</span>
                </label>
              </div>
            </div>
            { funnelState === 'application_rejected' && this.renderRejectReasons()}
          </div>
          <div className="reason-for-change">
            <label htmlFor="reasons">
              Reason for change
              <textarea name="changeReason" rows="4" cols="10" id="reasons" onChange={this.handleInputChange} aria-labelledby="reasons" />
            </label>
          </div>
          <div className="actions">
            <button type="button" className="cancel" onClick={this.hideModal}>Cancel</button>
            <button type="button" className="save" disabled={isSaveDisabled} onClick={this.handleSubmit}>Save</button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

ConsultantProjectStateModal.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  headerText: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  getNextState: PropTypes.func,
  csrfToken: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleUpdateConsultantStates: PropTypes.func.isRequired,
  context: PropTypes.oneOf(['Marketplace::Project', 'Consultant']).isRequired,
};

ConsultantProjectStateModal.defaultProps = {
  headerText: 'Update candidate status',
  getNextState: () => {},
};

export default ConsultantProjectStateModal;
