import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import FilterList from '../FilterList';
import './FilterModal.scss';

class FilterModal extends Component {
  constructor(props) {
    super(props);

    this.renderFilters = this.renderFilters.bind(this);
    this.renderFilterList = this.renderFilterList.bind(this);
  }

  renderFilters(iterable) {
    const { onItemClick } = this.props;

    return iterable.map((iter) => {
      if (iter.type === 'text') return null;

      return (
        <div className="col-6" key={`${iter.key}_${iter.name}`}>
          <h6 className="filter-modal-status">{iter.name}</h6>
          {iter.name === 'Current Status'
            ? <p className="filter-modal-status-info">Show items currently in the selected states</p>
            : <p className="filter-modal-status-info">Show items that have ever been in the selected states</p>
          }
          {this.renderFilterList(
            iter.options,
            val => onItemClick(val, iter.key),
            iter.key,
          )}
        </div>
      );
    });
  }

  renderFilterList(items, clickHandler, stateName) {
    const { selected } = this.props;
    return (
      <FilterList
        items={items}
        onClick={clickHandler}
        selectedList={selected[stateName]}
      />
    );
  }

  render() {
    const {
      showModal, toggleModal, filters, handleSubmit,
    } = this.props;

    return (
      <Modal isOpen={showModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal} className="filter-modal-header">
          <h6 className="header-text">Select funnel filters</h6>
        </ModalHeader>
        <ModalBody className="filter-modal-body">
          <div className="row">
            {this.renderFilters(filters[0])}
          </div>
        </ModalBody>
        <ModalFooter className="filter-modal-footer">
          <Button className="cancel-button" onClick={toggleModal}>Cancel</Button>
          <Button className="apply-button" onClick={handleSubmit}>Apply</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

FilterModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  selected: PropTypes.shape({}),
  filters: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

FilterModal.defaultProps = {
  selected: {},
};

export default FilterModal;
