import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Input,
} from 'reactstrap';
import { putRequest } from '../../../requestUtils';
import './ContextInputModal.scss';

class ContextInputModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getTextParam() {
    const { contextPrefix } = this.props;
    const { text } = this.state;

    return `${contextPrefix} - ${text}`;
  }

  handleSubmit() {
    const {
      params, submitUrl, afterSubmit, closeModal, csrfToken,
    } = this.props;
    const overallParams = Object.assign({}, params, { context_message: this.getTextParam() });

    putRequest(
      submitUrl,
      overallParams,
      csrfToken,
    ).then((response) => {
      afterSubmit(response);
      closeModal();
    });
  }

  render() {
    const {
      showModal, closeModal, idKey, headerText,
    } = this.props;

    return (
      <Modal
        isOpen={showModal}
        id={`${idKey}-context-modal`}
        toggle={closeModal}
        size="lg"
      >
        <ModalHeader toggle={closeModal}>
          {headerText}
        </ModalHeader>
        <ModalBody>
          <Input
            className="new-note-textarea mt-3 mb-3"
            type="textarea"
            defaultValue=""
            name="context-note"
            rows={4}
            onChange={event => this.setState({ text: event.target.value })}
          />
        </ModalBody>
        <ModalFooter>
          <div className="context-modal-actions">
            <Button
              onClick={closeModal}
              color="secondary"
              size="lg"
              className="cancel-btn"
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleSubmit}
              color="primary"
              size="lg"
              className="submit-btn"
            >
              Submit
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

ContextInputModal.propTypes = {
  params: PropTypes.shape({}),
  submitUrl: PropTypes.string.isRequired,
  afterSubmit: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  idKey: PropTypes.string.isRequired,
  headerText: PropTypes.string,
  contextPrefix: PropTypes.string,
  csrfToken: PropTypes.string.isRequired,
};

ContextInputModal.defaultProps = {
  params: {},
  afterSubmit: () => null,
  headerText: 'Add context for this change:',
  contextPrefix: '',
};

export default ContextInputModal;
