import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalTrigger from '../common/ModalTrigger';
import Filter from '../common/Filter';

const transformIntoFilter = (obj, key) => {
  const filters = [];
  Object.keys(obj).forEach((k) => {
    const options = obj[k].map(i => ({
      label: i,
      value: i,
    }));

    filters.push({
      name: k,
      key,
      options,
    });
  });

  return filters;
};

class SearchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected,
      keywords: props.keywords,
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleFilterChange(value, key) {
    const { selected } = this.state;
    const selectedItems = selected[key];
    let newSelected = null;
    let newState = null;

    if (Array.isArray(selectedItems)) {
      const index = selectedItems.indexOf(value);
      if (index > -1) {
        newSelected = update(selectedItems, { $splice: [[index, 1]] });
      } else {
        newSelected = update(selectedItems, { $push: [value] });
      }
      newState = update(selected, { [key]: { $set: newSelected } });
    } else if (key in selected) {
      newState = update(selected, { $unset: [key] });
    } else {
      newSelected = value;
      newState = update(selected, { [key]: { $set: newSelected } });
    }
    this.setState({ selected: newState });
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const {
      areaOfExpertises,
      therapeuticAreas,
      handleSubmit,
      showModal,
    } = this.props;
    const { selected, keywords } = this.state;
    const otherFilters = [
      {
        name: 'Marketplace',
        key: 'has_clora_profile',
        options: [
          { label: 'On Marketplace', value: 'true' },
        ],
      },
      {
        name: 'Role Types',
        key: 'role_types',
        options: [
          { label: 'Consultant', value: 'is_consultant' },
          { label: 'Contractor', value: 'is_contractor' },
          { label: 'Moonlighter', value: 'is_moonlighter' },
          { label: 'Full-Time', value: 'is_fte' },
        ],
      },
      {
        name: 'Location',
        key: 'locality',
        options: [
          { label: 'Location', value: 'locality', type: 'geosuggest' },
        ],
      },
    ];

    return (
      <ModalTrigger
        triggerText="Search"
        headerTitle="Search"
        idKey="consultant-search"
        size="lg"
        submitButtonText="Search"
        submitButtonClass="btn-success"
        handleSubmit={() => handleSubmit(selected, keywords)}
        showModal={showModal}
      >
        <Filter
          filters={transformIntoFilter(areaOfExpertises, 'area_of_expertises')}
          sort={[]}
          buttonText="Functional Areas"
          selected={selected}
          onItemClick={(item, key) => this.handleFilterChange(item, key)}
          height="auto"
        />
        <Filter
          filters={transformIntoFilter(therapeuticAreas, 'therapeutic_areas')}
          sort={[]}
          buttonText="Therapeutic Areas"
          selected={selected}
          onItemClick={(item, key) => this.handleFilterChange(item, key)}
        />
        <Filter
          filters={otherFilters}
          sort={[]}
          buttonText="Other"
          selected={selected}
          onItemClick={(item, key) => this.handleFilterChange(item, key)}
        />
        <div className="col mt-3">
          <input
            type="text"
            className="form-control"
            name="keywords"
            value={keywords}
            onChange={this.handleInputChange}
          />
        </div>
      </ModalTrigger>
    );
  }
}

SearchModal.propTypes = {
  areaOfExpertises: PropTypes.shape([]).isRequired,
  therapeuticAreas: PropTypes.shape([]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  keywords: PropTypes.string,
  showModal: PropTypes.bool,
  selected: PropTypes.shape({}),
};

SearchModal.defaultProps = {
  keywords: '',
  showModal: false,
  selected: {
    area_of_expertises: [],
    therapeutic_areas: [],
    role_types: [],
  },
};

export default SearchModal;
