import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import {
  Row,
  Col,
  Button,
  Input,
} from 'reactstrap';
import Geosuggest from 'react-geosuggest';
import Select from 'react-select';
import AsyncCreatableSelect from '../../AsyncCreatableSelect';
import { RuleOptionsContext } from '../constants';
import removeMark from '../../../../assets/images/xmark.svg';

const Rule = ({
  rule, parentId, resource, schema, preferenceOptions,
}) => {
  const {
    onPropChange,
    onRuleRemove,
  } = schema;

  const removeRule = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onRuleRemove(rule.id, parentId);
  };

  const onElementChanged = (property, value) => {
    onPropChange(property, value, rule.id);
  };

  const renderDeleteRule = () => (
    <Button className="remove-btn" onClick={removeRule}>
      <SVG className="x-mark" src={removeMark} />
    </Button>
  );

  const renderOptions = (options, type) => (
    options[type].map(option => (
      <option value={option.value}>{option.name}</option>
    ))
  );

  const textRules = () => {
    switch (resource) {
      case 'consultants':
        return [
          { name: 'All text', value: '' },
          { name: 'Name', value: 'name' },
          { name: 'Education', value: 'education_degrees' },
          { name: 'Email', value: 'email' },
          { name: 'LinkedIn URL', value: 'linkedin_url' },
          { name: 'Phone', value: 'phone_number' },
        ];
      default:
        return [{ name: 'All text', value: '' }];
    }
  };

  const showProficiencyOptions = ruleField => (
    ['area_of_expertises', 'higher_level_roles', 'therapeutic_areas', 'therapies', 'disease_codes'].find(
      cat => cat === ruleField,
    )
  );

  const showRankingOptions = ruleField => (
    ['therapeutic_areas', 'therapies', 'disease_codes'].find(cat => cat === ruleField)
  );

  const renderRule = (options) => {
    switch (rule.type) {
      case 'label':
        return (
          <Row>
            <Col>
              <Input type="select" onChange={event => onElementChanged('field', event.target.value)} value={rule.field}>
                {renderOptions(options, rule.type)}
              </Input>
            </Col>
            <Col>
              <AsyncCreatableSelect
                key={`${rule.id}-${rule.field}`}
                values={{ value: rule.name, label: rule.name }}
                field={rule.field}
                handleSelectChange={obj => onElementChanged('name', obj.value)}
              />
            </Col>
            {resource === 'consultants'
              && (
              <Col>
                <Input type="select" placeholder="Proficiency" onChange={event => onElementChanged('verified', event.target.value)} value={rule.verified}>
                  <option value="" selected>Select Option</option>
                  <option value="verified">Verified</option>
                  <option value="unverified">Unverified</option>
                  {rule.type === 'label' && showRankingOptions(rule.field)
                    && (
                    <React.Fragment>
                      <option value="ranking_1">Ranking: 1st only</option>
                      <option value="ranking_2">Ranking: 1st or 2nd</option>
                      <option value="ranking_3">Ranking: 1st, 2nd, 3rd</option>
                    </React.Fragment>
                    )
                  }
                  {rule.type === 'label' && showProficiencyOptions(rule.field)
                    && (
                    <React.Fragment>
                      <option value="proficiency_5">Proficiency: &gt;20 yrs</option>
                      <option value="proficiency_4">Proficiency: &gt;15 yrs</option>
                      <option value="proficiency_3">Proficiency: &gt;10 yrs</option>
                      <option value="proficiency_2">Proficiency: &gt;5 yrs</option>
                      <option value="proficiency_1">Proficiency: &gt;2 yrs</option>
                      <option value="proficiency_0">Proficiency: any</option>
                    </React.Fragment>
                    )
                  }
                </Input>
              </Col>
              )
            }
          </Row>
        );
      case 'status':
        return (
          <Row>
            <Col>
              <div className="static-heading">Project Status:</div>
            </Col>
            <Col>
              <Input type="select" onChange={event => onElementChanged('value', event.target.value)} value={rule.value}>
                <option value="clora_matching">Clora Matching</option>
                <option value="clora_matched">Clora Matched</option>
                <option value="executed_agreement">Executed Agreement</option>
                <option value="work_started">Work Started</option>
                <option value="work_completed">Work Completed</option>
                <option value="on_hold">On Hold</option>
              </Input>
            </Col>
          </Row>
        );
      case 'experience':
        return (
          <Row>
            <Col>
              <Input value="Experience" disabled />
            </Col>
            <Col>
              <Input
                onChange={e => onElementChanged('title', e.target.value)}
                value={rule.title}
                placeholder="Title"
              />
            </Col>
            <Col>
              <Input
                onChange={e => onElementChanged('organization', e.target.value)}
                value={rule.organization}
                placeholder="Company"
              />
            </Col>
            <Col>
              <Input
                onChange={e => onElementChanged('description', e.target.value)}
                value={rule.description}
                placeholder="Description"
              />
            </Col>
            <Col sm="2">
              <Input
                type="select"
                onChange={event => onElementChanged('current', event.target.value)}
                value={rule.current}
              >
                <option value="">Past or Current</option>
                <option value="true">Current</option>
                <option value="false">Not Current</option>
              </Input>
            </Col>
          </Row>
        );
      case 'text':
        return rule.keyword
          ? (
            <Row>
              <Col>
                <Input onChange={e => onElementChanged('query', e.target.value)} placeholder="Enter keyword" value={rule.query} />
              </Col>
            </Row>
          )
          : (
            <Row>
              <Col sm="4">
                <Input type="select" onChange={event => onElementChanged('field', event.target.value)} value={rule.field}>
                  {renderOptions({ text: textRules() }, rule.type)}
                </Input>
              </Col>
              <Col>
                { rule.field === 'education_degrees' ? (
                  <AsyncCreatableSelect
                    values={{ value: rule.query, label: rule.query }}
                    field="degrees"
                    handleSelectChange={(obj) => { onElementChanged('query', obj.value); }}
                    handleCreateOption={(inputValue) => { onElementChanged('query', inputValue); }}
                  />
                )
                  : <Input onChange={e => onElementChanged('query', e.target.value)} placeholder="Enter text" value={rule.query} />
                }
              </Col>
            </Row>
          );
      case 'range':
        return (
          <Row>
            <Col>
              <Input type="select" onChange={event => onElementChanged('field', event.target.value)} value={rule.field}>
                {renderOptions(options, rule.type)}
              </Input>
            </Col>
            <Col sm="2">
              <Input type="select" onChange={event => onElementChanged('operator', event.target.value)} value={rule.operator}>
                <option value="gte">&gt;=</option>
                <option value="gt">&gt;</option>
                <option value="equal">=</option>
                <option value="lte">&lt;=</option>
                <option value="lt">&lt;</option>
                <option value="between">between</option>
              </Input>
            </Col>
            { rule.operator === 'between'
              && [
                <Col>
                  <Input
                    onChange={e => onElementChanged('min', e.target.value)}
                    value={rule.min}
                    placeholder="min"
                  />
                </Col>,
                <Col>
                  <Input
                    onChange={e => onElementChanged('max', e.target.value)}
                    value={rule.max}
                    placeholder="max"
                  />
                </Col>,
              ]
            }
            { rule.operator !== 'between'
              && (
                <Col>
                  <Input
                    placeholder="Enter value"
                    onChange={e => onElementChanged('value', e.target.value)}
                    value={rule.value}
                  />
                </Col>
              )
            }
          </Row>
        );
      case 'bool':
        return (
          <Row>
            <Col>
              <Input type="select" onChange={event => onElementChanged('field', event.target.value)} value={rule.field}>
                {renderOptions(options, rule.type)}
              </Input>
            </Col>
            <Col>
              <Input type="select" onChange={event => onElementChanged('value', event.target.value)} value={rule.value}>
                <option value="true">True</option>
                <option value="false">False</option>
              </Input>
            </Col>
          </Row>
        );
      case 'geo':
        return (
          <Row>
            <Col sm="3">
              <Input
                type="select"
                onChange={event => onElementChanged('field', event.target.value)}
                value={rule.field}
              >
                <option value="locality">Location Area</option>
                <option value="city">City</option>
                <option value="country">Country</option>
                <option value="country_code">Country Code</option>
              </Input>
            </Col>
            <Col>
              { rule.field === 'locality' ? (
                <Geosuggest
                  types={['(cities)']}
                  initialValue={rule.value}
                  onChange={v => onElementChanged('value', v)}
                  onSuggestSelect={(obj) => {
                    if (obj !== undefined) {
                      onElementChanged('value', obj.label);
                    } else {
                      onElementChanged('value', '');
                    }
                  }}
                />
              )
                : (
                  <AsyncCreatableSelect
                    values={{ value: rule.query, label: rule.query }}
                    field={rule.field}
                    handleSelectChange={obj => onElementChanged('query', obj.value)}
                  />
                )
              }
            </Col>
            <Col sm="2">
              { rule.field === 'locality'
                && (
                <Input type="select" onChange={event => onElementChanged('distance', event.target.value)} value={rule.distance}>
                  <option value="25mi">25 miles</option>
                  <option value="50mi">50 miles</option>
                  <option value="100mi">100 miles</option>
                </Input>
                )
              }
            </Col>
          </Row>
        );
      case 'preference':
        return (
          <Row>
            <Col>
              <Input type="select" onChange={event => onElementChanged('field', event.target.value)} value={rule.field}>
                {renderOptions(options, rule.type)}
              </Input>
            </Col>
            <Col>
              <Select
                options={preferenceOptions[rule.field]}
                className="basic-select select-design"
                classNamePrefix="select"
                value={preferenceOptions[rule.field][rule.value]}
                onChange={event => onElementChanged('value', event.value)}
              />
            </Col>
          </Row>
        );
      case 'ready_to_consult':
        return (
          <Row>
            <Col>
              Ready to Consult:
              <Input type="select" onChange={event => onElementChanged('value', event.target.value)} value={rule.value}>
                <option value="true">True</option>
                <option value="false">False</option>
                <option value="nil">N/A</option>
              </Input>
            </Col>
            <Col>
              Min Start Date:
              <Input
                type="date"
                onChange={e => onElementChanged('start_date_min', e.target.value)}
                value={rule.start_date_min}
                placeholder="min"
              />
              Max Start Date:
              <Input
                type="date"
                onChange={e => onElementChanged('start_date_max', e.target.value)}
                value={rule.start_date_max}
                placeholder="max"
              />
            </Col>
            <Col>
              Min Confirmed Date:
              <Input
                type="date"
                onChange={e => onElementChanged('confirmed_at_min', e.target.value)}
                value={rule.confirmed_at_min}
                placeholder="min"
              />
              Max Confirmed Date:
              <Input
                type="date"
                onChange={e => onElementChanged('confirmed_at_max', e.target.value)}
                value={rule.confirmed_at_max}
                placeholder="max"
              />
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };

  return (
    <RuleOptionsContext.Consumer>
      {options => (
        <div className="rule-container">
          <div className="flex-container">
            <div key={rule.id} className="query-rule" data-rule-id={rule.id}>
              {renderRule(options, resource)}
            </div>
            {renderDeleteRule()}
          </div>
        </div>
      )}
    </RuleOptionsContext.Consumer>
  );
};

Rule.propTypes = {
  rule: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    field: PropTypes.string,
    name: PropTypes.string,
    verified: PropTypes.bool,
    title: PropTypes.string,
    organization: PropTypes.string,
    description: PropTypes.string,
    current: PropTypes.bool,
    keyword: PropTypes.string,
    query: PropTypes.string,
    operator: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.string,
    distance: PropTypes.number,
    start_date_min: PropTypes.string,
    start_date_max: PropTypes.string,
    confirmed_at_min: PropTypes.string,
    confirmed_at_max: PropTypes.string,
  }).isRequired,
  parentId: PropTypes.string.isRequired,
  schema: PropTypes.shape({
    onPropChange: PropTypes.func,
    onRuleRemove: PropTypes.func,
  }).isRequired,
  resource: PropTypes.string.isRequired,
  preferenceOptions: PropTypes.shape({}).isRequired,
};

export default Rule;
