import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import SVG from 'react-inlinesvg';
import deleteIconRed from '../../../../assets/images/delete-red.svg';
import './ModalTrigger.scss';

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const inlineStyles = { display: 'inline-block' };

class ModalTrigger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal,
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  open() {
    this.setState({ showModal: true });
  }

  close() {
    this.setState({ showModal: false });
  }

  handleSubmit() {
    const { handleSubmit } = this.props;
    handleSubmit().then(() => this.close());
  }

  renderTrigger() {
    const {
      triggerType,
      triggerText,
      idKey,
      classKey,
      disableTriggerButton,
      triggerIcon,
    } = this.props;
    const linkId = `${idKey.replace(/::/, '')}-link`;
    if (triggerType === 'link') {
      return (
        <a
          className={classKey || 'modal-anchor-trigger'}
          tabIndex={0}
          role="button"
          onClick={this.open}
          id={linkId}
        >
          { triggerText }
        </a>
      );
    }
    return (
      <Button
        onClick={this.open}
        id={`${idKey}-button`}
        className={classKey}
        disabled={disableTriggerButton}
      >
        { triggerIcon ? <i className={triggerIcon} /> : triggerText }
      </Button>
    );
  }

  renderFooter() {
    const {
      showFooter,
      submitButtonText,
      submitButtonClass,
      cancelButtonText,
      cancelButtonClass,
      idKey,
      submitDisabled,
      clearAllButtonText,
      clearAllButtonClass,
      handleClearInputs,
    } = this.props;

    if (showFooter === true) {
      return (
        <ModalFooter>
          { clearAllButtonText && (
            <Button
              className={clearAllButtonClass}
              onClick={handleClearInputs}
            >
              { clearAllButtonText }
              {' '}
              <SVG src={deleteIconRed} title={clearAllButtonText} />
            </Button>
          )}
          { cancelButtonText
            && (
            <Button
              id={`${idKey}-close`}
              onClick={this.close}
              className={cancelButtonClass}
            >
              {cancelButtonText}
            </Button>
            ) }
          { submitButtonText && (
            <Button
              disabled={submitDisabled}
              onClick={this.handleSubmit || this.close}
              id={`${idKey}-confirm`}
              className={submitButtonClass}
            >
              { submitButtonText }
            </Button>
          ) }
        </ModalFooter>
      );
    }

    return null;
  }

  render() {
    const {
      headerTitle,
      idKey,
      children,
      size,
      inline,
      containerClass,
    } = this.props;
    const { showModal } = this.state;

    return (
      <div className={containerClass || {}} style={inline ? inlineStyles : {}}>
        {this.renderTrigger()}

        <Modal
          isOpen={showModal}
          className={containerClass || {}}
          toggle={this.close}
          id={idKey}
          size={size}
        >
          <ModalHeader toggle={this.close}>
            { headerTitle }
          </ModalHeader>
          <ModalBody>
            { children }
          </ModalBody>
          {this.renderFooter()}
        </Modal>
      </div>
    );
  }
}

ModalTrigger.propTypes = {
  submitButtonText: PropTypes.string,
  submitButtonClass: PropTypes.string,
  cancelButtonClass: PropTypes.string,
  cancelButtonText: PropTypes.string,
  triggerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  headerTitle: PropTypes.string,
  handleSubmit: PropTypes.func,
  submitDisabled: PropTypes.bool,
  triggerType: PropTypes.oneOf(['link', 'button', 'node']),
  children: PropTypes.node.isRequired,
  idKey: PropTypes.string,
  classKey: PropTypes.string,
  showFooter: PropTypes.bool,
  showModal: PropTypes.bool,
  size: PropTypes.string,
  inline: PropTypes.bool,
  disableTriggerButton: PropTypes.bool,
  triggerIcon: PropTypes.string,
  containerClass: PropTypes.string,
  handleClearInputs: PropTypes.func,
  clearAllButtonClass: PropTypes.string,
  clearAllButtonText: PropTypes.string,
};

ModalTrigger.defaultProps = {
  submitButtonText: '',
  submitButtonClass: '',
  cancelButtonClass: '',
  cancelButtonText: 'Close',
  headerTitle: '',
  handleSubmit: null,
  submitDisabled: false,
  triggerType: 'button',
  showFooter: true,
  classKey: '',
  showModal: false,
  size: 'sm',
  inline: false,
  disableTriggerButton: false,
  idKey: null,
  triggerIcon: null,
  clearAllButtonText: '',
  handleClearInputs: () => null,
  containerClass: '',
  clearAllButtonClass: '',
};

export default ModalTrigger;
