import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import chevronDown from '../../../../assets/images/chevronDown.svg';

import './ActionDropdown.scss';

const disabledLink = link => link[4] && link[4].includes('disabled');

const clickAction = link => (link[0] === 'function' && !disabledLink(link) ? link[2] : () => {});

class ActionDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderLinkText(link) {
    const { id } = this.props;
    if (link[0] === 'function') {
      return (
        <div
          role="button"
          tabIndex={0}
          key={`${link[1]}-${id}`}
          className={`link-text ${disabledLink(link) ? 'disabled' : ''}`}
          onKeyUp={clickAction(link)}
          onClick={clickAction(link)}
        >
          {link[1] }
          {link[3] && <SVG src={link[3]} className="link-icon" />}
        </div>
      );
    }
    if (link[0] === 'info') {
      return (
        <text
          className="info-text"
        >
          {link[1]}
          <SVG src={link[3]} className="link-icon" />
        </text>
      );
    }
    return (
      <a
        className={`link-text ${disabledLink(link) ? 'disabled' : ''}`}
        target={disabledLink(link) ? '_self' : '_blank'}
        rel="noopener noreferrer"
        href={disabledLink(link) ? '#' : link[2]}
      >
        {link[1]}
        <SVG src={link[3]} className="link-icon" />
      </a>
    );
  }

  renderItem(link) {
    const { id } = this.props;

    switch (link[0]) {
      case 'separator':
        return (<hr key="separator" />);
      case 'hidden':
        return null;
      case 'info':
        return (
          <DropdownItem
            key={`${id}-${link[1]}`}
            className="info"
          >
            {this.renderLinkText(link)}
          </DropdownItem>
        );
      default:
        return (
          <DropdownItem
            key={`${id}-${link[1]}`}
            className={`dropdown-item ${link[4]}`}
          >
            {this.renderLinkText(link)}
          </DropdownItem>
        );
    }
  }

  render() {
    const {
      id, links, defaultLabel, isDisabled, plainTrigger, styleType,
    } = this.props;

    return (
      <UncontrolledButtonDropdown size="md" className="bulk-actions-dropdown" disabled={isDisabled} id={id}>
        <DropdownToggle
          caret={!plainTrigger}
          tag={plainTrigger ? 'span' : 'button'}
          className={
          `${plainTrigger ? '' : 'nav-link dropdown-toggle bulk-action-label'} ${isDisabled ? 'disabled' : ''}`
        }
        >
          { defaultLabel }
          {!plainTrigger && <SVG src={chevronDown} className="chevron" />}
        </DropdownToggle>
        <DropdownMenu className={styleType}>
          {links.map(link => (this.renderItem(link)))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }
}

ActionDropdown.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultLabel: PropTypes.node,
  links: PropTypes.arrayOf(PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.oneOf(['link', 'function', 'info', 'hidden', 'separator']),
      PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      PropTypes.object,
    ]),
  )).isRequired,
  isDisabled: PropTypes.bool,
  plainTrigger: PropTypes.bool,
  styleType: PropTypes.string,
};

ActionDropdown.defaultProps = {
  id: 'bulk',
  defaultLabel: 'Actions',
  isDisabled: false,
  plainTrigger: false,
  styleType: 'bulk-actions-menu',
};

export default ActionDropdown;
