import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import Rule from '../Rule';
import { isRuleGroup } from '../utils/queryBuilderUtils';
import removeMark from '../../../../assets/images/xmark.svg';
import plus from '../../../../assets/images/plus.svg';
import carat from '../../../../assets/images/carat.svg';

const RuleGroup = ({
  rules,
  combinator,
  id,
  parentId,
  resource,
  schema,
  preferenceOptions,
  optionalGroup,
  keywordGroup,
  optionalEnabled,
  optionalToggle,
  removable,
  backgroundStyling,
  withinOptionalGroup,
}) => {
  const {
    createRule,
    createStatusRule,
    createExperienceRule,
    createTextRule,
    createKeywordRule,
    createRangeRule,
    createBoolRule,
    createGeoRule,
    createPreferenceRule,
    createReadyToConsultRule,
    createRuleGroup,
    onRuleAdd,
    onRuleGroupAdd,
    onRuleGroupRemove,
    onPropChange,
  } = schema;

  const parentGroup = backgroundStyling === 'main-group';
  const optionalRules = optionalGroup || withinOptionalGroup;

  const addRule = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newRule = createRule(optionalRules);
    onRuleAdd(newRule, id, resource, parentGroup);
  };

  const addStatusRule = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newRule = createStatusRule(optionalRules);
    onRuleAdd(newRule, id, resource, parentGroup);
  };

  const addExperienceRule = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newRule = createExperienceRule(optionalRules);
    onRuleAdd(newRule, id, resource, parentGroup);
  };

  const addTextRule = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newRule = createTextRule(optionalRules);
    onRuleAdd(newRule, id, resource, parentGroup);
  };

  const addKeywordRule = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newRule = createKeywordRule();
    onRuleAdd(newRule, id, resource, parentGroup);
  };

  const addRangeRule = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newRule = createRangeRule(optionalRules);
    onRuleAdd(newRule, id, resource, parentGroup);
  };

  const addBoolRule = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newRule = createBoolRule(optionalRules);
    onRuleAdd(newRule, id, resource, parentGroup);
  };

  const addGeoRule = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newRule = createGeoRule(optionalRules);
    onRuleAdd(newRule, id, resource, parentGroup);
  };

  const addPreferenceRule = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newRule = createPreferenceRule(optionalRules);
    onRuleAdd(newRule, id, resource, parentGroup);
  };

  const addReadyToConsultRule = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newRule = createReadyToConsultRule(optionalRules);
    onRuleAdd(newRule, id, resource, parentGroup);
  };

  const addRuleGroup = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const newRuleGroup = createRuleGroup(optionalRules, keywordGroup);
    onRuleGroupAdd(newRuleGroup, id, resource, parentGroup);
  };

  const removeRuleGroup = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onRuleGroupRemove(id, parentId);
  };

  const onCombinatorChange = (value) => {
    onPropChange('combinator', value, id);
  };

  let groupStyle = '';
  let optionalGroupSpecific = '';
  if (optionalGroup) {
    groupStyle = 'optional-group';
    optionalGroupSpecific = 'optional-actions';
  }

  let labelStyle = 'optional-off';
  let optionalState = 'optional-toggle-off';
  if (optionalEnabled) {
    labelStyle = 'optional-on';
    optionalState = '';
  }

  let stdSubGroup = 'standard-group sub-group';
  let mainStyle = '';
  if (backgroundStyling === 'main-group') {
    stdSubGroup = 'standard-group';
    mainStyle = 'main';
  }

  return (
    <div key={id} className={`query-group ${mainStyle} ${groupStyle} ${optionalState}`} data-group-id={id}>
      {optionalGroup
        && (
          <div className={`toggle ${optionalState}`}>
            <label htmlFor="optional-toggle">
              <div className="switch">
                <input id="optional-toggle" type="checkbox" onClick={optionalToggle} checked={optionalEnabled} />
                <span className="slider round" />
              </div>
              <span className={`toggle-text ${labelStyle}`}>Use optional rules to rank results</span>
            </label>
          </div>
        )
      }
      <div>
        <Row className={`actions ${mainStyle} ${optionalState} ${optionalGroupSpecific}`}>
          <Col className="combinators">
            <ButtonGroup>
              <Button
                className="btn-combinator btn-left"
                color="primary"
                active={combinator === 'and'}
                value="and"
                onClick={event => onCombinatorChange(event.target.value)}
              >
                AND
              </Button>
              <Button
                className="btn-combinator"
                color="primary"
                active={combinator === 'or'}
                value="or"
                onClick={event => onCombinatorChange(event.target.value)}
              >
                OR
              </Button>
              <Button
                className="btn-combinator btn-right"
                color="primary"
                active={combinator === 'not'}
                value="not"
                onClick={event => onCombinatorChange(event.target.value)}
              >
                NOT
              </Button>
            </ButtonGroup>
          </Col>
          <Col className="rule-additions">
            <ButtonGroup>
              {keywordGroup
                ? (
                  <Button color="success" className="add-keyword-button" onClick={addKeywordRule} size="sm">
                    <SVG src={plus} className="plus-icon" />
                    Keyword
                    <SVG src={carat} className="carat-icon" />
                  </Button>
                )
                : (
                  <UncontrolledButtonDropdown size="sm">
                    <DropdownToggle className="add-rule-button">
                      <SVG src={plus} className="plus-icon" />
                      Rule
                      <SVG src={carat} className="carat-icon" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem value="label" onClick={addRule}>Tag</DropdownItem>
                      {resource === 'projects'
                        && <DropdownItem value="status" onClick={addStatusRule}>Status</DropdownItem>
                      }
                      {resource === 'consultants'
                        && (
                        <div>
                          <DropdownItem value="experience" onClick={addExperienceRule}>Experience</DropdownItem>
                          <DropdownItem value="preference" onClick={addPreferenceRule}>Preference</DropdownItem>
                          <DropdownItem value="ready_to_consult" onClick={addReadyToConsultRule}>Ready to Consult</DropdownItem>
                        </div>
                        )
                      }
                      <DropdownItem value="text" onClick={addTextRule}>Text</DropdownItem>
                      <DropdownItem value="range" onClick={addRangeRule}>Range</DropdownItem>
                      <DropdownItem value="bool" onClick={addBoolRule}>Bool</DropdownItem>
                      <DropdownItem value="geo" onClick={addGeoRule}>Geo</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                )
              }
              <Button color="success" className="add-group-button" onClick={addRuleGroup} size="sm">
                <SVG src={plus} className="plus-icon" />
                Group
              </Button>
              {removable
                && (
                  <Button className="remove-btn group-btn" onClick={removeRuleGroup}>
                    <SVG className="x-mark" src={removeMark} />
                  </Button>
                )
              }
            </ButtonGroup>
          </Col>
        </Row>

        <div className={`${backgroundStyling}`}>
          {rules.map(r => (
            isRuleGroup(r) ? (
              <RuleGroup
                rules={r.rules}
                combinator={r.combinator}
                id={r.id}
                parentId={id}
                schema={schema}
                resource={resource}
                removable={r.removable}
                backgroundStyling={stdSubGroup}
                preferenceOptions={preferenceOptions}
                withinOptionalGroup={optionalRules}
                optionalGroup={r.optional_group}
                keywordGroup={r.keyword_group}
                optionalEnabled={optionalEnabled}
                optionalToggle={optionalToggle}
              />
            ) : (
              <Rule
                rule={r}
                parentId={id}
                schema={schema}
                resource={resource}
                preferenceOptions={preferenceOptions}
              />
            )
          ))}
        </div>
      </div>
    </div>
  );
};

RuleGroup.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  combinator: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  schema: PropTypes.shape({
    createRule: PropTypes.func,
    createStatusRule: PropTypes.func,
    createExperienceRule: PropTypes.func,
    createTextRule: PropTypes.func,
    createKeywordRule: PropTypes.func,
    createRangeRule: PropTypes.func,
    createBoolRule: PropTypes.func,
    createGeoRule: PropTypes.func,
    createPreferenceRule: PropTypes.func,
    createReadyToConsultRule: PropTypes.func,
    createRuleGroup: PropTypes.func,
    onRuleAdd: PropTypes.func,
    onRuleGroupAdd: PropTypes.func,
    onRuleGroupRemove: PropTypes.func,
    onPropChange: PropTypes.func,
  }).isRequired,
  resource: PropTypes.string,
  preferenceOptions: PropTypes.shape({}).isRequired,
  optionalGroup: PropTypes.bool.isRequired,
  keywordGroup: PropTypes.bool.isRequired,
  optionalEnabled: PropTypes.bool.isRequired,
  optionalToggle: PropTypes.func.isRequired,
  removable: PropTypes.bool,
  backgroundStyling: PropTypes.string.isRequired,
  withinOptionalGroup: PropTypes.bool,
};

RuleGroup.defaultProps = {
  parentId: null,
  resource: 'consultants',
  removable: true,
  withinOptionalGroup: false,
};

export default RuleGroup;
