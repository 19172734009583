/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { deleteRequest } from '../../requestUtils';
import ModalTrigger from '../common/ModalTrigger';
import './DeleteConsultant.scss';

class DeleteConsultant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      merge: null,
      error: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value, name } = target;
    this.setState({
      [name]: value,
    });
  }

  handleDelete() {
    const { text, merge } = this.state;
    const { consultantId, csrfToken } = this.props;
    if (text === 'delete') {
      deleteRequest(
        `/consultants/${consultantId}`,
        { merge },
        csrfToken,
      ).then(() => {
        window.location.href = '/consultants';
      });
    } else {
      this.setState({ error: 'Type delete to proceed' });
    }
  }

  render() {
    const { consultantName, projects, notes } = this.props;
    const { text, merge, error } = this.state;
    return (
      <div className="delete">
        <ModalTrigger
          triggerText="Delete this Consultant"
          triggerType="button"
          cancelButtonText="Cancel"
          submitButtonText="Delete"
          submitButtonClass="btn-danger"
          headerTitle={`Warning! You are attempting to delete ${consultantName}. Please review consultant information and association before deletion.`}
          handleSubmit={this.handleDelete}
          idKey="delete"
          classKey="btn-danger"
          size="md"
        >
          <div className="projects">
            <h6>
              {`Linked to ${projects.length} Projects`}
            </h6>
            <ul>
              { projects.map((project, index) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <li key={index}>{project}</li>
              ))}
            </ul>
          </div>
          <div className="projects">
            <h6>{`Linked to ${notes.length} Notes`}</h6>
            <ul>
              { notes.map((note, index) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <li key={index}>{`"${note.data.substring(0, 100)}" - ${note.author}`}</li>
              ))}
            </ul>
          </div>
          <div className="mb-3">
            <label htmlFor="merge">
              Type in ID of consultant you would like the notes and projects to transfer to
              (optional):
              <input
                value={merge}
                className="form-control"
                name="merge"
                id="merge"
                type="number"
                onChange={this.handleInputChange}
              />
            </label>
          </div>
          <div>
            <label htmlFor="text">
              Type &apos;delete&apos; and press the Delete button to continue.
              <input
                value={text}
                className="form-control"
                name="text"
                id="text"
                onChange={this.handleInputChange}
              />
              { error && (<p className="warning">{error}</p>)}
            </label>
          </div>
        </ModalTrigger>
      </div>
    );
  }
}

DeleteConsultant.propTypes = {
  consultantId: PropTypes.number.isRequired,
  consultantName: PropTypes.string.isRequired,
  projects: PropTypes.shape([]).isRequired,
  notes: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }).isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default DeleteConsultant;
