import React from 'react';
import PropTypes from 'prop-types';
import './HeaderDropdown.scss';
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { deleteRequest } from '../../requestUtils';


const deleteAction = (href, csrfToken, redirPath) => () => {
  deleteRequest(href, {}, csrfToken).then(() => { window.location.href = redirPath; });
};

const HeaderDropdown = ({ links, csrfToken }) => (
  <UncontrolledButtonDropdown size="md" className="header-dropdown-container">
    <DropdownToggle className="nav-link dropdown-toggle">
      Home
    </DropdownToggle>
    <DropdownMenu>
      {links.map(link => (
        <DropdownItem key={link[0]}>
          {link[2] === 'delete'
            ? (
              <a
                role="link"
                tabIndex={0}
                onKeyUp={deleteAction(link[1], csrfToken, link[3])}
                onClick={deleteAction(link[1], csrfToken, link[3])}
                className="dropdown-item"
              >
                {link[0]}
              </a>
            )
            : <a href={link[1]} className="dropdown-item">{link[0]}</a>
            }
        </DropdownItem>
      ))}
    </DropdownMenu>
  </UncontrolledButtonDropdown>
);

HeaderDropdown.propTypes = {
  links: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default HeaderDropdown;
