import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import paginatePrev from '../../../../assets/images/chevron-left.svg';
import paginateNext from '../../../../assets/images/chevron-right.svg';
import paginateFirst from '../../../../assets/images/chevrons-left.svg';
import paginateLast from '../../../../assets/images/chevrons-right.svg';
import paginatePrevDisabled from '../../../../assets/images/chevron-left-disabled.svg';
import paginateNextDisabled from '../../../../assets/images/chevron-right-disabled.svg';
import paginateFirstDisabled from '../../../../assets/images/chevrons-left-disabled.svg';
import paginateLastDisabled from '../../../../assets/images/chevrons-right-disabled.svg';
import './Paginate.scss';


const pageButtonStyling = (pageNumber, currentPage, disabledPages) => {
  if (disabledPages.includes(pageNumber)) {
    return 'paginate-btn btn-disabled number-disabled';
  }
  return pageNumber === currentPage ? 'paginate-btn active-btn btn-disabled' : 'paginate-btn';
};
const paginationControlStyling = isDisabled => (isDisabled ? 'paginate-btn btn-disabled' : 'paginate-btn');
const getNextPage = (defaultNextPage, disabledPages, isForward, totalPages) => {
  if (disabledPages.includes(defaultNextPage)) {
    if (isForward) {
      for (let i = defaultNextPage + 1; i <= totalPages; i += 1) {
        if (!disabledPages.includes(i)) {
          return i;
        }
      }
    } else {
      for (let i = defaultNextPage - 1; i >= 1; i -= 1) {
        if (!disabledPages.includes(i)) {
          return i;
        }
      }
    }
  }
  return defaultNextPage;
};

const render = (currentPage, totalPages, onPaginationClick, windowSize, disabledPages) => {
  const firstPage = isDisabled => (
    <button
      type="button"
      className={paginationControlStyling(currentPage === 1)}
      onClick={() => onPaginationClick(getNextPage(1, disabledPages, true, totalPages))}
    >
      {isDisabled ? <SVG src={paginateFirstDisabled} /> : <SVG src={paginateFirst} />}
    </button>
  );

  const previousPage = isDisabled => (
    <button
      type="button"
      className={paginationControlStyling(currentPage === 1)}
      onClick={() => onPaginationClick(
        getNextPage(currentPage - 1, disabledPages, false, totalPages),
      )}
    >
      {isDisabled ? <SVG src={paginatePrevDisabled} /> : <SVG src={paginatePrev} />}

    </button>
  );

  const nextPage = isDisabled => (
    <button
      type="button"
      className={paginationControlStyling(currentPage === totalPages)}
      onClick={
      () => onPaginationClick(getNextPage(currentPage + 1, disabledPages, true, totalPages))
    }
    >
      {isDisabled ? <SVG src={paginateNextDisabled} /> : <SVG src={paginateNext} />}
    </button>
  );

  const lastPage = isDisabled => (
    <button
      type="button"
      className={paginationControlStyling(currentPage === totalPages)}
      onClick={() => onPaginationClick(getNextPage(totalPages, disabledPages, false, totalPages))}
    >
      {isDisabled ? <SVG src={paginateLastDisabled} /> : <SVG src={paginateLast} />}
    </button>
  );

  const currentWindow = [];
  const leftMostPage = currentPage - windowSize;
  const rightMostPage = currentPage + windowSize;
  currentWindow.push(
    <button
      type="button"
      className={pageButtonStyling(1, currentPage, disabledPages)}
      onClick={() => onPaginationClick(1)}
    >
      {1}
    </button>,
  );

  if (leftMostPage > windowSize) {
    currentWindow.push(
      <button
        type="button"
        className="paginate-btn btn-disabled ellipses-btn"
      >
        ...
      </button>,
    );
  }

  for (let i = leftMostPage; i <= currentPage + windowSize; i += 1) {
    const isDisabledPage = disabledPages.includes(i);
    if (i > 1 && i < totalPages && !isDisabledPage) {
      currentWindow.push(
        <button
          type="button"
          className={pageButtonStyling(i, currentPage, disabledPages)}
          onClick={() => onPaginationClick(i)}
        >
          {i}
        </button>,
      );
    }
    if (i > 1 && i < totalPages && isDisabledPage) {
      currentWindow.push(
        <button
          type="button"
          className={pageButtonStyling(i, currentPage, disabledPages)}
          onClick={() => onPaginationClick(i)}
        >
          {i}
        </button>,
      );
    }
  }

  if (rightMostPage < totalPages - windowSize) {
    currentWindow.push(
      <button
        type="button"
        className="paginate-btn btn-disabled ellipses-btn"
      >
        ...
      </button>,
    );
  }

  if (totalPages > 1) {
    currentWindow.push(
      <button
        type="button"
        className={pageButtonStyling(totalPages, currentPage, disabledPages)}
        onClick={() => onPaginationClick(totalPages)}
      >
        {totalPages}
      </button>,
    );
  }


  return (
    <div className="paginate-box">
      {firstPage(currentPage === 1)}
      {previousPage(currentPage === 1)}
      {currentWindow}
      {nextPage(currentPage === totalPages)}
      {lastPage(currentPage === totalPages)}
    </div>
  );
};

const Paginate = ({
  currentPage,
  totalPages,
  onPaginationClick,
  windowSize,
  disabledPages,
}) => (
  render(currentPage, totalPages, onPaginationClick, windowSize, disabledPages)
);

Paginate.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPaginationClick: PropTypes.func.isRequired,
  windowSize: PropTypes.number,
  disabledPages: PropTypes.arrayOf(PropTypes.number),
};

Paginate.defaultProps = {
  windowSize: 2,
};

export default Paginate;
