import React from 'react';
import PropTypes from 'prop-types';
import './InputFilterContainer.scss';

const InputFilterContainer = ({
  resourceName, onItemClick, filterKey, placeholder,
}) => (
  <div className="input-filter-container">
    <span>
      <i className="fa fa-search" aria-hidden="true" />
    </span>
    <span className="input-filter-container-search">
      <input
        type="text"
        value={resourceName}
        size={20}
        onChange={e => onItemClick(e.target.value, filterKey)}
        placeholder={placeholder}
      />
    </span>
  </div>
);

InputFilterContainer.propTypes = {
  filterKey: PropTypes.string.isRequired,
  resourceName: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};


InputFilterContainer.defaultProps = {
  resourceName: '',
};


export default InputFilterContainer;
