// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
import $ from 'jquery';

var handleCustomTag;

handleCustomTag = function(params) {
  var term;
  term = $.trim(params.term);
  if (term === '') {
    return null;
  }
  return {
    id: 'custom:' + term,
    text: term
  };
};

$( document ).ready(function() {
  $('.select2-select').select2({
    theme: 'bootstrap',
    placeholder: 'Select or enter new keywords',
    width: 'auto',
    tags: true,
    createTag: handleCustomTag,
    /*
    minimumInputLength: 1,
    ajax: {
      url: '/skills/select2_autocomplete',
      dataType: 'json',
      data: function(params) {
        console.log(params);
        var query = {
          query: params.term
        }
        return query;
      },
      processResults: function(data) {
        console.log(data);
        return {
          results: data
        };
      }
    }
    */
  });

  $('.select2-select-no-custom').select2({
    theme: 'bootstrap',
    placeholder: 'Select keywords',
    width: 'auto',
    tags: false,
  });

  $('.select2#higher-level-roles-select').select2({
    theme: 'bootstrap',
    placeholder: 'Select or enter roles',
    width: 'auto',
    tags: true,
    createTag: handleCustomTag,
    ajax: {
      url: '/tags/options', 
      data: function(params) {
        $aoeSelect = $('#area-of-expertises-select');
        var consultantId = $('#consultant-id').val();
        var query = {
          association_type: 'ConsultantsHigherLevelRole', 
          area_of_expertise_ids: $aoeSelect.val(),
          consultant_id: consultantId
        };
        return query;
      },
      processResults: function(data) {
        return {
          results: data.map(function(elm) {
            return { id: elm.value, text: elm.label }
          })
        }
      }
    }
  });
});
