import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Input,
} from 'reactstrap';
import Rating from '../Rating';
import { postRequest } from '../../requestUtils';

const sortDates = (a, b) => {
  const dateA = new Date(a.date);
  const dateB = new Date(b.date);
  return dateA - dateB;
};

const recentRatings = (dimensions, ratings, consultant) => {
  let latestRatings = [];
  dimensions.map((dimension) => {
    const categoryRatings = ratings.filter(
      r => (dimension === r.dimension_name),
    );
    if (categoryRatings.length > 0) {
      latestRatings = [...latestRatings, categoryRatings.sort(sortDates).slice(-1)[0]];
    } else {
      const noRating = {
        dimension_name: dimension,
        score: null,
        consultant_id: consultant,
      };
      latestRatings = [...latestRatings, noRating];
    }
    return null;
  });
  return latestRatings;
};

class RatingsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      dimension: null,
      ratingComment: null,
      score: null,
      index: null,
      ratings: recentRatings(props.ratingDimensions, props.ratings, props.consultantId),
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value, name } = target;
    this.setState({
      [name]: value,
    });
  }

  handleSelectChange(event, name, index) {
    const { value } = event;
    this.setState({
      showModal: true,
      dimension: name,
      score: value,
      index,
    });
  }

  toggleModal() {
    const showModal = this.state;
    this.setState({
      showModal: !showModal,
    });
  }

  handleSubmit() {
    const { consultantId, csrfToken } = this.props;
    const {
      ratings, dimension, score, ratingComment, index,
    } = this.state;
    const params = {
      dimension,
      score,
      rating_comment: ratingComment,
    };

    postRequest(`/consultants/${consultantId}/rate`, params, csrfToken)
      .then((response) => {
        const newRatings = ratings.slice();
        newRatings[index] = response.data.data;
        this.setState({
          showModal: false,
          ratings: newRatings,
        });
      });
  }

  renderRating(score, dimension, index) {
    if (score === null) {
      return (
        <Rating
          dimension={dimension}
          handleInputChange={this.handleSelectChange}
          index={index}
        />
      );
    }

    return (
      <Rating
        score={{ value: score, label: score.toString() }}
        dimension={dimension}
        handleInputChange={this.handleSelectChange}
        index={index}
      />
    );
  }

  render() {
    const { ratings, showModal } = this.state;

    return (
      <div>
        <h4>Ratings</h4>
        <div className="row align-items-end">
          {ratings.map((rating, index) => (
            <div className="col" key={rating.id}>
              {this.renderRating(rating.score, rating.dimension_name, index)}
            </div>
          ))}
        </div>

        <Modal isOpen={showModal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            Rating Comment
          </ModalHeader>
          <ModalBody>
            <Input
              type="textarea"
              name="ratingComment"
              onChange={this.handleInputChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleSubmit}>Submit</Button>
            <Button onClick={this.toggleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

RatingsContainer.propTypes = {
  consultantId: PropTypes.number.isRequired,
  ratings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  csrfToken: PropTypes.string.isRequired,
  ratingDimensions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RatingsContainer;
