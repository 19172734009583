import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import chevronDown from '../../../../assets/images/chevron-down.svg';
import chevronUp from '../../../../assets/images/chevron-up.svg';

const ExpandButton = ({
  handleToggle,
  expanded,
  expandAll,
  expandText,
}) => {
  if (expandAll) {
    return (
      <button
        type="button"
        className="expand-all-button expand-button mt-2"
        onClick={handleToggle}
      >
        {expanded ? '- Collapse all' : '+ Expand all'}
      </button>
    );
  }

  return (
    <button
      type="button"
      className="expand-button"
      onClick={handleToggle}
    >
      {expandText && (expanded ? expandText[0] : expandText[1])}
      {!expandText && (expanded
        ? (
          <div className="expand-button-text">
            See less
            {' '}
            <SVG src={chevronUp} className="expand-icon" />
          </div>
        )
        : (
          <div className="expand-button-text">
            See more
            {' '}
            <SVG src={chevronDown} className="expand-icon" />
          </div>
        ))}
    </button>
  );
};

ExpandButton.defaultProps = {
  expanded: false,
  expandAll: false,
  expandText: PropTypes.arrayOf(PropTypes.string),
};

ExpandButton.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
  expandAll: PropTypes.bool,
  expandText: null,
};

export default ExpandButton;
