import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import SVG from 'react-inlinesvg';
import update from 'immutability-helper';
import InputFilterContainer from '../InputFilterContainer';
import FilterModal from '../FilterModal';
import * as filterIcon from '../../../../assets/images/filter.svg';
import './FilterContainer.scss';

class FilterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilterModal: false,
      tempFilterStatus: props.selected,
    };

    this.updateSelectedFilterListItem = this.updateSelectedFilterListItem.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);
    this.toggleFilterModal = this.toggleFilterModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { updateSelected } = this.props;
    const { tempFilterStatus } = this.state;
    updateSelected(tempFilterStatus);
    this.toggleFilterModal();
  }

  toggleFilterModal() {
    const { showFilterModal } = this.state;
    this.setState({ showFilterModal: !showFilterModal });
  }

  handleClearFilters() {
    const { initializeFilters } = this.props;
    initializeFilters();
  }

  updateSelectedFilterListItem(clickedItem, key) {
    const { updateSelected } = this.props;
    const { tempFilterStatus } = this.state;
    const newState = { ...tempFilterStatus };
    const selectedItems = newState[key];
    let newSelected = null;

    if (Array.isArray(selectedItems)) {
      const index = selectedItems.indexOf(clickedItem);
      if (index > -1) {
        newSelected = update(selectedItems, { $splice: [[index, 1]] });
      } else {
        newSelected = update(selectedItems, { $push: [clickedItem] });
      }
    } else if (selectedItems !== clickedItem) {
      newSelected = clickedItem;
    }
    newState[key] = newSelected;
    this.setState({ tempFilterStatus: newState });
    updateSelected(newState);
  }

  render() {
    const { filters, selected } = this.props;
    const { showFilterModal, tempFilterStatus } = this.state;

    const filtersCount = selected.funnel_state_current.length
      + selected.funnel_state_past.length
      + (selected.resource_name && selected.resource_name.length ? 1 : 0);
    const buttonTextWithCount = filtersCount ? `Filter (${filtersCount})` : 'Filter';

    return (
      <div className="filter-container">
        <InputFilterContainer
          filterKey="resource_name"
          onItemClick={(item, key) => this.updateSelectedFilterListItem(item, key)}
          resourceName={selected.resource_name}
          placeholder="Search..."
        />

        <Button
          className="filter-button"
          outline
          onClick={this.toggleFilterModal}
        >
          <SVG src={filterIcon} />
          <span className="dropdown-text">
            { buttonTextWithCount }
          </span>
        </Button>
        <FilterModal
          showModal={showFilterModal}
          toggleModal={this.toggleFilterModal}
          onItemClick={(item, key) => this.updateSelectedFilterListItem(item, key)}
          selected={tempFilterStatus}
          filters={[filters]}
          handleSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

FilterContainer.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string })).isRequired,
  updateSelected: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    funnel_state_current: PropTypes.arrayOf(PropTypes.string),
    funnel_state_past: PropTypes.arrayOf(PropTypes.string),
    resource_name: PropTypes.string,
  }).isRequired,
  initializeFilters: PropTypes.func.isRequired,
};

export default FilterContainer;
