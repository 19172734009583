import React from 'react';
import PropTypes from 'prop-types';
import './ProjectStatusChanges.scss';

const ProjectStatusChanges = ({ statusChanges }) => (
  <div className="status-changes mt-4">
    <div className="header-action">
      <h4 className="title text-capitalize">History</h4>
    </div>
    <ul className="status-change-ul">
      { statusChanges.map(statusChange => (
        <li
          className="status-change-li-item"
        >
          <div className="status-change-li-item-data">
            <div className="text-muted status-change-li-item-meta">

              <span className="status-change-li-item-icon">
                <i className="fa fa-arrow-right text-muted" aria-hidden="true" />
              </span>
              <span>{statusChange.initiator}</span>
              <br />
              {statusChange.old_status}
              {' '}
              <span className="fa fa-angle-double-right" />
              {' '}
              {statusChange.new_status}
              <span className="float-right">{statusChange.created}</span>
            </div>

            <div className="status-change-li-item-text">
              <span>{statusChange.change_reason}</span>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

ProjectStatusChanges.propTypes = {
  statusChanges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ProjectStatusChanges;
