import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import cn from 'classnames';
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
} from 'reactstrap';
import NoteDisplay from '../NoteDisplay';
import ActionDropdown from '../common/ActionDropdown';
import { postRequest, getRequest } from '../../requestUtils';
import * as notesIcon from '../../../assets/images/notes.svg';
import * as ratingsIcon from '../../../assets/images/star.svg';
import * as statusUpdatesIcon from '../../../assets/images/refresh.svg';
import * as availabilityIcon from '../../../assets/images/clock.svg';
import * as checkIcon from '../../../assets/images/check.svg';
import * as cloraLoading from '../../../assets/images/DNA-2s-200px.svg';
import './NotesContainer.scss';

/* eslint-disable react/no-array-index-key */

const noteTypes = {
  note: notesIcon,
  ratings: ratingsIcon,
  'status-update': statusUpdatesIcon,
  availability: availabilityIcon,
};

const INITIAL_HEIGHT = 46;

class NotesContainer extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.textareaRef = React.createRef();
    this.containerHeight = React.createRef();
    this.containerHeight.current = INITIAL_HEIGHT;

    this.state = {
      notes: [
        ...props.notes,
        ...props.ratings,
        props.availability,
      ].filter(n => n),
      newNote: '',
      filteredNotes: [],
      dropdownOpen: false,
      dropdownProjects: props.dropdownProjects,
      isFilterApplied: false,
      isExpanded: false,
      isFetching: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNotesFilter = this.handleNotesFilter.bind(this);
    this.combinedFeedback = this.combinedFeedback.bind(this);
    this.handleProjectsFilter = this.handleProjectsFilter.bind(this);
    this.toggleDropdownOpen = this.toggleDropdownOpen.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);
    this.handleTextareaExpand = this.handleTextareaExpand.bind(this);
    this.handleTextareaClose = this.handleTextareaClose.bind(this);
    this.getStatusChangeHistory = this.getStatusChangeHistory.bind(this);
  }

  componentDidMount() {
    const { showFilters } = this.props;
    if (showFilters) {
      this.setState({ isFetching: true });
      this.getStatusChangeHistory()
        .then((res) => {
          const { data } = res.data;
          this.setState(prev => ({ notes: [...prev.notes, ...data] }));
        }).catch((e) => {}); // eslint-disable-line no-unused-vars

      this.setState({ isFetching: false });
    }
  }

  getStatusChangeHistory() {
    const { consultantCloraResourceId, userEmail } = this.props;

    const params = {
      route: 'api/v1/consultants/status_change_history',
      args: {
        consultant_resource_id: consultantCloraResourceId,
        tracked_object_type: 'proposal',
        user_email: userEmail,
      },
    };

    return getRequest('/marketplace_api_proxy', params);
  }

  combinedFeedback() {
    const { notes } = this.state;
    return notes.sort((a, b) => (
      Date.parse(a.created) < Date.parse(b.created) ? 1 : -1
    ));
  }

  handleNotesFilter(key) {
    const { notes } = this.state;
    const newNotes = notes.filter(n => n.type === key).sort((a, b) => (
      Date.parse(a.created) < Date.parse(b.created) ? 1 : -1
    ));

    this.setState({
      filteredNotes: newNotes,
      isFilterApplied: true,
    });
  }

  handleProjectsFilter(projectId) {
    const { notes } = this.state;
    const newNotes = notes.filter(n => n.project_id === projectId).sort((a, b) => (
      Date.parse(a.created) < Date.parse(b.created) ? 1 : -1
    ));

    this.setState({
      filteredNotes: newNotes,
      isFilterApplied: true,
    });
  }

  handleClearFilters() {
    this.setState({
      filteredNotes: [],
      isFilterApplied: false,
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const { value, name } = target;

    if (this.textareaRef) {
      this.textareaRef.current.style.height = 'auto';
      this.textareaRef.current.style.height = `${this.textareaRef.current.scrollHeight}px`;
    }

    this.setState({
      [name]: value,
    });
  }

  handleSubmit() {
    const { newNote, notes } = this.state;
    const { submitPath, csrfToken, afterChange } = this.props;
    const params = {
      note: newNote,
    };

    return postRequest(submitPath, params, csrfToken)
      .then((response) => {
        const resNotes = [...notes, response.data];
        this.setState({ notes: resNotes, newNote: '' });
        this.handleTextareaClose();
        afterChange(resNotes);
      });
  }

  handleTextareaExpand() {
    const { isExpanded } = this.state;
    if (!isExpanded) {
      this.containerHeight.current = this.containerRef.current.scrollHeight;
      this.setState({ isExpanded: true });
    }
  }

  handleTextareaClose() {
    this.setState({ isExpanded: false, newNote: '' });
  }

  toggleDropdownOpen() {
    this.setState(prev => ({ dropdownOpen: !prev.dropdownOpen }));
  }

  render() {
    const { header, showFilters } = this.props;
    const {
      filteredNotes,
      dropdownOpen,
      dropdownProjects,
      isFilterApplied,
      isExpanded,
      newNote,
      isFetching,
    } = this.state;

    const feedback = isFilterApplied ? filteredNotes : this.combinedFeedback();
    const noteLinks = [
      ['function', 'Notes', () => { this.handleNotesFilter('note'); }, noteTypes.note, 'note'],
      ['function', 'Ratings', () => { this.handleNotesFilter('ratings'); }, noteTypes.ratings, 'ratings'],
      ['function', 'Status updates', () => { this.handleNotesFilter('status-update'); }, noteTypes['status-update'], 'status-update'],
      ['function', 'Availability updates', () => { this.handleNotesFilter('availability'); }, noteTypes.availability, 'availability'],
    ];

    return (
      <div className="notes">
        {isFetching
          ? (
            <div className="flex-grid center-horizontal">
              <SVG src={cloraLoading} />
            </div>
          )
          : (
            <Fragment>
              <div className="header">
                { header && <h4 className="title text-capitalize">{header}</h4> }
                <div>
                  <div
                    ref={this.containerRef}
                    className={cn('note-form-container mt-3 mb-3', {
                      expanded: isExpanded,
                      collapsed: !isExpanded,
                      modified: newNote.length > 0,
                    })}
                    style={{
                      minHeight: isExpanded ? this.containerHeight.current : INITIAL_HEIGHT,
                    }}
                  >
                    <textarea
                      ref={this.textareaRef}
                      onClick={this.handleTextareaExpand}
                      onFocus={this.handleTextareaExpand}
                      className="new-note-textarea mb-3"
                      placeholder="Enter new note here"
                      name="newNote"
                      value={newNote}
                      onChange={this.handleInputChange}
                    />
                    <div className="action-buttons">
                      <Button
                        outline
                        size="sm"
                        onClick={this.handleTextareaClose}
                        className="new-note-button mr-0 cancel"
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        className="mr-2 save-note"
                        onClick={this.handleSubmit}
                      >
                        <SVG src={checkIcon} />
                        {' '}
                        Save note
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              { showFilters
              && (
              <div className="d-flex align-items-center">
                {dropdownProjects.length > 0
                  && (
                  <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdownOpen} direction="down">
                    <DropdownToggle caret className="dropdown-toogle">All projects</DropdownToggle>
                    <DropdownMenu className="project-list-dropdown">
                      {dropdownProjects.map(proj => (
                        <Fragment>
                          <DropdownItem onClick={() => this.handleProjectsFilter(proj.id)}>
                            <Col xs={11} className="pl-2 gap-3">
                              <div className="project-company">
                                {proj.company_name}
                              </div>
                              <div className="project-name">{proj.name}</div>
                              <div className="d-flex justify-content-between">
                                <div className={proj.status}>
                                  {proj.status.split('_').join(' ')}
                                </div>
                                <div className="project-created-time">{proj.created}</div>
                              </div>
                            </Col>
                          </DropdownItem>
                          <DropdownItem divider />
                        </Fragment>
                      ))

                      }
                    </DropdownMenu>
                  </Dropdown>
                  )
                }
                <ActionDropdown
                  defaultLabel="All events"
                  links={noteLinks}
                />
                {isFilterApplied
                  && (
                  <button
                    type="button"
                    className="clear-filter"
                    onClick={this.handleClearFilters}
                  >
                    Clear filter
                  </button>
                  )
                }
              </div>
              )
            }
              <div className="notes-container">
                {feedback.filter(n => n).map(note => (
                  <NoteDisplay
                    note={note}
                    noteTypes={noteTypes}
                  />
                ))}
              </div>
            </Fragment>
          )
        }
      </div>
    );
  }
}

NotesContainer.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  ratings: PropTypes.arrayOf(PropTypes.shape({})),
  csrfToken: PropTypes.string.isRequired,
  submitPath: PropTypes.string.isRequired,
  header: PropTypes.string,
  afterChange: PropTypes.func,
  availability: PropTypes.shape({}),
  dropdownProjects: PropTypes.shape([]),
  showFilters: PropTypes.bool,
  consultantCloraResourceId: PropTypes.string,
  userEmail: PropTypes.string,
};

NotesContainer.defaultProps = {
  header: 'Project Notes',
  afterChange: () => {},
  ratings: [],
  dropdownProjects: [],
  availability: null,
  showFilters: false,
  consultantCloraResourceId: '',
  userEmail: '',
};

export default NotesContainer;
