import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TagDisplay from '../TagDisplay';
import { postRequest, deleteRequest } from '../../requestUtils';
import './ProjectTagsContainer.scss';

class ProjectTagsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: props.tags,
      addTag: false,
      newTags: '',
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.toggleAddTag = this.toggleAddTag.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { value, name } = target;
    this.setState({
      [name]: value,
    });
  }

  handleKeyPress(event) {
    if (event.keyCode === 13) {
      this.handleAddition();
    }
  }

  handleDelete(event, name, i) {
    const { tags } = this.state;
    const { projectId, csrfToken } = this.props;
    const params = {
      tag_id: tags[i].id,
    };

    deleteRequest(
      `/projects/${projectId}/remove_tag`,
      params,
      csrfToken,
    ).then((response) => {
      this.setState({ tags: response.data });
    });
  }

  handleAddition() {
    const { newTags } = this.state;
    const { csrfToken, projectId } = this.props;
    const params = { tags: newTags };
    postRequest(`/projects/${projectId}/add_tags`, params, csrfToken)
      .then((response) => {
        this.setState({ tags: response.data, newTags: '', addTag: false });
      });
  }

  toggleAddTag() {
    const { addTag } = this.state;
    this.setState({ addTag: !addTag });
  }

  renderInput() {
    const { addTag } = this.state;

    if (addTag === true) {
      return (
        <input
          type="text"
          placeholder="Add Tags Here (separated by commas)"
          name="newTags"
          className="form-control"
          onChange={this.handleInputChange}
          onKeyDown={this.handleKeyPress}
        />
      );
    }

    return null;
  }

  render() {
    const { tags } = this.state;
    return (
      <div className="project-tags">
        <TagDisplay
          title="Keywords"
          tags={tags}
          dynamic
          toggleAddTag={this.toggleAddTag}
          handleRemoveTag={this.handleDelete}
          badgeClasses="badge badge-success project-badge"
        />
        {this.renderInput()}
      </div>
    );
  }
}

ProjectTagsContainer.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  csrfToken: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
};

ProjectTagsContainer.defaultProps = {
};

export default ProjectTagsContainer;
