import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col,
} from 'reactstrap';
import InputFilterContainer from '../InputFilterContainer';
import { postRequest } from '../../../requestUtils';
import './SourceAlternateProjectModal.scss';
import checkmark from '../../../../assets/images/check.svg';
import { analyticsTrack } from '../../../analyticsUtils';

const projectStatus = {
  employer_requested_matching: 'Matching requested',
  employer_draft: 'Draft',
  clora_matching: 'Matching',
  clora_matched: 'Matched',
};

const trackAddToProject = (clickPosition, projectId, consultantId, source) => {
  analyticsTrack('Add to Project Clicked', {
    clickPosition,
    projectId,
    consultantId,
    source,
  });
};

class SourceAlternateProjectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sourcingProjectsFilter: '',
      filteredSourcingProjects: [],
      selectedSourcingProjects: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSourcingProjectFilter = this.handleSourcingProjectFilter.bind(this);
    this.handleSelectProjectSourcing = this.handleSelectProjectSourcing.bind(this);
  }

  handleSelectProjectSourcing(e, id) {
    e.stopPropagation();

    const { selectedSourcingProjects } = this.state;

    let result;
    if (selectedSourcingProjects.includes(id)) {
      result = [...selectedSourcingProjects.filter(projectId => projectId !== id)];
    } else {
      result = [...selectedSourcingProjects, id];
    }

    this.setState({ selectedSourcingProjects: result });
  }

  handleSubmit() {
    const {
      csrfToken,
      handleSetBulkActionMessage,
      toggleModal,
      selectedConsultantName,
      sourcingProjects,
      consultantIds,
      resultPosition,
    } = this.props;

    const { selectedSourcingProjects } = this.state;

    if (selectedSourcingProjects.length === 0) return null;

    const bulkMessageSubject = consultantIds.length > 1 ? `${consultantIds.length} consultants` : `${selectedConsultantName} has been`;
    const projectTarget = selectedSourcingProjects.length > 1 ? `${selectedSourcingProjects.length} projects.` : `${sourcingProjects.find(proj => proj.id === selectedSourcingProjects[0]).name}.`;

    return postRequest(
      '/projects/bulk_add_consultants_projects',
      { consultant_ids: consultantIds, project_ids: selectedSourcingProjects },
      csrfToken,
    ).then(() => {
      toggleModal();
      this.setState({ selectedSourcingProjects: [] });
      handleSetBulkActionMessage(`${bulkMessageSubject} added to ${projectTarget}`, 'notify');
      trackAddToProject(resultPosition, selectedSourcingProjects, consultantIds, 'consultant search');
    }).catch(() => {
      toggleModal();
      handleSetBulkActionMessage('Error: Consultants failed to source to project.', 'error');
    });
  }

  handleSourcingProjectFilter(item) {
    const { sourcingProjects } = this.props;
    const filteredProject = sourcingProjects
      .filter(proj => proj.company_name.toLowerCase().includes(item)
      || proj.name.toLowerCase().includes(item));
    this.setState({
      sourcingProjectsFilter: item,
      filteredSourcingProjects: filteredProject,
    });
  }

  renderProjectSourcing() {
    const { sourcingProjects } = this.props;
    const { selectedSourcingProjects, filteredSourcingProjects } = this.state;
    const isSelected = id => selectedSourcingProjects.includes(id);
    const projects = filteredSourcingProjects.length > 0
      ? filteredSourcingProjects : sourcingProjects;

    return (
      <div className="project-list-container">
        { projects.map(proj => (
          <Row
            className={`p-2 project-item ${isSelected(proj.id) ? 'selected' : null}`}
            key={proj.id}
            onClick={e => this.handleSelectProjectSourcing(e, proj.id)}
          >
            <Col xs={1} className="pr-1">
              <label htmlFor="select-checkbox" className="select-label">
                { isSelected(proj.id) ? <SVG className="select-check" src={checkmark} /> : null }
                <input
                  id="select-checkbox"
                  type="button"
                  className={`btn ${isSelected(proj.id) ? 'selected-page' : 'unselected-page'}`}
                />
              </label>
            </Col>
            <Col xs={11} className="pl-2 gap-3">
              <div className="project-company">
                {proj.company_name}
              </div>
              <div className="project-name">{proj.name}</div>
              <div className="d-flex justify-content-between">
                <div className={projectStatus[proj.status].split(' ').join('-').toLowerCase()}>
                  {projectStatus[proj.status]}
                </div>
                <div className="project-created-time">{proj.created}</div>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    );
  }

  render() {
    const { showModal, toggleModal } = this.props;
    const { sourcingProjectsFilter, selectedSourcingProjects } = this.state;

    const disableSubmitButton = selectedSourcingProjects.length < 1;

    return (
      <Modal isOpen={showModal} toggle={toggleModal} className="source-alternate-project-modal" size="lg">
        <ModalHeader toggle={toggleModal}>
          Add to another Project
        </ModalHeader>
        <ModalBody>
          <Col>
            <div className="sub-header">Filter</div>
            <InputFilterContainer
              filterKey="name"
              onItemClick={(item, key) => this.handleSourcingProjectFilter(item.toLowerCase(), key)}
              resourceName={sourcingProjectsFilter}
              placeholder="Search by company name or project name"
            />
            {this.renderProjectSourcing()}
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel-button" onClick={toggleModal}>Cancel</Button>
          <Button
            className={`${disableSubmitButton ? 'submit-button-disabled' : 'submit-button'}`}
            onClick={this.handleSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

SourceAlternateProjectModal.propTypes = {
  selectedConsultantName: PropTypes.string.isRequired,
  consultantIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  toggleModal: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleSetBulkActionMessage: PropTypes.func,
  sourcingProjects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  resultPosition: PropTypes.number,
};

SourceAlternateProjectModal.defaultProps = {
  handleSetBulkActionMessage: () => {},
  resultPosition: 0,
};

export default SourceAlternateProjectModal;
