import React from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash.groupby';
import GenericSearchContainer from '../common/GenericSearchContainer';
import './ResumesContainer.scss';

const consultantId = item => (
  item.consultant_id
);

const renderResumes = (results) => {
  const groupedResumes = Object.values(groupBy(results, consultantId));
  const consultantCount = groupedResumes.length;
  const resumeCount = results.length;
  return (
    <div className="max-width">
      <h3>{`Found ${resumeCount} matching resumes to ${consultantCount} consultants`}</h3>
      { groupedResumes.map(resumes => (
        <div className="card resume-card">
          <h2 className="bold mb-1">
            <a href={`/consultants/${resumes[0].consultant_id}`}>
              {resumes[0].consultant_name}
            </a>
          </h2>
          { resumes.map(resume => (
            <div>
              <div className="resume-content">
                <a href={`/groupedResumes/${resume.resource_id}`}>{resume.resume_file_name}</a>
                <div className="content">{resume.text}</div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const ResumesContainer = ({ filters, searchLink, csrfToken }) => (
  <GenericSearchContainer
    header="Resume Search"
    filters={filters}
    searchLink={searchLink}
    csrfToken={csrfToken}
    renderResults={renderResumes}
  />
);

ResumesContainer.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchLink: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default ResumesContainer;
