import React from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash.groupby';
import GenericSearchContainer from '../common/GenericSearchContainer';
import './NotesSearchContainer.scss';

const groupNoteable = item => (
  item.noteable_path
);

const renderNotes = (results) => {
  const groupedNotes = Object.values(groupBy(results, groupNoteable));
  const noteCount = results.length;
  const noteableCount = groupedNotes.length;
  return (
    <div className="max-width">
      <h3>{`Found ${noteCount} matching notes to ${noteableCount} noteable Objects`}</h3>
      { groupedNotes.map(notes => (
        <div className="card noteable-card">
          <h2 className="bold mb-1">
            <a href={notes[0].noteable_path}>
              {`${notes[0].noteable_name} (${notes[0].noteable_type})`}
            </a>
          </h2>
          <div className="note-content">
            { notes.map(note => (
              <div>
                <div className="content">{note.data}</div>
                <div className="author">
                  {`Created on ${note.created} by ${note.author}`}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const NotesSearchContainer = ({ filters, searchLink, csrfToken }) => (
  <GenericSearchContainer
    header="Note Search"
    filters={filters}
    searchLink={searchLink}
    csrfToken={csrfToken}
    renderResults={renderNotes}
  />
);

NotesSearchContainer.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchLink: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
};

NotesSearchContainer.defaultProps = {
};

export default NotesSearchContainer;
