/* global jQuery */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';

class SimilarConsultantModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consultants: [],
      isConsultant: true,
      locality: '',
      hasCloraProfile: '',
      showModal: false,
      isLoading: false,
      showTermWeights: false,
      termWeights: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleMatch = this.handleMatch.bind(this);
    this.showTermWeights = this.showTermWeights.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value,
    });
  }

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  handleMatch(event) {
    event.preventDefault();
    const { consultantId } = this.props;
    const {
      locality, hasCloraProfile, isConsultant, showTermWeights, termWeights,
    } = this.state;

    this.setState({ isLoading: true, isError: false });
    const params = {
      consultant_id: consultantId,
      filters: {
        locality,
        has_clora_profile: hasCloraProfile,
        is_consultant: isConsultant,
        dnu: false,
      },
    };

    if (showTermWeights) {
      params.term_weights = termWeights;
    }

    axios({
      url: '/search/match',
      params,
      /* eslint-disable-next-line no-shadow */
      paramsSerializer(params) { return jQuery.param(params); },
    })
      .then((response) => {
        this.setState({
          consultants: response.data.candidates,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isError: true, isLoading: false });
      });
  }

  showTermWeights() {
    const { consultantId } = this.props;
    axios({
      url: `/consultants/${consultantId}/term_weights`,
    })
      .then((response) => {
        this.setState({
          showTermWeights: true,
          termWeights: response.data.term_weights,
        });
      });
  }

  renderTermWeights() {
    const { showTermWeights, termWeights } = this.state;
    if (showTermWeights) {
      return (
        <Input type="textarea" value={termWeights} name="termWeights" onChange={this.handleInputChange} rows="6" />
      );
    }

    return null;
  }

  renderConsultants() {
    const {
      consultants, isLoading, isError,
    } = this.state;
    if (isLoading === true) {
      return <div><h1>Loading...</h1></div>;
    }

    if (isError === true) {
      return <div><h1>Sorry, something went wrong...</h1></div>;
    }

    return (
      <ul>
        { consultants.map(c => (
          <li key={`consultant-${c.id}`}>
            <a href={`/consultants/${c.id}`} target="_blank" rel="noopener noreferrer">
              {c.given_name}
              {' '}
              {c.family_name}
              :
              {' '}
              {c.similarity_score}
            </a>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const {
      showModal, locality, isConsultant, hasCloraProfile,
    } = this.state;
    return (
      <ButtonGroup>
        <Button color="secondary" onClick={this.toggleModal}>
          Find Similar
        </Button>

        <Modal isOpen={showModal} toggle={this.toggleModal} size="lg">
          <ModalHeader toggle={this.toggleModal}>
            <div className="container-fluid">
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="con-sim-locality">Locality</Label>
                    <Input id="con-sim-locality" value={locality} name="locality" onChange={this.handleInputChange} />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label check>
                      <Input type="checkbox" checked={isConsultant} name="isConsultant" id="con-sim-is-consultant" onChange={this.handleInputChange} />
                      {' '}
                      Consultant Only
                    </Label>
                    <Label check>
                      <Input type="checkbox" checked={hasCloraProfile} name="hasCloraProfile" id="con-sim-is-clora" onChange={this.handleInputChange} />
                      {' '}
                      Has Clora Account
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <Button color="success" onClick={this.handleMatch}>Find</Button>
                  <Button color="secondary" onClick={this.showTermWeights}>Edit Term Weights</Button>
                </Col>
              </Row>
              <Row>
                {this.renderTermWeights()}
              </Row>
            </div>
          </ModalHeader>
          <ModalBody>
            {this.renderConsultants()}
          </ModalBody>
        </Modal>
      </ButtonGroup>
    );
  }
}

SimilarConsultantModal.propTypes = {
  consultantId: PropTypes.number.isRequired,
};

export default SimilarConsultantModal;
