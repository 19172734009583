import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import SVG from 'react-inlinesvg';
import ConsultantProjectStatus from '../ConsultantProjectStatus';
import ConsultantProjectStateModal from '../common/ConsultantProjectStateModal';
import FilterContainer from '../common/FilterContainer';
import ActionDropdown from '../common/ActionDropdown';
import MatchSourceModal from '../common/MatchSourceModal';
import consumer from '../../channels/consumer';
import { getRequest, postRequest, deleteRequest } from '../../requestUtils';
import './ConsultantProjectContainer.scss';
import SourceAlternateProjectModal from '../common/SourceAlternateProjectModal';
import ToastMessage from '../common/ToastMessage';
import minus from '../../../assets/images/minus.svg';
import checkmark from '../../../assets/images/check.svg';
import sortAsc from '../../../assets/images/sort-asc.svg';
import sortDesc from '../../../assets/images/sort-desc.svg';
import sortOutline from '../../../assets/images/sort-outline.svg';
import download from '../../../assets/images/download.svg';
import downloadGreen from '../../../assets/images/download-green.svg';
import cornerUpRight from '../../../assets/images/corner-up-right.svg';
import trash2 from '../../../assets/images/trash-2.svg';
import sourceOrigin from '../../../assets/images/sourceOrigin.svg';
import source from '../../../assets/images/Source.svg';

const renderSortArrow = (order = 'outline') => {
  switch (order) {
    case 'asc':
      return <SVG src={sortAsc} className="sort-arrow asc" />;
    case 'desc':
      return <SVG src={sortDesc} className="sort-arrow desc" />;
    default:
      return <SVG src={sortOutline} className="sort-arrow outline" />;
  }
};

class ConsultantProjectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statuses: null,
      consultantIds: props.consultantIds,
      selectedProjectConsultants: [],
      showConsultantStateModal: false,
      showSourceAlternateModal: false,
      showMatchSourceModal: false,
      bulkActionMessage: '',
      bulkMessageType: null,
      bulkMessageSubtext: '',
      sortCategory: 'funnel_state',
      order: 'desc',
      selectedFilters: { funnel_state_current: [], funnel_state_past: [], resource_name: '' },
    };

    this.getStatuses = this.getStatuses.bind(this);
    this.initializeFilters = this.initializeFilters.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleSelectConsultant = this.handleSelectConsultant.bind(this);
    this.handleDeselectConsultant = this.handleDeselectConsultant.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleDeselectAll = this.handleDeselectAll.bind(this);
    this.handleUpdateConsultantStates = this.handleUpdateConsultantStates.bind(this);
    this.toggleConsultantStateModal = this.toggleConsultantStateModal.bind(this);
    this.toggleSourceAlternateModal = this.toggleSourceAlternateModal.bind(this);
    this.toggleMatchSourceModal = this.toggleMatchSourceModal.bind(this);
    this.handleBulkExport = this.handleBulkExport.bind(this);
    this.handleBulkUnsource = this.handleBulkUnsource.bind(this);
    this.handleBulkFindEmail = this.handleBulkFindEmail.bind(this);
    this.handleBulkEmailsResponse = this.handleBulkEmailsResponse.bind(this);
    this.handleUpdateFilters = this.handleUpdateFilters.bind(this);
    this.handleUnsource = this.handleUnsource.bind(this);
    this.handleSetBulkActionMessage = this.handleSetBulkActionMessage.bind(this);
  }

  componentDidMount() {
    this.getStatuses({});
  }

  getStatuses() {
    const { context, contextId, formUrl } = this.props;
    const { sortCategory, order, selectedFilters } = this.state;
    const params = {
      context,
      context_id: contextId,
      sort_category: sortCategory,
      order,
      ...selectedFilters,
    };
    return getRequest(formUrl, params).then((response) => {
      this.setState({ statuses: response.data });
    });
  }

  selectedResourceName() {
    const { sourceAlternateConsultant, statuses, selectedProjectConsultants } = this.state;

    const selectedConsultant = (
      sourceAlternateConsultant && statuses.find(st => st.id === sourceAlternateConsultant[0])
    ) || (
      selectedProjectConsultants.length
        && statuses.find(st => st.id === selectedProjectConsultants[0])
    );
    return selectedConsultant ? selectedConsultant.resource_name : '';
  }

  toggleConsultantStateModal() {
    const { showConsultantStateModal } = this.state;
    this.setState({ showConsultantStateModal: !showConsultantStateModal });
  }

  toggleSourceAlternateModal(consultantProjectId) {
    const { showSourceAlternateModal, selectedProjectConsultants } = this.state;
    this.setState({
      showSourceAlternateModal: !showSourceAlternateModal,
      sourceAlternateConsultant: (typeof (consultantProjectId) === 'number' ? [consultantProjectId] : selectedProjectConsultants),
    });
  }

  toggleMatchSourceModal() {
    const { showMatchSourceModal } = this.state;
    this.setState({ showMatchSourceModal: !showMatchSourceModal });
  }

  handleUpdateFilters(filters) {
    this.setState({ selectedFilters: filters }, this.getStatuses);
  }

  initializeFilters() {
    const { filters } = this.props;
    const { selectedFilters } = this.state;
    filters.forEach((filter) => {
      selectedFilters[filter.key] = (filter.type === 'text' ? '' : []);
    });
    this.handleUpdateFilters(selectedFilters);
    return selectedFilters;
  }

  handleUpdateConsultantStates(updatedConsultantsProjects, updateField = 'Status', success = true) {
    const { context } = this.props;
    const { statuses } = this.state;

    let updatedStatus;
    let newStatuses;
    let message;
    if (success) {
      newStatuses = [...statuses].map((status) => {
        updatedStatus = updatedConsultantsProjects.find(sc => sc.id === status.id);

        if (updatedStatus) {
          updatedStatus.updated = true;
        } else {
          updatedStatus = { ...status };
          updatedStatus.updated = false;
        }
        return updatedStatus;
      });
      const targetDescription = updatedConsultantsProjects.length > 1
        ? `${updatedConsultantsProjects.length} ${context === 'Consultant' ? 'projects' : 'consultants'}`
        : `${updatedConsultantsProjects[0].resource_name}`;

      message = `${updateField} has been updated for ${targetDescription}.`;
    } else {
      newStatuses = statuses;
      message = `${updateField} failed to update.`;
    }

    this.setState({
      statuses: newStatuses,
      bulkActionMessage: message,
      bulkMessageType: success ? 'notify' : 'error',
      selectedProjectConsultants: [],
    });
  }

  handleDeselectConsultant(consultantId) {
    const { selectedProjectConsultants } = this.state;

    const copy = [...selectedProjectConsultants];
    const index = copy.indexOf(consultantId);
    copy.splice(index, 1);
    this.setState({ selectedProjectConsultants: copy });
  }

  handleSelectConsultant(consultantId) {
    const { selectedProjectConsultants } = this.state;

    this.setState({ selectedProjectConsultants: [...selectedProjectConsultants, consultantId] });
  }

  handleSelectAll() {
    const { statuses } = this.state;

    this.setState({ selectedProjectConsultants: statuses.map(s => s.id) });
  }

  handleDeselectAll() {
    this.setState({ selectedProjectConsultants: [] });
  }

  handleBulkExport() {
    const { statuses, selectedProjectConsultants } = this.state;
    const ids = statuses.filter(
      st => selectedProjectConsultants.includes(st.id),
    ).map(st => st.consultant_id);
    const params = {
      selected_ids: ids,
      export_all: 'true',
    };
    axios({
      url: '/search/bulk_export',
      params,
    }).then(() => {
      this.handleSetBulkActionMessage(`${ids.length} consultants exported.`);
    }).catch(() => {
      this.handleSetBulkActionMessage('Consultants failed to export.', 'error');
    });
  }

  handleBulkUnsource() {
    const { context, resourceName } = this.props;
    const { selectedProjectConsultants } = this.state;
    const params = { selected_ids: selectedProjectConsultants };
    axios({
      url: '/search/bulk_unsource',
      params,
    }).then(() => {
      if (context === 'Marketplace::Project') {
        this.handleSetBulkActionMessage(selectedProjectConsultants.length > 1
          ? `${selectedProjectConsultants.length} consultants removed from ${resourceName}.`
          : `${this.selectedResourceName()} has been removed from ${resourceName}.`);
      } else {
        this.handleSetBulkActionMessage(selectedProjectConsultants.length > 1
          ? `${resourceName} has been removed from ${selectedProjectConsultants.length} projects.`
          : `${resourceName} has been removed from ${this.selectedResourceName()}.`);
      }
      this.getStatuses({});
    }).catch(() => {
      this.handleSetBulkActionMessage('Error: Matches failed to un-source', 'error');
    });
  }

  handleUnsource(consultantId, selectedResourceName) {
    const { csrfToken, context, resourceName } = this.props;

    return () => {
      deleteRequest(`/consultants_projects/${consultantId}`, {}, csrfToken).then(() => {
        if (context === 'Marketplace::Project') {
          this.handleSetBulkActionMessage(`${selectedResourceName} has been removed from ${resourceName}.`);
        } else {
          this.handleSetBulkActionMessage(`${resourceName} has been removed from ${selectedResourceName}.`);
        }
        this.getStatuses({});
      }).catch(() => {
        this.handleSetBulkActionMessage('Error: Matches failed to un-source', 'error');
      });
    };
  }

  handleBulkFindEmail() {
    const { userId } = this.props;
    const { selectedProjectConsultants } = this.state;
    const params = {
      selected_consultant_project_ids: selectedProjectConsultants.slice(0, 500),
      user_id: userId,
    };

    if (selectedProjectConsultants.length > 500) {
      this.handleSetBulkActionMessage(
        'Only fetching emails for first 500 consultants. Try narrowing your search.', 'warning',
      );
    }

    this.handleSetBulkActionMessage(`Searching for ${selectedProjectConsultants.length} emails...`);
    postRequest('/rocketreach/bulk_find_email', params).then(() => {
      this.subscribeToBulkEmailChannel();
    }).catch((resp) => {
      if (resp.match('400')) {
        this.handleSetBulkActionMessage('All selected candidates have an email set.', 'warning');
      } else {
        this.handleSetBulkActionMessage(
          'There was an error retrieving emails, wait 5 minutes and try again.', 'error',
        );
      }
      this.handleBulkExport();
    });
  }

  subscribeToBulkEmailChannel() {
    const { userId } = this.props;

    consumer.subscriptions.create({
      channel: 'BulkEmailChannel',
      user_id: userId,
    }, {
      received: this.handleBulkEmailsResponse,
    });
  }

  handleBulkEmailsResponse(response) {
    const { selectedProjectConsultants } = this.state;

    const emails = response.body;
    const foundCount = emails.reduce((total, status) => total + (status.success ? 1 : 0), 0);
    const inverseCount = selectedProjectConsultants.length - foundCount;

    if (foundCount === selectedProjectConsultants.length) {
      this.handleSetBulkActionMessage(
        `Found ${foundCount} out of ${selectedProjectConsultants.length} emails.`,
      );
    } else if (inverseCount === 1) {
      this.handleSetBulkActionMessage(`There is no email address available for
        ${this.selectedResourceName()}`, 'error');
    } else {
      this.handleSetBulkActionMessage(
        `${inverseCount} of ${selectedProjectConsultants.length} exported
          consultants had no email address available.`,
        'warning',
        'See the downloaded file for details.',
      );
    }
    this.handleBulkExport();
  }

  handleSetBulkActionMessage(bulkActionMessage, type, subtext) {
    this.setState({ bulkActionMessage, bulkMessageType: type, bulkMessageSubtext: subtext });
  }

  handleSort(sortColumn) {
    const { sortCategory, order } = this.state;

    if (!sortColumn) return;
    if (sortColumn === sortCategory && order === 'asc') {
      this.setState({ order: 'desc' }, this.getStatuses);
    } else {
      this.setState({ sortCategory: sortColumn, order: 'asc' }, this.getStatuses);
    }
  }

  renderStatuses() {
    const {
      csrfToken, marketplaceHost, baseLink, context, funnelStates, userEmail,
    } = this.props;
    const { statuses, selectedProjectConsultants } = this.state;
    return (
      <div className="project-consultants-body">
        {statuses.map(status => (
          <ConsultantProjectStatus
            key={`${status.id}-${status.updated_at}`}
            id={status.id}
            email={status.email}
            currentState={status.funnel_state}
            matchSource={status.match_source}
            resourceLink={status.resource_link}
            resourceName={status.resource_name}
            shareUrl={status.share_url}
            statusChanges={status.status_changes}
            proposalRate={status.proposal_rate}
            proposalResourceId={status.proposal_resource_id}
            company={status.project_company}
            latestStatusChangeAt={status.latest_status_change_at}
            notes={status.notes}
            addNotePath={status.add_note_path}
            ratings={status.marketplace_ratings}
            ratingCategories={status.rating_categories}
            averageRating={status.average_rating}
            canReject={status.can_reject}
            csrfToken={csrfToken}
            marketplaceHost={marketplaceHost}
            selectConsultant={this.handleSelectConsultant}
            deselectConsultant={this.handleDeselectConsultant}
            isSelected={selectedProjectConsultants.includes(status.id)}
            baseLink={baseLink}
            projectId={status.project_id}
            projectName={status.project_name}
            context={context}
            unsourceConsultant={this.handleUnsource(status.id, status.resource_name)}
            updated={status.updated}
            funnelStates={funnelStates}
            handleUpdateConsultantStates={this.handleUpdateConsultantStates}
            toggleSourceAlternateModal={() => this.toggleSourceAlternateModal(status.id)}
            consultantCloraResourceId={status.consultant_clora_resource_id}
            userEmail={userEmail}
          />
        ))}
      </div>
    );
  }

  renderSelectCount() {
    const { selectedProjectConsultants, statuses } = this.state;
    const { totalCount } = this.props;
    return (
      <Row className="status-counts">
        <Col xs={8} className="status-count">
          Viewing
          {' '}
          <span className="number">{statuses.length}</span>
          {' '}
          of
          {' '}
          <span className="number">{totalCount}</span>
          {' '}
          associated candidates
        </Col>
        <Col xs={4} className="selected-count">
          {`${selectedProjectConsultants.length} selected`}
        </Col>
      </Row>
    );
  }

  renderSelectAll() {
    const { selectedProjectConsultants } = this.state;
    const { totalCount } = this.props;

    let selectMethod;
    let selectMark;
    let checkboxSelect;
    if (selectedProjectConsultants.length > 0) {
      selectMethod = this.handleDeselectAll;
      checkboxSelect = 'selected-page';

      selectMark = (selectedProjectConsultants.length === totalCount)
        ? <SVG className="select-check" src={checkmark} />
        : <SVG className="select-minus" src={minus} />;
    } else {
      selectMethod = this.handleSelectAll;
      selectMark = null;
      checkboxSelect = 'unselected-page';
    }

    return (
      <label htmlFor="select-checkbox" className="select-label">
        {selectMark || ''}
        <input id="select-checkbox" type="button" onClick={selectMethod} className={`btn ${checkboxSelect}`} />
      </label>
    );
  }

  renderTableHeader() {
    const { context } = this.props;

    return (
      <Row className="consultant-project consultant-project-header">
        {this.renderColumnHeader(null, this.renderSelectAll(), 1, 'checkbox-column')}
        {this.renderColumnHeader('funnel_state', 'Status', 1, 'status-column')}
        {this.renderColumnHeader(
          (context === 'Marketplace::Project' ? 'consultants.family_name' : 'project_name'),
          (context === 'Marketplace::Project' ? 'Project Name' : 'Candidate Name'),
          3,
        )}
        {this.renderColumnHeader('hourly_rate', 'Proposed Take Home Rate', 1)}
        {this.renderColumnHeader('match_source', 'Origin', 2)}
        {this.renderColumnHeader('last_status_change', 'Last Updated', 2)}
        {this.renderColumnHeader(null, 'Actions', 1)}
      </Row>
    );
  }

  renderColumnHeader(sortColumn, label, width, className) {
    const { sortCategory, order } = this.state;
    const sorted = sortColumn && sortColumn === sortCategory;

    return (
      <Col className={`column-header ${sortColumn} ${sorted ? 'sorted' : ''} ${className}`} onClick={() => this.handleSort(sortColumn)} xs={width}>
        {label}
        {' '}
        {sortColumn && renderSortArrow(sorted && order)}
      </Col>
    );
  }

  render() {
    const {
      filters, funnelStates, csrfToken, otherProjects, context,
      sourcingProjects,
    } = this.props;
    const {
      statuses,
      selectedProjectConsultants,
      showConsultantStateModal,
      showSourceAlternateModal,
      showMatchSourceModal,
      bulkActionMessage,
      bulkMessageType,
      bulkMessageSubtext,
      selectedFilters,
      consultantIds,
    } = this.state;
    const bulkActionLabel = (
      <React.Fragment>
        <SVG src={downloadGreen} className="link-icon" />
        {' '}
        <span className="dropdown-text">Export for Outreach</span>
      </React.Fragment>
    );

    if (statuses === null) {
      return <h1>Loading...</h1>;
    }

    return (
      <div className="consultant-project-container">
        <div className="mb-3 mt-3 flex-grid project-bulk-actions">
          <div className="flex-col col-lg-4 col-sm-6 filter-container">
            <FilterContainer
              filters={filters}
              updateSelected={this.handleUpdateFilters}
              initializeFilters={this.initializeFilters}
              selected={selectedFilters}
            />
          </div>
          <div className="flex-col col-lg-6 col-sm-6">
            {this.renderSelectCount()}
          </div>
          <div className="flex-col col-lg-2 col-sm-2">
            <ActionDropdown
              isDisabled={!selectedProjectConsultants.length}
              defaultLabel={bulkActionLabel}
              links={[
                ['function', 'Export for Outreach', this.handleBulkFindEmail, download],
                ['function', 'Add to another project', this.toggleSourceAlternateModal, cornerUpRight, otherProjects.length ? '' : 'disabled'],
                ['function', 'Remove from current project', this.handleBulkUnsource, trash2, 'unsource-button'],
                ['separator', '----', () => {}, null, 'separator'],
                ['function', 'Update candidate status', this.toggleConsultantStateModal, sourceOrigin],
                ['function', 'Update candidate origin', this.toggleMatchSourceModal, source],
              ]}
            />
          </div>
        </div>
        <div className="mt-3 project-consultants-list">
          {this.renderTableHeader()}
          {this.renderStatuses()}
        </div>

        <ConsultantProjectStateModal
          ids={selectedProjectConsultants}
          toggleModal={this.toggleConsultantStateModal}
          csrfToken={csrfToken}
          funnelStates={funnelStates}
          showModal={showConsultantStateModal}
          handleUpdateConsultantStates={this.handleUpdateConsultantStates}
          context={context}
        />

        <SourceAlternateProjectModal
          selectedConsultantName={this.selectedResourceName()}
          statuses={statuses}
          consultantIds={consultantIds}
          toggleModal={this.toggleSourceAlternateModal}
          csrfToken={csrfToken}
          showModal={showSourceAlternateModal}
          sourcingProjects={sourcingProjects}
          handleSetBulkActionMessage={this.handleSetBulkActionMessage}
        />

        <MatchSourceModal
          ids={selectedProjectConsultants}
          showModal={showMatchSourceModal}
          toggleModal={this.toggleMatchSourceModal}
          csrfToken={csrfToken}
          afterSubmit={this.handleUpdateConsultantStates}
          bulkActionMessage={this.handleSetBulkActionMessage}
          context={context}
        />
        <ToastMessage
          message={bulkActionMessage}
          type={bulkMessageType}
          subtext={bulkMessageSubtext}
          hidden
        />
      </div>
    );
  }
}

ConsultantProjectContainer.propTypes = {
  funnelStates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  context: PropTypes.oneOf(['Marketplace::Project', 'Consultant']).isRequired,
  contextId: PropTypes.number.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  formUrl: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  marketplaceHost: PropTypes.string.isRequired,
  otherProjects: PropTypes.arrayOf(PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  )),
  sourcingProjects: PropTypes.arrayOf(PropTypes.shape([])),
  baseLink: PropTypes.string,
  userId: PropTypes.number,
  userEmail: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  resourceName: PropTypes.string.isRequired,
  consultantIds: PropTypes.arrayOf(PropTypes.number),
};

ConsultantProjectContainer.defaultProps = {
  filters: [],
  otherProjects: [],
  sourcingProjects: [],
  baseLink: null,
  userId: null,
  consultantIds: [],
};

export default ConsultantProjectContainer;
