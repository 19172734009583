import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';
import './StringTagEditor.scss';

/* eslint-disable-next-line react/prefer-stateless-function */
class StringTagEditor extends Component {
  render() {
    const {
      addTag,
      removeTag,
      tags,
      error,
    } = this.props;

    return (
      <div>
        { error && <div className="alert alert-danger">{error}</div> }
        <div className="tags">
          { tags.map((tag, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <span key={index} className="badge badge-success">
              { tag.inModel
                && (
                  <span>
                    <i className="fa fa-bullseye" />
                  </span>
                )
              }
              <span
                role="presentation"
              >
                {tag.name}
              </span>
              <span
                className="fa fa-times remove-tag"
                onClick={() => removeTag(index)}
                role="presentation"
              />
            </span>
          ))}
        </div>
        <div className="mt-3">
          <TagsInput
            /* eslint-disable-next-line no-unused-vars */
            value={tags.map(tag => null)}
            onChange={value => addTag({ value })}
            className="form-control fs-hide"
            inputProps={{
              placeholder: tags.length === 0 ? 'Enter text here!' : '',
              className: 'react-tagsinput-input',
            }}
            addKeys={[9, 13, 188, 186]}
            addOnBlur
          />
        </div>
      </div>
    );
  }
}

StringTagEditor.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addTag: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  error: PropTypes.string,
};

StringTagEditor.defaultProps = {
  error: null,
};

export default StringTagEditor;
