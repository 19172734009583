/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import snakeCase from 'lodash.snakecase';
import {
  Row, Col, Card, CardBody, Button, CardTitle,
} from 'reactstrap';
import SVG from 'react-inlinesvg';
import TagDisplay from '../TagDisplay';
import GeneralConsultantInfoDisplay from './GeneralConsultantInfoDisplay';
import ExpandButton from '../common/ExpandButton';
import ContextInputModal from '../common/ContextInputModal';
import ResumeDisplay from '../ResumeDisplay';
import ModalTrigger from '../common/ModalTrigger';
import NotesContainer from '../NotesContainer';
import ActionDropdown from '../common/ActionDropdown';
import AvailabilityModal from '../common/AvailabilityModal';
import { postRequest, putRequest } from '../../requestUtils';
import './ConsultantResult.scss';
import chevronDown from '../../../assets/images/chevronDown.svg';
import chevronUp from '../../../assets/images/chevronUp.svg';
import SourceAlternateProjectModal from '../common/SourceAlternateProjectModal';
import ToastMessage from '../common/ToastMessage';
import plusGreen from '../../../assets/images/plusGreen.svg';
import plusGray from '../../../assets/images/plusGray.svg';
import checkGreen from '../../../assets/images/checkGreen.svg';
import greyClock from '../../../assets/images/greyClock.svg';
import greyWorkPref from '../../../assets/images/greyWorkPref.svg';
import Divider from '../../../assets/images/Divider.svg';
import check from '../../../assets/images/check.svg';
import notesBlue from '../../../assets/images/notesBlue.svg';
import emailBlue from '../../../assets/images/emailBlue.svg';
import ellipsisBlue from '../../../assets/images/ellipsisBlue.svg';
import pen from '../../../assets/images/pen.svg';
import profile from '../../../assets/images/profile.svg';
import cornerUpRight from '../../../assets/images/corner-up-right.svg';
import trash2 from '../../../assets/images/trash-2.svg';
import { analyticsTrack } from '../../analyticsUtils';
import chevronDownWhite from '../../../assets/images/chevronDownWhite.svg';
import chevronDownNeutralBlack from '../../../assets/images/chevronDownNeutralBlack.svg';


const orderByStartDate = (array) => {
  const sorted = array.sort((a, b) => {
    if (a.start_date === null) {
      return 1;
    }
    if (b.start_date === null) {
      return -1;
    }
    if (a.start_date === b.start_date) {
      return 0;
    }
    return a.start_date < b.start_date ? 1 : -1;
  });
  const current = sorted.filter(i => i.current);
  const sortedNotCurrent = sorted.filter(i => !i.current);
  return current.concat(sortedNotCurrent);
};

const toMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', {
    month: 'short',
  });
};

const renderMatchScore = (rawScore, label, badgeClass) => (
  <React.Fragment>
    <span className="score">
      {' '}
      {`${parseInt(rawScore * 100, 10)}%`}
    </span>
    <span className={`badge ${badgeClass}`}>{label}</span>
  </React.Fragment>
);

const availabilityText = (availabilityStatus, dnu) => {
  if (dnu) {
    return 'DO NOT USE';
  } if (availabilityStatus === 'available') {
    return 'Available';
  } if (availabilityStatus === 'not_available') {
    return 'Not Available';
  }
  return 'Availability unknown';
};

const trackAddToProject = (clickPosition, projectId, consultantId, searchId, source) => {
  analyticsTrack('Add to Project Clicked', {
    clickPosition,
    projectId,
    consultantId,
    searchId,
    source,
  });
};

class ConsultantResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vettedAt: props.vettedAt,
      isVetted: props.isVetted,
      cloraReviewed: props.cloraReviewed,
      profileReviewedAt: props.profileReviewedAt,
      dnu: props.dnu,
      isConsultant: props.isConsultant,
      isMoonlighter: props.isMoonlighter,
      isContractor: props.isContractor,
      isFte: props.isFte,
      email: props.item.email,
      consultantIds: [props.item.id],
      flashStatus: null,
      error: null,
      showDNUContextModal: false,
      expandAll: false,
      showSourceAlternateModal: false,
      bulkActionMessage: '',
      bulkMessageType: null,
      attributesExpanded: {
        summary: false,
        experiences: false,
        resumes: false,
        expertise_tags: !props.searchResultPage,
        area_of_expertises: false,
        higher_level_roles: false,
        therapeutic_areas: false,
        industries: false,
        development_phases: false,
        therapies: false,
        levels: false,
        disease_codes: false,
        tools: false,
        accreditations: false,
        formulations: false,
        languages: false,
        degrees: false,
        accomplishments: false,
        preferences: false,
      },
      givenName: props.item.given_name,
      familyName: props.item.family_name,
      phoneNumber: props.item.phone_number,
      linkedinUrl: props.item.linkedin_url,
      locality: props.item.locality,
      cloraResourceId: props.item.clora_resource_id,
      enrichmentStatus: props.item.enrichment_status,
      availability: props.item.availability,
      availabilityEndDate: props.item.availability_end_date,
      minimumHoursPerWeek: props.item.minimum_hours_per_week,
      maximumHoursPerWeek: props.item.maximum_hours_per_week,
      availabilityNote: props.item.availability_note,
      availabilityType: props.item.availability_type,
      showAvailabilityModal: false,
    };

    this.handleExpand = this.handleExpand.bind(this);
    this.handleExpandAll = this.handleExpandAll.bind(this);
    this.handleAddConsultant = this.handleAddConsultant.bind(this);
    this.handleRemoveConsultant = this.handleRemoveConsultant.bind(this);
    this.handleSelectConsultant = this.handleSelectConsultant.bind(this);
    this.handleDeselectConsultant = this.handleDeselectConsultant.bind(this);
    this.handleMarkAsStatus = this.handleMarkAsStatus.bind(this);
    this.handleStatusUpdateResponse = this.handleStatusUpdateResponse.bind(this);
    this.handleFindEmail = this.handleFindEmail.bind(this);
    this.shouldRender = this.shouldRender.bind(this);
    this.renderShowAllButton = this.renderShowAllButton.bind(this);
    this.toggleSourceAlternateModal = this.toggleSourceAlternateModal.bind(this);
    this.toggleAvailabilityModal = this.toggleAvailabilityModal.bind(this);
    this.trackResultClick = this.trackResultClick.bind(this);
    this.handleSetBulkActionMessage = this.handleSetBulkActionMessage.bind(this);
    this.renderExperienceSummary = this.renderExperienceSummary.bind(this);
    this.handleEditModalUpdate = this.handleEditModalUpdate.bind(this);
    this.handleAvailabilityModalUpdate = this.handleAvailabilityModalUpdate.bind(this);
  }

  componentDidMount() {
    const bioContainerHeight = this.summaryContainer.clientHeight;
    this.setState({ bioContainerHeight });
  }

  getUpdateUrl() {
    const { item } = this.props;

    return `/consultants/${item.id}`;
  }

  getStatusUpdateParams(statusKey) {
    /* eslint-disable-next-line react/destructuring-assignment */
    const status = this.state[statusKey];
    const snakecaseStatusKey = snakeCase(statusKey);
    const inner = { [snakecaseStatusKey]: !status };
    return { consultant: inner };
  }

  trackResultClick() {
    const {
      searchResultPage, item, queryId, resultPosition,
    } = this.props;
    if (!searchResultPage) return;

    analyticsTrack(
      'Search Result Clicked',
      {
        searchId: queryId,
        clickPosition: resultPosition,
        clickedObjectId: item.id,
        clickedObjectType: 'consultant',
      },
    );
  }

  handleStatusUpdateResponse(statusKey, response) {
    this.setState({ [statusKey]: response.data[snakeCase(statusKey)] });
  }

  handleExpand(attributeName) {
    const { attributesExpanded } = this.state;

    this.setState(prevState => ({
      attributesExpanded: {
        ...attributesExpanded,
        [attributeName]: !prevState.attributesExpanded[attributeName],
      },
      showAllSummaryButton: false,
    }));
  }

  handleSetBulkActionMessage(bulkActionMessage, type) {
    const { searchResultPage, setBulkActionMessage } = this.props;

    if (searchResultPage) {
      setBulkActionMessage(bulkActionMessage, type);
    } else {
      this.setState({ bulkActionMessage, bulkMessageType: type });
    }
  }

  handleExpandAll() {
    const { expandAll } = this.state;

    const newValue = !expandAll;

    this.setState({ expandAll: newValue });
    this.setState({
      attributesExpanded: {
        summary: newValue,
        experiences: newValue,
        resumes: newValue,
        expertise_tags: newValue,
        area_of_expertises: newValue,
        higher_level_roles: newValue,
        therapeutic_areas: newValue,
        industries: newValue,
        development_phases: newValue,
        therapies: newValue,
        levels: newValue,
        disease_codes: newValue,
        tools: newValue,
        accreditations: newValue,
        formulations: newValue,
        languages: newValue,
        degrees: newValue,
        accomplishments: newValue,
        preferences: newValue,
      },
    });
  }

  handleAddConsultant() {
    const {
      item, addConsultant, projectId, resultPosition, queryId, searchResultPage,
    } = this.props;

    const source = searchResultPage ? 'project context consultant search' : 'consultant profile';

    addConsultant([item.id], projectId);

    trackAddToProject(resultPosition, [projectId], [item.id], queryId, source);
  }

  handleRemoveConsultant() {
    const { item, removeConsultant } = this.props;

    removeConsultant(item.id);
  }

  handleSelectConsultant() {
    const { item, selectConsultant } = this.props;

    selectConsultant(item.id);
  }

  handleDeselectConsultant() {
    const { item, deselectConsultant } = this.props;

    deselectConsultant(item.id);
  }

  handleMarkAsStatus(statusKey, dateKey = null) {
    const { csrfToken } = this.props;
    const params = this.getStatusUpdateParams(statusKey);
    return putRequest(
      this.getUpdateUrl(),
      params,
      csrfToken,
    ).then((response) => {
      this.handleStatusUpdateResponse(statusKey, response);
      if (dateKey) {
        this.setState({ [dateKey]: null });
      }
    });
  }

  handleFindEmail() {
    const { item } = this.props;
    const params = { selected_ids: [item.id] };

    this.setState({ flashStatus: 'Searching for email...' });
    return postRequest(
      '/rocketreach/find_email',
      params,
    ).then((resp) => {
      if (resp.data[0].success) {
        this.setState({ flashStatus: resp.data[0].error_message, email: resp.data[0].email });
      } else {
        this.setState({ flashStatus: resp.data[0].error_message, error: true });
      }
      setTimeout(() => this.setState({
        flashStatus: '', error: false,
      }), 4000);
    }).catch((e) => {
      this.setState({ flashStatus: e.data[0].error_message, error: true });
      setTimeout(() => this.setState({
        flashStatus: '', error: false,
      }), 4000);
    });
  }

  handleEditModalUpdate(formData) {
    const {
      editGivenName,
      editFamilyName,
      editEmail,
      editPhoneNumber,
      editCloraResourceId,
      editLinkedinUrl,
      editLocality,
      editEnrichmentStatus,
    } = formData;
    const { csrfToken, item } = this.props;

    return putRequest(
      `/consultants/${item.id}`,
      {
        consultant: {
          given_name: editGivenName,
          family_name: editFamilyName,
          email: editEmail,
          phone_number: editPhoneNumber,
          clora_resource_id: editCloraResourceId,
          linkedin_url: editLinkedinUrl,
          locality: editLocality,
          enrichment_status: editEnrichmentStatus,
        },
      },
      csrfToken,
    );
  }

  handleAvailabilityModalUpdate(formData) {
    const {
      editAvailability,
      editAvailabilityEndDate,
      editMinimumHoursPerWeek,
      editMaximumHoursPerWeek,
      editAvailabilityNote,
      editAvailabilityType,
      dnu,
    } = formData;
    const { csrfToken, item } = this.props;
    const availabilityId = item.availability_id;

    return putRequest(
      '/availabilities/update',
      {
        availability: {
          has_availability: editAvailabilityType === 'available',
          start_date: editAvailability,
          end_date: editAvailabilityEndDate,
          minimum_hours_per_week: editMinimumHoursPerWeek,
          maximum_hours_per_week: editMaximumHoursPerWeek,
          note: editAvailabilityNote,
          consultant_id: item.id,
          id: availabilityId,
          dnu,
        },
      },
      csrfToken,
    ).then(() => {
      this.setState({ dnu });
    });
  }

  shouldRender(itemName) {
    const { expandAll, attributesExpanded } = this.state;
    const { item } = this.props;

    const isExpertiseTag = [
      'area_of_expertises', 'higher_level_roles', 'therapeutic_areas', 'industries',
      'development_phases', 'therapies', 'levels', 'disease_codes', 'tools',
      'accreditations', 'formulations', 'languages', 'degrees', 'accomplishments', 'regulatory_pathways',
    ].includes(itemName);

    if (isExpertiseTag && !attributesExpanded.expertise_tags) return false;
    return (item[itemName] && item[itemName].length > 0) || expandAll;
  }

  toggleSourceAlternateModal() {
    const { showSourceAlternateModal } = this.state;
    this.setState({
      showSourceAlternateModal: !showSourceAlternateModal,
    });
  }

  toggleAvailabilityModal() {
    const { showAvailabilityModal } = this.state;
    this.setState({ showAvailabilityModal: !showAvailabilityModal });
  }

  renderBio() {
    const { attributesExpanded, showAllSummaryButton, bioContainerHeight } = this.state;
    const { item, searchResultPage } = this.props;
    const { summary, id } = item;
    let summaryText;
    let showExpandButton;
    let lineClamp = null;
    let showAllButton;

    if (searchResultPage) {
      showAllButton = bioContainerHeight > 170 && !showAllSummaryButton
                        && attributesExpanded.summary;
      showExpandButton = bioContainerHeight > 57;
      summaryText = summary;
      if (bioContainerHeight > 57 && !attributesExpanded.summary && !showAllSummaryButton) {
        lineClamp = 2;
      } else if (attributesExpanded.summary && !showAllSummaryButton) {
        lineClamp = 6;
      }
    } else {
      showAllButton = false;
      showExpandButton = summary && summary.length > 180;
      summaryText = showExpandButton && !attributesExpanded.summary ? `${summary.slice(0, 180)}...` : summary;
    }

    return (
      <div className={searchResultPage ? 'bio-container' : ''}>
        <div
          className={`result-field summary-${id}`}
          ref={(el) => { this.summaryContainer = el; }}
        >
          {searchResultPage && <div className="about-section-header">About</div>}
          {searchResultPage
            ? (
              <div
                className="bio-text"
                style={{ WebkitLineClamp: lineClamp }}
              >
                {summaryText}
              </div>
            )
            : (summaryText)
           }
        </div>
        {searchResultPage && showAllButton && this.renderShowAllButton()}
        {searchResultPage && showAllButton && <br />}
        {showExpandButton && this.renderExpandButton('summary')}
      </div>
    );
  }

  renderShowAllButton() {
    const onClick = () => this.setState({ showAllSummaryButton: true });

    return (
      <button
        type="button"
        className="see-all-button"
        onClick={onClick}
      >
        See all
      </button>
    );
  }

  renderExtraTags() {
    return (
      <div className="extraTags">
        {this.shouldRender('disease_codes') && this.renderTagGroup('disease codes', 'disease_codes', 'ConsultantsDiseaseCode')}
        {this.shouldRender('tools') && this.renderTagGroup('tools', 'tools', 'Marketplace::Tool')}
        {this.shouldRender('accreditations') && this.renderTagGroup('accreditations', 'accreditations', 'Marketplace::Accreditation')}
        {this.shouldRender('formulations') && this.renderTagGroup('formulations', 'formulations', 'Marketplace::Formulation')}
        {this.shouldRender('languages') && this.renderTagGroup('languages', 'languages', 'Marketplace::Language')}
        {this.shouldRender('degrees') && this.renderTagGroup('degrees', 'degrees', 'Degree')}
        {this.shouldRender('accomplishments') && this.renderTagGroup('accomplishments', 'accomplishments', 'Accomplishment')}
      </div>
    );
  }

  renderResumes() {
    const { item, csrfToken } = this.props;
    const { attributesExpanded } = this.state;

    return (
      <div className="resumes">
        <ResumeDisplay
          resumes={item.resumes}
          csrfToken={csrfToken}
          consultantId={item.id}
          expanded={attributesExpanded.resumes}
        />
        {item.resumes.length > 2 && this.renderExpandButton('resumes')}
      </div>
    );
  }

  renderAddRemoveButton() {
    const { isAdded, sourcingAvailable, projectId } = this.props;

    if (!sourcingAvailable || !projectId) return null;
    return (
      <React.Fragment>
        {isAdded
          ? (
            <div className="sourced-checkmark">
              <SVG src={checkGreen} />
              {' '}
              Added to project
            </div>
          )
          : (
            <button
              className="btn btn-source-selected ml-2 add-project-btn"
              type="button"
              onClick={this.handleAddConsultant}
            >
              <SVG src={plusGreen} className="green-plus mr-2" />
              Add to project
            </button>
          )
        }
      </React.Fragment>
    );
  }

  renderCheckbox() {
    const { isSelected } = this.props;
    const handler = isSelected ? this.handleDeselectConsultant : this.handleSelectConsultant;

    return (
      <label htmlFor="select-checkbox" className="select-consultant select-label">
        {isSelected && <SVG src={check} className="select-check" />}
        <input
          id="select-checkbox"
          onClick={handler}
          type="button"
          className={`btn consultant-select ${isSelected ? 'selected' : ''}`}
        />
      </label>
    );
  }

  renderMarkStatusButton(statusKey, title, icon, options = {}) {
    const buttonClass = options.buttonClass || 'btn-success';
    const dateKey = options.dateKey || null;
    const onClick = options.onClick || (() => this.handleMarkAsStatus(statusKey, dateKey));
    /* eslint-disable-next-line react/destructuring-assignment */
    const status = this.state[statusKey];

    if (status === true) {
      return (
        <button
          title={`Mark as not ${title}`}
          type="button"
          className={`btn ${buttonClass} btn-border-gray ${statusKey}`}
          onClick={onClick}
        >
          <i className={`fa ${icon}`} />
        </button>
      );
    }

    return (
      <button
        title={`Mark as ${title}`}
        type="button"
        className={`btn btn-outline-secondary  ${statusKey}`}
        onClick={onClick}
      >
        <i className={`fa ${icon}`} />
      </button>
    );
  }

  renderFindEmailButton() {
    const { email } = this.state;

    return (
      <button
        className="btn btn-outline-secondary"
        type="button"
        onClick={this.handleFindEmail}
        title="Get Email"
        disabled={email}
      >
        <i className="fa fa-at" />
      </button>
    );
  }

  renderJobExperiences() {
    const { attributesExpanded } = this.state;
    const { item, searchResultPage } = this.props;

    const orderedExperiences = orderByStartDate(item.experiences);

    const experiences = orderedExperiences.length > 3 && !attributesExpanded.experiences
      ? orderedExperiences.slice(0, 3) : orderedExperiences;

    return (
      <div className="result-field experiences">
        <div className="exp-section-header">Previous roles</div>
        {experiences.map((exp, index) => {
          let badge = '';
          if (exp.current === true) {
            badge = <span className="badge badge-success badge-current-experience">Current</span>;
          }
          let startParts;
          let startYear;
          let endParts;
          let endYear;
          let years;
          let startYearOnly;
          let endYearOnly;
          if (exp.start || exp.start_date) {
            startYearOnly = (exp.start || '').match(/^\d{4}$/);
            startParts = exp.start_date ? exp.start_date.split('-') : exp.start.split('-');
            startYear = startParts.length > 1
              ? [parseInt(startParts[0], 10), parseInt(startParts[1], 10)]
              : [parseInt(startParts[0], 10)];

            if (exp.end !== 'Present' && (exp.end || exp.end_date)) {
              endYearOnly = (exp.end || '').match(/^\d{4}$/);
              endParts = exp.end_date ? exp.end_date.split('-') : exp.end.split('-');
              endYear = endParts.length > 1
                ? [parseInt(endParts[0], 10), parseInt(endParts[1], 10)]
                : [parseInt(startParts[0], 10)];
            }

            years = endYear
              ? endYear[0] - startYear[0]
              : new Date().getFullYear() - startYear[0];
          }
          return (
            /* eslint-disable-next-line react/no-array-index-key */
            <div className="experience" key={index}>
              <div className="title-header">
                {exp.title}
              </div>
              <div className="experience-info">
                <span className="experience-org">
                  {exp.organization}
                </span>
                {(exp.start || exp.start_date)
                  && (
                    <span className="experience-dates">
                      {`${startYearOnly ? '' : toMonthName(startYear[1])} ${startYear[0]}`}
                      {' - '}
                      {endYear
                        ? `${endYearOnly ? '' : toMonthName(endYear[1])} ${endYear[0]}`
                        : badge}
                      {years === 0 ? ' (<1 year)' : ` (${years} years)`}
                      <br />
                      {searchResultPage
                        ? null
                        : attributesExpanded.experiences && exp.description }
                    </span>
                  )
                }
              </div>
            </div>
          );
        })}
        {item.experiences.length > 3
          && this.renderExpandButton('experiences')}
      </div>
    );
  }

  renderTagGroup(title, tags, associationType) {
    const { item, csrfToken, searchResultPage } = this.props;
    const { attributesExpanded } = this.state;
    return (
      <div className={`${title}-tag ${searchResultPage ? 'search-results' : ''}`}>
        <TagDisplay
          title={title}
          tags={item[tags]}
          associationType={associationType}
          consultantId={item.id}
          csrfToken={csrfToken}
          allowEdit
          expanded={attributesExpanded[tags]}
          mustHaveMatches={item.must_have_matches}
          optionalMatches={item.optional_matches}
          isSearchResultPage={searchResultPage}
        />
        {item[tags].length > 8 && this.renderExpandButton(tags)}
      </div>
    );
  }

  renderTagGroupHeader(tags, className = 'experience-header-elements') {
    const { item } = this.props;
    const tagNames = item[tags].map(element => element.name);
    return ((item[tags].length) > 0 && (
      <div className={className}>
        {tagNames.join(', ')}
      </div>
    ));
  }

  renderExpandAll() {
    const { expandAll } = this.state;

    return (
      <ExpandButton handleToggle={this.handleExpandAll} expanded={expandAll} expandAll />
    );
  }

  renderExpandButton(attributeName, expandText = null) {
    const { searchResultPage } = this.props;
    const { attributesExpanded } = this.state;
    const forNewDesign = ['summary'];

    return (
      <ExpandButton
        handleToggle={() => this.handleExpand(attributeName)}
        expanded={attributesExpanded[attributeName]}
        expandText={expandText}
        searchResultPage={forNewDesign.includes(attributeName) && searchResultPage}
      />
    );
  }

  renderDNUContextModal() {
    const { dnu, showDNUContextModal } = this.state;
    const { csrfToken } = this.props;
    const contextPrefix = dnu ? 'Marked as not DNU' : 'Marked as DNU';
    const headerText = `Why is this consultant being marked as ${dnu ? 'not DNU' : 'DNU'}?`;

    return (
      <ContextInputModal
        csrfToken={csrfToken}
        showModal={showDNUContextModal}
        params={this.getStatusUpdateParams('dnu')}
        submitUrl={this.getUpdateUrl()}
        closeModal={() => this.setState({ showDNUContextModal: false })}
        afterSubmit={response => this.handleStatusUpdateResponse('dnu', response)}
        headerText={headerText}
        contextPrefix={contextPrefix}
        idKey="dnu"
      />
    );
  }

  renderMatchScores() {
    const { item, projectId } = this.props;
    const { attributesExpanded } = this.state;

    return (
      <div
        className="match-score"
        onClick={() => this.handleExpand('expertise_tags')}
        onKeyUp={() => this.handleExpand('expertise_tags')}
        role="button"
        tabIndex={0}
      >
        <span className="match-score-header">
          {projectId ? 'Expertise project match' : 'Expertise Tags'}
          <SVG src={attributesExpanded.expertise_tags ? chevronUp : chevronDown} className="expertise-tag-expand" />
        </span>
        <span className="requirement-tags">
          {projectId && item.must_have_match_score
            ? renderMatchScore(item.must_have_match_score, 'Must-have', 'badge-primary') : ''
        }
          {projectId
            ? renderMatchScore(item.optional_match_score, 'Optional', 'badge-success') : ''
        }
        </span>
      </div>
    );
  }

  renderSourceAlternateModal() {
    const { sourcingAvailable } = this.props;

    if (sourcingAvailable) return null;
    return (
      <Button
        outline
        color="secondary"
        size="med"
        onClick={this.toggleSourceAlternateModal}
        className="source-alternate-button mr-0 text-uppercase"
      >
        <i className="fa fa-plus mr-2" />
        Add to Project
      </Button>
    );
  }

  renderExperienceSummary() {
    const {
      item,
      searchResultPage,
    } = this.props;


    const resume = item.resumes[item.resumes.length - 1];

    if (!searchResultPage) return null;

    return (
      <React.Fragment>
        <div>
          <div className="experience-header">
            Experience summary
          </div>
          {resume && <a className="experience-header-elements" href={`/resumes/${resume.resource_id}`} target="_blank" rel="noreferrer">Resume</a>}
        </div>
        <div className="seniority">
          {this.renderTagGroupHeader('levels', 'seniority')}
          {item.total_years_of_experience && (
            <p className="seniority">
              {`${item.total_years_of_experience} years`}
            </p>
          )}
        </div>
        <div className="experience-spacing" />

      </React.Fragment>
    );
  }

  renderCandidateActions() {
    const {
      item, csrfToken, marketplaceHost, projectId, isAdded,
    } = this.props;

    return (
      <React.Fragment>
        <div className="add-project-divider">
          <SVG src={Divider} />
        </div>
        {item.email
          && (
          <a href={`mailto:${item.email}`} title="Email candidate">
            <SVG src={emailBlue} className="candidate-action-icon" />
          </a>
          )
        }
        <ModalTrigger
          idKey={`consultant-${item.id}-notes`}
          triggerText={
            <SVG title="View Notes" src={notesBlue} className="candidate-action-icon" />
          }
          triggerType="link"
          classKey="none"
        >
          <NotesContainer
            header="Consultant Notes"
            notes={item.notes}
            ratings={item.marketplace_reviews}
            csrfToken={csrfToken}
            submitPath={item.add_note_path}
          />
        </ModalTrigger>
        <ActionDropdown
          id={`consultant-${item.id}-actions`}
          key={`consultant-${item.id}-actions-${item.clora_resource_id}-${isAdded}`}
          defaultLabel={
            <SVG title="More Actions" src={ellipsisBlue} className="consultant-actions candidate-action-icon" />
          }
          links={[
            ['link', 'Edit Profile', `/consultants/${item.id}/edit`, pen],
            ['link', 'View in Marketplace', `${marketplaceHost}users/${item.clora_resource_id}`, profile, item.clora_resource_id ? '' : 'disabled'],
            ['separator', '----', () => {}, null, 'separator'],
            ['function', 'Add to another project', this.toggleSourceAlternateModal, cornerUpRight],
            ['function', 'Add to current project', this.handleAddConsultant, plusGray, `${(projectId && (!isAdded)) ? '' : 'disabled'} gray-plus`],
            ['function', 'Remove from current project', this.handleRemoveConsultant, trash2, isAdded ? '' : 'disabled'],
          ]}
          plainTrigger
        />
      </React.Fragment>
    );
  }

  renderGeneralInfo() {
    const {
      item,
      targetBlank,
      source,
      csrfToken,
      marketplaceHost,
      enrichmentStatusOptions,
      searchResultPage,
      profileRatings,
      projectRatings,
    } = this.props;
    const {
      email,
      givenName,
      familyName,
      phoneNumber,
      linkedinUrl,
      locality,
      cloraResourceId,
      enrichmentStatus,
      isConsultant,
      isVetted,
      vettedAt,
      attributesExpanded,
      cloraReviewed,
      profileReviewedAt,
      availabilityNote,
      availabilityEndDate,
      dnu,
    } = this.state;

    const target = targetBlank === true ? '_blank' : '_self';

    return (
      <GeneralConsultantInfoDisplay
        key={`${item.id}-consultant-${email}`}
        id={item.id}
        target={target}
        givenName={givenName}
        familyName={familyName}
        email={email}
        phoneNumber={phoneNumber}
        linkedinUrl={linkedinUrl}
        cloraResourceId={cloraResourceId}
        locality={locality}
        isConsultant={isConsultant}
        isVetted={isVetted}
        vettedAt={vettedAt}
        enrichmentStatus={enrichmentStatus}
        isReviewed={cloraReviewed}
        profileReviewedAt={profileReviewedAt}
        source={source}
        csrfToken={csrfToken}
        minimumHourlyRate={item.minimum_hourly_rate}
        maximumHourlyRate={item.maximum_hourly_rate}
        minimumHoursPerWeek={item.minimum_hours_per_week}
        maximumHoursPerWeek={item.maximum_hours_per_week}
        availability={item.availability}
        availabilityType={item.availability_type}
        availabilityConfirmedAt={item.availability_confirmed_at}
        availabilityNote={availabilityNote}
        availabilityEndDate={availabilityEndDate}
        dnu={dnu}
        profileRatings={profileRatings}
        projectRatings={projectRatings}
        ratingsDimensions={item.ratings_dimensions}
        marketplaceHost={marketplaceHost}
        readyToConsult={item.ready_to_consult}
        readyToConsultConfirmedAt={item.ready_to_consult_confirmed_at}
        consultingRestartDate={item.consulting_restart_date}
        emailPreferences={item.email_preferences}
        emailPreferencesConfirmedAt={item.email_preferences_confirmed_at}
        companyFit={item.company_fit}
        companyFitConfirmedAt={item.company_fit_confirmed_at}
        travelDays={item.travel_days}
        travelDaysConfirmedAt={item.travel_days_confirmed_at}
        inOfficeDays={item.in_office_days}
        inOfficeDaysConfirmedAt={item.in_office_days_confirmed_at}
        toggleExpand={() => this.handleExpand('preferences')}
        expanded={attributesExpanded.preferences}
        enrichmentStatusOptions={enrichmentStatusOptions}
        proposalStats={item.proposal_stats}
        searchResultPage={searchResultPage}
        handleFindEmail={this.handleFindEmail}
        handleEditModalUpdate={this.handleEditModalUpdate}
        handleAvailabilityModalUpdate={this.handleAvailabilityModalUpdate}
        trackResultClick={this.trackResultClick}
        roleTypes={item.role_types}
      />
    );
  }

  renderExperienceTags() {
    const { searchResultPage } = this.props;

    return (
      <React.Fragment>
        {this.shouldRender('area_of_expertises') && this.renderTagGroup('areas of expertise', 'area_of_expertises', 'AreaOfExpertisesConsultant')}
        {this.shouldRender('higher_level_roles') && this.renderTagGroup('roles', 'higher_level_roles', 'ConsultantsHigherLevelRole')}
        {this.shouldRender('therapeutic_areas') && this.renderTagGroup('therapeutic areas', 'therapeutic_areas', 'ConsultantsTherapeuticArea')}
        {this.shouldRender('industries') && this.renderTagGroup('industries', 'industries', 'ConsultantsIndustry')}
        {this.shouldRender('development_phases') && this.renderTagGroup('development phases', 'development_phases', 'ConsultantsDevelopmentPhase')}
        {this.shouldRender('therapies') && this.renderTagGroup('therapies', 'therapies', 'ConsultantsTherapy')}
        {this.shouldRender('regulatory_pathways') && this.renderTagGroup('regulatory pathways', 'regulatory_pathways', 'ConsultantsRegulatoryPathway')}
        {this.shouldRender('levels') && this.renderTagGroup('role levels', 'levels', 'ConsultantsLevel')}
        {this.renderExtraTags()}
        {!searchResultPage && this.shouldRender('resumes') && this.renderResumes()}
        <br />
        {!searchResultPage && this.renderExpandAll()}
      </React.Fragment>
    );
  }

  renderSourceAlternateProjectModal() {
    const {
      item, csrfToken, sourcingProjects, resultPosition,
    } = this.props;
    const { consultantIds, showSourceAlternateModal } = this.state;

    return (
      <SourceAlternateProjectModal
        selectedConsultantName={`${item.given_name} ${item.family_name}`}
        consultantIds={consultantIds}
        toggleModal={this.toggleSourceAlternateModal}
        csrfToken={csrfToken}
        showModal={showSourceAlternateModal}
        sourcingProjects={sourcingProjects}
        handleSetBulkActionMessage={this.handleSetBulkActionMessage}
        resultPosition={resultPosition}
      />
    );
  }

  renderSearchResult() {
    const {
      item,
      projectId,
    } = this.props;

    const {
      flashStatus,
      error,
    } = this.state;

    return (
      <div className="consultant-result result-item">
        <Row className="header-actions mb-2">
          <Col>
            <div className="btn-toolbar float-left">
              {this.renderCheckbox()}
              {projectId && this.renderAddRemoveButton()}
              {this.renderCandidateActions()}
            </div>
          </Col>
        </Row>
        <Card className={`search-result ${item.dnu ? 'dnu' : item.availability_type}`}>
          <CardTitle className="mb-0">
            <Row className="mt-0 mb-0 card-title-row">
              <Col xs="5" className={`availability-status ${item.dnu ? 'dnu' : item.availability_type}`}>
                {this.renderAvailabilityDropdown()}
              </Col>
              <Col xs="7" className="proposal-stats">
                Proposals submitted:
                <span className={`ml-1 mr-3 ${item.proposal_stats.submitted_to_clora ? 'exists' : ''}`}>
                  {item.proposal_stats.submitted_to_clora}
                </span>
                Matched:
                <span className={`ml-1 ${item.proposal_stats.accepted ? 'exists' : ''}`}>
                  {item.proposal_stats.accepted}
                </span>
              </Col>
            </Row>
          </CardTitle>
          <CardBody>
            <Row className="mt-0 ml-0 mr-0">
              <Col xs="12" md="5" className="left-pane">
                {flashStatus
                  ? (
                    <div className="flex-col bulk-action-message">
                      <div className={`alert ${error ? 'alert-danger' : ''}`}>
                        {flashStatus}
                      </div>
                    </div>
                  )
                  : ''
                }
                { this.renderGeneralInfo() }
              </Col>

              <Col xs="12" md="7" className="right-pane">
                {this.renderExperienceSummary()}
                {this.renderBio()}
                {item.experiences && this.renderJobExperiences()}
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div className="tags-section">
                  {this.renderMatchScores()}
                  {' '}
                  {this.renderExperienceTags()}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {this.renderDNUContextModal()}
        {this.renderSourceAlternateProjectModal()}
        {this.renderAvailabilityModal()}
      </div>
    );
  }

  renderAvailabilityDropdown() {
    const {
      item, isAdded, roleTypes,
    } = this.props;

    const roleNames = roleTypes.map(element => element.name);

    let hoursText = '';

    const downArrow = (item.availability_type === 'not_available' || item.availability_type === 'unknown') ? chevronDownNeutralBlack : chevronDownWhite;

    if (item.minimum_hours_per_week && item.maximum_hours_per_week) {
      hoursText = `${item.minimum_hours_per_week} - ${item.maximum_hours_per_week} hrs/week`;
    } else if (item.minimum_hours_per_week) {
      hoursText = `${item.minimum_hours_per_week} hrs/week`;
    } else if (item.maximum_hours_per_week) {
      hoursText = `${item.maximum_hours_per_week} hrs/week`;
    }
    const hoursLabel = (
      <React.Fragment>
        <SVG src={greyClock} />
        <div className="availability-left">
          {hoursText}
        </div>
      </React.Fragment>
    );

    const roleLabel = (
      <React.Fragment>
        <SVG src={greyWorkPref} />
        <div className="availability-left">
          {`${roleNames}`}
        </div>
      </React.Fragment>
    );

    const availabilityNode = (
      <React.Fragment>
        { availabilityText(item.availability_type, item.dnu) }
        <SVG title="availabilityDropdown" src={downArrow} className="availability-expand" />
      </React.Fragment>
    );

    return (
      <ActionDropdown
        id={`consultant-${item.id}-availability`}
        key={`consultant-${item.id}-availability-${item.clora_resource_id}-${isAdded}`}
        styleType="availability-menu"
        defaultLabel={(
          <span>
            <div className={`availability-padding-${item.availability_type}`}>
              {availabilityNode}
            </div>
          </span>
    )}
        links={[
          [item.availability_type === 'available' && item.availability ? 'info' : 'hidden', `Available starting ${new Date(item.availability).toLocaleDateString('en-US')}`, () => {}, null],
          [item.availability_type === 'available' && item.availability_end_date ? 'info' : 'hidden', `Until ${new Date(item.availability_end_date).toLocaleDateString('en-US')}`, () => {}, null],
          [item.availability_confirmed_at ? 'info' : 'hidden', `Confirmed ${new Date(item.availability_confirmed_at).toLocaleDateString('en-US')}`, () => {}, null],
          [item.availability_type === 'available' && (item.minimum_hours_per_week || item.maximum_hours_per_week) ? 'info' : 'hidden', hoursLabel, () => {}, null],
          [item.availability_type === 'available' && roleNames.length > 0 ? 'info' : 'hidden', roleLabel, () => {}, null],
          [item.availability_type === 'unknown' ? 'info' : 'hidden', 'No availability data', () => {}, null],
          ['function', 'Update', this.toggleAvailabilityModal, null, 'update-text'],
        ]}
        plainTrigger
      />
    );
  }

  renderAvailabilityModal() {
    const { item } = this.props;

    const { showAvailabilityModal } = this.state;

    return (
      <React.Fragment>
        <AvailabilityModal
          handleUpdate={this.handleAvailabilityModalUpdate}
          availability={item.availability}
          availabilityType={item.availability_type}
          availabilityConfirmedAt={item.availability_confirmed_at}
          availabilityEndDate={item.availability_end_date}
          minimumHoursPerWeek={item.minimum_hours_per_week}
          maximumHoursPerWeek={item.maximum_hours_per_week}
          availabilityNote={item.availability_note}
          showModal={showAvailabilityModal}
          toggleModal={this.toggleAvailabilityModal}
          dnu={item.dnu}
        />
      </React.Fragment>
    );
  }

  renderFullView() {
    const {
      item,
      targetBlank,
    } = this.props;

    const {
      flashStatus,
      error,
      bulkActionMessage,
      bulkMessageType,
    } = this.state;

    const target = targetBlank === true ? '_blank' : '_self';
    return (
      <div className="consultant-result result-item mb-3">
        <Card className="full-view">
          <CardBody>
            <Row>
              <Col>
                <div className="btn-toolbar float-left">
                  <div className="mr-2">
                    {this.renderSourceAlternateModal()}
                  </div>
                  {this.renderCandidateActions()}
                </div>
              </Col>
              <Col className="float-right">
                <React.Fragment>
                  <a title="Edit Profile" href={`/consultants/${item.id}/edit`} className="btn btn-outline-secondary" target={target}>
                    <i className="fa fa-pencil-square-o" />
                  </a>
                  <div className="btn-group mr-2" role="group">
                    {this.renderMarkStatusButton('isVetted', 'Vetted', 'fa-check-circle-o', { dateKey: 'vettedAt' })}
                    {this.renderMarkStatusButton('cloraReviewed', 'Clora Reviewed', 'fa-thumbs-up', { dateKey: 'profileReviewedAt' })}
                    {this.renderMarkStatusButton(
                      'dnu',
                      'DNU',
                      'fa-ban',
                      {
                        buttonClass: 'btn-danger',
                        onClick: () => this.setState({ showDNUContextModal: true }),
                      },
                    )}
                  </div>

                  <div className="btn-group mr-2" role="group">
                    {this.renderMarkStatusButton('isConsultant', 'Consultant', 'fa-copyright')}
                    {this.renderMarkStatusButton('isMoonlighter', 'Moonlighter', 'fa-moon-o')}
                    {this.renderMarkStatusButton('isContractor', 'Contractor', 'fa-file-text-o')}
                    {this.renderMarkStatusButton('isFte', 'Full-Time Employee', 'fa-clock-o')}
                  </div>
                </React.Fragment>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="5">
                {flashStatus
                  ? (
                    <div className="flex-col bulk-action-message">
                      <div className={`alert ${error ? 'alert-danger' : ''}`}>
                        {flashStatus}
                      </div>
                    </div>
                  )
                  : ''
                }
                { this.renderGeneralInfo() }
                <hr />
              </Col>

              <Col xs="12" md="7">
                {this.renderExperienceSummary()}
                {this.renderBio()}
                {item.experiences && this.renderJobExperiences()}
              </Col>
            </Row>
            <div className="tags-section-header match-score">
              {this.renderMatchScores()}
            </div>
            {this.renderExperienceTags()}
          </CardBody>
        </Card>
        {this.renderDNUContextModal()}
        {this.renderSourceAlternateProjectModal()}
        <ToastMessage message={bulkActionMessage} type={bulkMessageType} />
      </div>
    );
  }

  render() {
    const { searchResultPage } = this.props;

    return searchResultPage ? this.renderSearchResult() : this.renderFullView();
  }
}

ConsultantResult.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  isAdded: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool,
  item: PropTypes.shape({
    email: PropTypes.string.isRequired,
    id: PropTypes.number,
    summary: PropTypes.shape({
      length: PropTypes.number,
      slice: PropTypes.func,
    }),
    disease_codes: PropTypes.arrayOf(PropTypes.shape({})),
    tools: PropTypes.arrayOf(PropTypes.shape({})),
    accreditations: PropTypes.arrayOf(PropTypes.shape({})),
    formulations: PropTypes.arrayOf(PropTypes.shape({})),
    languages: PropTypes.arrayOf(PropTypes.shape({})),
    degrees: PropTypes.arrayOf(PropTypes.shape({})),
    accomplishments: PropTypes.arrayOf(PropTypes.shape({})),
    resumes: PropTypes.arrayOf(PropTypes.shape({
      length: PropTypes.number,
      resource_id: PropTypes.shape,
    })),
    experiences: PropTypes.arrayOf(PropTypes.shape({ length: PropTypes.number })),
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    overall_rating: PropTypes.number,
    phone_number: PropTypes.string,
    linkedin_url: PropTypes.string,
    clora_resource_id: PropTypes.string,
    locality: PropTypes.string,
    enrichment_status: PropTypes.string,
    minimum_hourly_rate: PropTypes.number,
    maximum_hourly_rate: PropTypes.number,
    minimum_hours_per_week: PropTypes.number,
    maximum_hours_per_week: PropTypes.number,
    availability: PropTypes.string,
    availability_type: PropTypes.string,
    availability_end_date: PropTypes.string,
    availability_confirmed_at: PropTypes.string,
    ratings_dimensions: PropTypes.arrayOf(PropTypes.string),
    ready_to_consult: PropTypes.bool,
    ready_to_consult_confirmed_at: PropTypes.string,
    consulting_restart_date: PropTypes.string,
    email_preferences: PropTypes.string,
    email_preferences_confirmed_at: PropTypes.string,
    company_fit: PropTypes.string,
    company_fit_confirmed_at: PropTypes.string,
    travel_days: PropTypes.string,
    travel_days_confirmed_at: PropTypes.string,
    in_office_days: PropTypes.string,
    in_office_days_confirmed_at: PropTypes.string,
    proposal_stats: PropTypes.shape({
      submitted_to_clora: PropTypes.number,
      accepted: PropTypes.number,
    }),
    headline: PropTypes.string,
    area_of_expertises: PropTypes.arrayOf(PropTypes.shape({})),
    therapeutic_areas: PropTypes.arrayOf(PropTypes.shape({})),
    higher_level_roles: PropTypes.arrayOf(PropTypes.shape({})),
    industries: PropTypes.arrayOf(PropTypes.shape({})),
    development_phases: PropTypes.arrayOf(PropTypes.shape({})),
    therapies: PropTypes.arrayOf(PropTypes.shape({})),
    levels: PropTypes.arrayOf(PropTypes.shape({})),
    must_have_match_score: PropTypes.number,
    optional_match_score: PropTypes.number,
    must_have_matches: PropTypes.arrayOf(PropTypes.string),
    optional_matches: PropTypes.arrayOf(PropTypes.string),
    ratings: PropTypes.arrayOf(PropTypes.shape({})),
    notes: PropTypes.arrayOf(PropTypes.shape({})),
    add_note_path: PropTypes.string,
    total_years_of_experience: PropTypes.number,
    seniority: PropTypes.arrayOf(PropTypes.shape({})),
    marketplace_reviews: PropTypes.arrayOf(PropTypes.shape({})),
    role_types: PropTypes.arrayOf(PropTypes.shape({})),
    availability_note: PropTypes.string,
    dnu: PropTypes.bool,
    availability_id: PropTypes.oneOf(['string', null]),
  }).isRequired,
  vettedAt: PropTypes.string,
  isVetted: PropTypes.bool,
  source: PropTypes.string,
  dnu: PropTypes.bool,
  isConsultant: PropTypes.bool,
  isMoonlighter: PropTypes.bool,
  isContractor: PropTypes.bool,
  isFte: PropTypes.bool,
  addConsultant: PropTypes.func,
  removeConsultant: PropTypes.func,
  selectConsultant: PropTypes.func,
  deselectConsultant: PropTypes.func,
  targetBlank: PropTypes.bool,
  marketplaceHost: PropTypes.string.isRequired,
  sourcingAvailable: PropTypes.bool,
  projectId: PropTypes.string,
  cloraReviewed: PropTypes.bool.isRequired,
  profileReviewedAt: PropTypes.string,
  enrichmentStatusOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  profileRatings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  projectRatings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchResultPage: PropTypes.bool,
  sourcingProjects: PropTypes.arrayOf(PropTypes.shape([])),
  setBulkActionMessage: PropTypes.func,
  resultPosition: PropTypes.number,
  queryId: PropTypes.string,
  roleTypes: PropTypes.arrayOf(PropTypes.shape({})),
  enrichmentStatus: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

ConsultantResult.defaultProps = {
  targetBlank: false,
  vettedAt: null,
  isVetted: null,
  dnu: null,
  isConsultant: null,
  isMoonlighter: null,
  isContractor: null,
  isFte: null,
  source: null,
  isSelected: false,
  addConsultant: () => null,
  removeConsultant: () => null,
  sourcingAvailable: false,
  projectId: null,
  selectConsultant: () => null,
  deselectConsultant: () => null,
  profileReviewedAt: null,
  searchResultPage: false,
  sourcingProjects: [],
  setBulkActionMessage: () => null,
  queryId: null,
  resultPosition: 0,
  roleTypes: [],
};

export default ConsultantResult;
